import Pusher from "pusher-js";
import store from "@/store";
import JwtService from "@/services/jwt.service";
import { bus } from "@/main.js";
import {
  BUILDING_SET_CURRENTCONFIRMED,
  PUSHER_SUBSCRIPTION_SUCCEEDED,
  AUTH_LOGOUTCONFIRMED,
} from "@/events";

const pusherAPI = process.env.VUE_APP_PUSHER_API;
const pusherKey = process.env.VUE_APP_PUSHER_KEY;

const onServerEvent = function (eventName, data) {
  console.log(
    "Pusher Event <" + eventName + "> received and to be sent to VueX dispatch"
  );
  if (eventName != PUSHER_SUBSCRIPTION_SUCCEEDED) {
    store.dispatch("action:pusher-" + eventName, data);
  }
};

var pusher;

export const registerPusherCallbacks = () => {
  // When we log in from the login page,
  // We need to wait until the current building
  // is set to redirect the user, because the building
  // ID is set in the path
  bus.$on(BUILDING_SET_CURRENTCONFIRMED, () => {
    // Now we can connect to pusher with the auth token
    serverEventService.init();
  });

  bus.$on(AUTH_LOGOUTCONFIRMED, () => {
    serverEventService.tearDown();
  });
};

const serverEventService = {
  /*
  Connect to pusher websocket.
  */
  init() {
    // Pusher.logToConsole = true;
    if (JwtService.isTokenValid()) {
      const accessToken = JwtService.getToken();
      pusher = new Pusher(pusherKey, {
        cluster: "us2",
        authEndpoint: pusherAPI,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      // Create a channel from the user name
      const privateChannel = "private-" + JwtService.getUserId();
      const channel = pusher.subscribe(privateChannel);
      channel.bind_global(onServerEvent);
    } else {
      // Token is invalid, the login will be called again and
      // the bus will get called again for building_set_confirmed
      serverEventService.tearDown();
    }
  },

  /*
  Removing bound function on channel and disconnecting from Pusher.
  Called on signout
  */
  tearDown() {
    pusher.disconnect();
  },
};

export default serverEventService;
