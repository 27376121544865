// source: msh/service/v1/device_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var msh_resource_v1_device_pb = require('../../../msh/resource/v1/device_pb.js');
goog.object.extend(proto, msh_resource_v1_device_pb);
var msh_resource_v1_event_pb = require('../../../msh/resource/v1/event_pb.js');
goog.object.extend(proto, msh_resource_v1_event_pb);
var msh_service_v1_command_pb = require('../../../msh/service/v1/command_pb.js');
goog.object.extend(proto, msh_service_v1_command_pb);
var msh_trait_activities_v1_activity_level_pb = require('../../../msh/trait/activities/v1/activity_level_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_activity_level_pb);
var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
goog.exportSymbol('proto.msh.service.v1.AddRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.AdminProvisionRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AdminProvisionResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.DeleteRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.DeleteResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.DeviceStartGroupResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLDeviceEventsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLDeviceEventsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceByIDRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceByIDResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceByProductNameRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceByProductNameResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.ListRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.ListResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.ProvisionRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.ProvisionResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendDeviceCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendDeviceCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendRebootCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendRebootCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartRecordingDeviceCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartRecordingDeviceCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStopRecordingDeviceCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStopRecordingDeviceCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.UnProvisionRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.UnProvisionResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.UpdateRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddRequest.displayName = 'proto.msh.service.v1.AddRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.DeleteRequest.displayName = 'proto.msh.service.v1.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UpdateRequest.displayName = 'proto.msh.service.v1.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ProvisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ProvisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ProvisionRequest.displayName = 'proto.msh.service.v1.ProvisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ProvisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ProvisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ProvisionResponse.displayName = 'proto.msh.service.v1.ProvisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AdminProvisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AdminProvisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AdminProvisionRequest.displayName = 'proto.msh.service.v1.AdminProvisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AdminProvisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AdminProvisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AdminProvisionResponse.displayName = 'proto.msh.service.v1.AdminProvisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UnProvisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UnProvisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UnProvisionRequest.displayName = 'proto.msh.service.v1.UnProvisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UnProvisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UnProvisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UnProvisionResponse.displayName = 'proto.msh.service.v1.UnProvisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddResponse.displayName = 'proto.msh.service.v1.AddResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.DeleteResponse.displayName = 'proto.msh.service.v1.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UpdateResponse.displayName = 'proto.msh.service.v1.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRequest.displayName = 'proto.msh.service.v1.GetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetResponse.displayName = 'proto.msh.service.v1.GetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceByIDRequest.displayName = 'proto.msh.service.v1.GetDeviceByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceByIDResponse.displayName = 'proto.msh.service.v1.GetDeviceByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListRequest.displayName = 'proto.msh.service.v1.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListResponse.displayName = 'proto.msh.service.v1.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendDeviceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendDeviceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendDeviceCommandRequest.displayName = 'proto.msh.service.v1.SendDeviceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendDeviceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendDeviceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendDeviceCommandResponse.displayName = 'proto.msh.service.v1.SendDeviceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendRebootCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendRebootCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendRebootCommandRequest.displayName = 'proto.msh.service.v1.SendRebootCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendRebootCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendRebootCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendRebootCommandResponse.displayName = 'proto.msh.service.v1.SendRebootCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStartRecordingDeviceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.displayName = 'proto.msh.service.v1.SendStartRecordingDeviceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStartRecordingDeviceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.displayName = 'proto.msh.service.v1.SendStartRecordingDeviceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.displayName = 'proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.displayName = 'proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.DeviceStartGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.DeviceStartGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.DeviceStartGroupResponse.displayName = 'proto.msh.service.v1.DeviceStartGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStopRecordingDeviceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.displayName = 'proto.msh.service.v1.SendStopRecordingDeviceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStopRecordingDeviceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.displayName = 'proto.msh.service.v1.SendStopRecordingDeviceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.displayName = 'proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.displayName = 'proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLDeviceEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetADLDeviceEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLDeviceEventsRequest.displayName = 'proto.msh.service.v1.GetADLDeviceEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLDeviceEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetADLDeviceEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetADLDeviceEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLDeviceEventsResponse.displayName = 'proto.msh.service.v1.GetADLDeviceEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.displayName = 'proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.displayName = 'proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.displayName = 'proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceByProductNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceByProductNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceByProductNameRequest.displayName = 'proto.msh.service.v1.GetDeviceByProductNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceByProductNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceByProductNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceByProductNameResponse.displayName = 'proto.msh.service.v1.GetDeviceByProductNameResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iotDeviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    registryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cloudLocation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    blocked: (f = msg.getBlocked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddRequest}
 */
proto.msh.service.v1.AddRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddRequest;
  return proto.msh.service.v1.AddRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddRequest}
 */
proto.msh.service.v1.AddRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIotDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloudLocation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIotDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCloudLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBlocked();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.AddRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iot_device_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AddRequest.prototype.getIotDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.setIotDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.msh.service.v1.AddRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string registry_id = 4;
 * @return {string}
 */
proto.msh.service.v1.AddRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cloud_location = 5;
 * @return {string}
 */
proto.msh.service.v1.AddRequest.prototype.getCloudLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.setCloudLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.BoolValue blocked = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.msh.service.v1.AddRequest.prototype.getBlocked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.msh.service.v1.AddRequest} returns this
*/
proto.msh.service.v1.AddRequest.prototype.setBlocked = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.AddRequest} returns this
 */
proto.msh.service.v1.AddRequest.prototype.clearBlocked = function() {
  return this.setBlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.AddRequest.prototype.hasBlocked = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.DeleteRequest}
 */
proto.msh.service.v1.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.DeleteRequest;
  return proto.msh.service.v1.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.DeleteRequest}
 */
proto.msh.service.v1.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.DeleteRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.DeleteRequest} returns this
 */
proto.msh.service.v1.DeleteRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    blocked: (f = msg.getBlocked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UpdateRequest}
 */
proto.msh.service.v1.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UpdateRequest;
  return proto.msh.service.v1.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UpdateRequest}
 */
proto.msh.service.v1.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlocked();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.UpdateRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.UpdateRequest} returns this
 */
proto.msh.service.v1.UpdateRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue blocked = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.msh.service.v1.UpdateRequest.prototype.getBlocked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.msh.service.v1.UpdateRequest} returns this
*/
proto.msh.service.v1.UpdateRequest.prototype.setBlocked = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.UpdateRequest} returns this
 */
proto.msh.service.v1.UpdateRequest.prototype.clearBlocked = function() {
  return this.setBlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.UpdateRequest.prototype.hasBlocked = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ProvisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ProvisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ProvisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ProvisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structureId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    label: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ProvisionRequest}
 */
proto.msh.service.v1.ProvisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ProvisionRequest;
  return proto.msh.service.v1.ProvisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ProvisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ProvisionRequest}
 */
proto.msh.service.v1.ProvisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ProvisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ProvisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ProvisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ProvisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.ProvisionRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ProvisionRequest} returns this
 */
proto.msh.service.v1.ProvisionRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure_id = 2;
 * @return {string}
 */
proto.msh.service.v1.ProvisionRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ProvisionRequest} returns this
 */
proto.msh.service.v1.ProvisionRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_key = 4;
 * @return {string}
 */
proto.msh.service.v1.ProvisionRequest.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ProvisionRequest} returns this
 */
proto.msh.service.v1.ProvisionRequest.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string label = 5;
 * @return {string}
 */
proto.msh.service.v1.ProvisionRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ProvisionRequest} returns this
 */
proto.msh.service.v1.ProvisionRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ProvisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ProvisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ProvisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ProvisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && msh_resource_v1_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ProvisionResponse}
 */
proto.msh.service.v1.ProvisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ProvisionResponse;
  return proto.msh.service.v1.ProvisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ProvisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ProvisionResponse}
 */
proto.msh.service.v1.ProvisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ProvisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ProvisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ProvisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ProvisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Device device = 1;
 * @return {?proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.ProvisionResponse.prototype.getDevice = function() {
  return /** @type{?proto.msh.resource.v1.Device} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {?proto.msh.resource.v1.Device|undefined} value
 * @return {!proto.msh.service.v1.ProvisionResponse} returns this
*/
proto.msh.service.v1.ProvisionResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.ProvisionResponse} returns this
 */
proto.msh.service.v1.ProvisionResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.ProvisionResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AdminProvisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AdminProvisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AdminProvisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structureId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AdminProvisionRequest}
 */
proto.msh.service.v1.AdminProvisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AdminProvisionRequest;
  return proto.msh.service.v1.AdminProvisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AdminProvisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AdminProvisionRequest}
 */
proto.msh.service.v1.AdminProvisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AdminProvisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AdminProvisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AdminProvisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AdminProvisionRequest} returns this
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AdminProvisionRequest} returns this
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AdminProvisionRequest} returns this
 */
proto.msh.service.v1.AdminProvisionRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AdminProvisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AdminProvisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AdminProvisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AdminProvisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && msh_resource_v1_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AdminProvisionResponse}
 */
proto.msh.service.v1.AdminProvisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AdminProvisionResponse;
  return proto.msh.service.v1.AdminProvisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AdminProvisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AdminProvisionResponse}
 */
proto.msh.service.v1.AdminProvisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AdminProvisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AdminProvisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AdminProvisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AdminProvisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Device device = 1;
 * @return {?proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.AdminProvisionResponse.prototype.getDevice = function() {
  return /** @type{?proto.msh.resource.v1.Device} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {?proto.msh.resource.v1.Device|undefined} value
 * @return {!proto.msh.service.v1.AdminProvisionResponse} returns this
*/
proto.msh.service.v1.AdminProvisionResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.AdminProvisionResponse} returns this
 */
proto.msh.service.v1.AdminProvisionResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.AdminProvisionResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UnProvisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UnProvisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UnProvisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UnProvisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structureId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UnProvisionRequest}
 */
proto.msh.service.v1.UnProvisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UnProvisionRequest;
  return proto.msh.service.v1.UnProvisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UnProvisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UnProvisionRequest}
 */
proto.msh.service.v1.UnProvisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UnProvisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UnProvisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UnProvisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UnProvisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.UnProvisionRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.UnProvisionRequest} returns this
 */
proto.msh.service.v1.UnProvisionRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure_id = 2;
 * @return {string}
 */
proto.msh.service.v1.UnProvisionRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.UnProvisionRequest} returns this
 */
proto.msh.service.v1.UnProvisionRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UnProvisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UnProvisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UnProvisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UnProvisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UnProvisionResponse}
 */
proto.msh.service.v1.UnProvisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UnProvisionResponse;
  return proto.msh.service.v1.UnProvisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UnProvisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UnProvisionResponse}
 */
proto.msh.service.v1.UnProvisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UnProvisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UnProvisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UnProvisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UnProvisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddResponse}
 */
proto.msh.service.v1.AddResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddResponse;
  return proto.msh.service.v1.AddResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddResponse}
 */
proto.msh.service.v1.AddResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.DeleteResponse}
 */
proto.msh.service.v1.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.DeleteResponse;
  return proto.msh.service.v1.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.DeleteResponse}
 */
proto.msh.service.v1.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UpdateResponse}
 */
proto.msh.service.v1.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UpdateResponse;
  return proto.msh.service.v1.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UpdateResponse}
 */
proto.msh.service.v1.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRequest}
 */
proto.msh.service.v1.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRequest;
  return proto.msh.service.v1.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRequest}
 */
proto.msh.service.v1.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.GetRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetRequest} returns this
 */
proto.msh.service.v1.GetRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && msh_resource_v1_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetResponse}
 */
proto.msh.service.v1.GetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetResponse;
  return proto.msh.service.v1.GetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetResponse}
 */
proto.msh.service.v1.GetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Device device = 1;
 * @return {?proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.GetResponse.prototype.getDevice = function() {
  return /** @type{?proto.msh.resource.v1.Device} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {?proto.msh.resource.v1.Device|undefined} value
 * @return {!proto.msh.service.v1.GetResponse} returns this
*/
proto.msh.service.v1.GetResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetResponse} returns this
 */
proto.msh.service.v1.GetResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceByIDRequest}
 */
proto.msh.service.v1.GetDeviceByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceByIDRequest;
  return proto.msh.service.v1.GetDeviceByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceByIDRequest}
 */
proto.msh.service.v1.GetDeviceByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetDeviceByIDRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetDeviceByIDRequest} returns this
 */
proto.msh.service.v1.GetDeviceByIDRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && msh_resource_v1_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceByIDResponse}
 */
proto.msh.service.v1.GetDeviceByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceByIDResponse;
  return proto.msh.service.v1.GetDeviceByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceByIDResponse}
 */
proto.msh.service.v1.GetDeviceByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Device device = 1;
 * @return {?proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.GetDeviceByIDResponse.prototype.getDevice = function() {
  return /** @type{?proto.msh.resource.v1.Device} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {?proto.msh.resource.v1.Device|undefined} value
 * @return {!proto.msh.service.v1.GetDeviceByIDResponse} returns this
*/
proto.msh.service.v1.GetDeviceByIDResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetDeviceByIDResponse} returns this
 */
proto.msh.service.v1.GetDeviceByIDResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetDeviceByIDResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListRequest}
 */
proto.msh.service.v1.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListRequest;
  return proto.msh.service.v1.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListRequest}
 */
proto.msh.service.v1.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent_id = 1;
 * @return {string}
 */
proto.msh.service.v1.ListRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ListRequest} returns this
 */
proto.msh.service.v1.ListRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.ListRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.ListRequest} returns this
*/
proto.msh.service.v1.ListRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.ListRequest} returns this
 */
proto.msh.service.v1.ListRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.ListRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.ListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    msh_resource_v1_device_pb.Device.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListResponse}
 */
proto.msh.service.v1.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListResponse;
  return proto.msh.service.v1.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListResponse}
 */
proto.msh.service.v1.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Device devices = 1;
 * @return {!Array<!proto.msh.resource.v1.Device>}
 */
proto.msh.service.v1.ListResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Device>} value
 * @return {!proto.msh.service.v1.ListResponse} returns this
*/
proto.msh.service.v1.ListResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.ListResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.ListResponse} returns this
 */
proto.msh.service.v1.ListResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.ListResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.ListResponse} returns this
*/
proto.msh.service.v1.ListResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.ListResponse} returns this
 */
proto.msh.service.v1.ListResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.ListResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendDeviceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendDeviceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendDeviceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    command: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendDeviceCommandRequest}
 */
proto.msh.service.v1.SendDeviceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendDeviceCommandRequest;
  return proto.msh.service.v1.SendDeviceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendDeviceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendDeviceCommandRequest}
 */
proto.msh.service.v1.SendDeviceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.msh.service.v1.Command.DeviceCommand} */ (reader.readEnum());
      msg.setCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendDeviceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendDeviceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendDeviceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Command.DeviceCommand command = 2;
 * @return {!proto.msh.service.v1.Command.DeviceCommand}
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.getCommand = function() {
  return /** @type {!proto.msh.service.v1.Command.DeviceCommand} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.msh.service.v1.Command.DeviceCommand} value
 * @return {!proto.msh.service.v1.SendDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendDeviceCommandRequest.prototype.setCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendDeviceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendDeviceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendDeviceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendDeviceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendDeviceCommandResponse}
 */
proto.msh.service.v1.SendDeviceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendDeviceCommandResponse;
  return proto.msh.service.v1.SendDeviceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendDeviceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendDeviceCommandResponse}
 */
proto.msh.service.v1.SendDeviceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendDeviceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendDeviceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendDeviceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendDeviceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendRebootCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendRebootCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendRebootCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendRebootCommandRequest}
 */
proto.msh.service.v1.SendRebootCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendRebootCommandRequest;
  return proto.msh.service.v1.SendRebootCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendRebootCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendRebootCommandRequest}
 */
proto.msh.service.v1.SendRebootCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendRebootCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendRebootCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendRebootCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendRebootCommandRequest} returns this
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendRebootCommandRequest} returns this
 */
proto.msh.service.v1.SendRebootCommandRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendRebootCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendRebootCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendRebootCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendRebootCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendRebootCommandResponse}
 */
proto.msh.service.v1.SendRebootCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendRebootCommandResponse;
  return proto.msh.service.v1.SendRebootCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendRebootCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendRebootCommandResponse}
 */
proto.msh.service.v1.SendRebootCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendRebootCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendRebootCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendRebootCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendRebootCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userTag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartRecordingDeviceCommandRequest;
  return proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 duration = 2;
 * @return {number}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_tag = 4;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.getUserTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandRequest.prototype.setUserTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && msh_common_v1_common_pb.IOTCoreCommandStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartRecordingDeviceCommandResponse;
  return proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.common.v1.IOTCoreCommandStatus status = 2;
 * @return {?proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.getStatus = function() {
  return /** @type{?proto.msh.common.v1.IOTCoreCommandStatus} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 2));
};


/**
 * @param {?proto.msh.common.v1.IOTCoreCommandStatus|undefined} value
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} returns this
*/
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userTag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest;
  return proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string device_ids = 1;
 * @return {!Array<string>}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional int32 duration = 2;
 * @return {number}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_tag = 4;
 * @return {string}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.getUserTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.setUserTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceStartResponsesList: jspb.Message.toObjectList(msg.getDeviceStartResponsesList(),
    proto.msh.service.v1.DeviceStartGroupResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse;
  return proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = new proto.msh.service.v1.DeviceStartGroupResponse;
      reader.readMessage(value,proto.msh.service.v1.DeviceStartGroupResponse.deserializeBinaryFromReader);
      msg.addDeviceStartResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceStartResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.msh.service.v1.DeviceStartGroupResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DeviceStartGroupResponse device_start_responses = 2;
 * @return {!Array<!proto.msh.service.v1.DeviceStartGroupResponse>}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.getDeviceStartResponsesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.DeviceStartGroupResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.DeviceStartGroupResponse, 2));
};


/**
 * @param {!Array<!proto.msh.service.v1.DeviceStartGroupResponse>} value
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} returns this
*/
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.setDeviceStartResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.msh.service.v1.DeviceStartGroupResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse}
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.addDeviceStartResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.msh.service.v1.DeviceStartGroupResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.prototype.clearDeviceStartResponsesList = function() {
  return this.setDeviceStartResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.DeviceStartGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.DeviceStartGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeviceStartGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && msh_common_v1_common_pb.IOTCoreCommandStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse}
 */
proto.msh.service.v1.DeviceStartGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.DeviceStartGroupResponse;
  return proto.msh.service.v1.DeviceStartGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.DeviceStartGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse}
 */
proto.msh.service.v1.DeviceStartGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.DeviceStartGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.DeviceStartGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeviceStartGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse} returns this
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.common.v1.IOTCoreCommandStatus status = 2;
 * @return {?proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.getStatus = function() {
  return /** @type{?proto.msh.common.v1.IOTCoreCommandStatus} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 2));
};


/**
 * @param {?proto.msh.common.v1.IOTCoreCommandStatus|undefined} value
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse} returns this
*/
proto.msh.service.v1.DeviceStartGroupResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.DeviceStartGroupResponse} returns this
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.DeviceStartGroupResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStopRecordingDeviceCommandRequest;
  return proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && msh_common_v1_common_pb.IOTCoreCommandStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStopRecordingDeviceCommandResponse;
  return proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.common.v1.IOTCoreCommandStatus status = 1;
 * @return {?proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.getStatus = function() {
  return /** @type{?proto.msh.common.v1.IOTCoreCommandStatus} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 1));
};


/**
 * @param {?proto.msh.common.v1.IOTCoreCommandStatus|undefined} value
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse} returns this
*/
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest;
  return proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} returns this
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && msh_common_v1_common_pb.IOTCoreCommandStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse;
  return proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.common.v1.IOTCoreCommandStatus status = 1;
 * @return {?proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.getStatus = function() {
  return /** @type{?proto.msh.common.v1.IOTCoreCommandStatus} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 1));
};


/**
 * @param {?proto.msh.common.v1.IOTCoreCommandStatus|undefined} value
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse} returns this
*/
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse} returns this
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLDeviceEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLDeviceEventsRequest;
  return proto.msh.service.v1.GetADLDeviceEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLDeviceEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest} returns this
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest} returns this
*/
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest} returns this
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest} returns this
*/
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsRequest} returns this
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLDeviceEventsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLDeviceEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLDeviceEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLDeviceEventsResponse;
  return proto.msh.service.v1.GetADLDeviceEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLDeviceEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLDeviceEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLDeviceEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse} returns this
*/
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse} returns this
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse} returns this
*/
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLDeviceEventsResponse} returns this
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLDeviceEventsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest;
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.common.v1.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} returns this
*/
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.TimeFrame time_frame = 3;
 * @return {!proto.msh.common.v1.TimeFrame}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.msh.common.v1.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.common.v1.TimeFrame} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse;
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory;
      reader.readMessage(value,proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activityLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory;
  return proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActivityLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivityLevel();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} returns this
*/
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double activity_level = 2;
 * @return {number}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.getActivityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory.prototype.setActivityLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated ActivityHistory activities = 1;
 * @return {!Array<!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory>}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory>} value
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse} returns this
*/
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.ActivityHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse} returns this
 */
proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceByProductNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceByProductNameRequest}
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceByProductNameRequest;
  return proto.msh.service.v1.GetDeviceByProductNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceByProductNameRequest}
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceByProductNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetDeviceByProductNameRequest} returns this
 */
proto.msh.service.v1.GetDeviceByProductNameRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceByProductNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceByProductNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && msh_resource_v1_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceByProductNameResponse}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceByProductNameResponse;
  return proto.msh.service.v1.GetDeviceByProductNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceByProductNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceByProductNameResponse}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceByProductNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceByProductNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Device device = 1;
 * @return {?proto.msh.resource.v1.Device}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.getDevice = function() {
  return /** @type{?proto.msh.resource.v1.Device} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_device_pb.Device, 1));
};


/**
 * @param {?proto.msh.resource.v1.Device|undefined} value
 * @return {!proto.msh.service.v1.GetDeviceByProductNameResponse} returns this
*/
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetDeviceByProductNameResponse} returns this
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetDeviceByProductNameResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.msh.service.v1);
