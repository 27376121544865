// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import Vue from "vue";
import Vuex from "vuex";

import { registerAccountCallbacks } from "@/store/account.module";
import account from "@/store/account.module";
import auth from "@/store/auth.module";
import profile from "@/store/profile.module";
import loading from "@/store/loading.module";
import floors from "@/store/floors.module";
import building from "@/store/buildings.module";
import breadcrumbs from "@/store/breadcrumbs.module";
import structure from "@/store/structure.module";
import recordings from "@/store/recordings.module";
import { registerBuildingCallbacks } from "@/store/buildings.module";
import { registerProfileCallbacks } from "@/store/profile.module";
import { registerFloorsCallbacks } from "@/store/floors.module";

Vue.use(Vuex);

export const registerStoreCallbacks = () => {
  registerAccountCallbacks();
  registerProfileCallbacks();
  registerBuildingCallbacks();
  registerFloorsCallbacks();
};

export default new Vuex.Store({
  modules: {
    account,
    building,
    breadcrumbs,
    auth,
    loading,
    floors,
    profile,
    structure,
    recordings,
  },
});
