// Français
export const locale = {
  msh: {
    login: {
      sideTitle: "Portail Moonshot Health",
      email: "Courriel",
      password: "Mot de passe",
      signIn: "Connexion",
      welcome: "Bienvenue",
      fixValidation: "Information invalide",
    },
    forgot: {
      title: "Oublié votre mot de passe ?",
      desc: "Entrer votre courriel pour réinitialiser votre mot de passe",
      submit: "Confirmer",
      cancel: "Annuler",
      success: "Votre compte a été réinitialisé avec succès.",
    },
    aside: {
      title: "Moonshot Health",
      dashboard: "Tableau de bord",
      appartments: "Appartements",
      devices: "Appareils",
      recordings: "Enregistrements",
      provision: "Enregistrer un nouvel appareil",
    },
    user: {
      profile: "Profile",
      signOut: "Déconnexion",
    },
    buildingSelector: {
      title: "Tout les immeubles",
    },
    appartments: {
      title: "Appartements",
      titleSingle: "Appartement {name}",
      timeline: "Ligne du temps",
      devices: "Appareils",
      lastEvents: "Aucun événement | Un événement | {count} événements",
      withActivity: "Avec Activité",
      withoutActivity: "Sans Activité",
      showingTotal: "Aucun appartement | Un appartement | {count} appartements",
      filter: "Filtrer",
      presence: "Présence",
      activityLevel: "Niveau d'activité",
      extra: "...",
      name: "Nom",
      showMore: "Plus",
      event: {
        out: "Sortie",
        new: "new",
        acknowledge: "acknowledged",
        noActivitySince: "aucune activité depuis {time}",
        fall: "Chute détectée",
        generic: "Alerte active",
      },
    },
    devices: {
      title: "Appareils",
      titleSingle: "Appareil {name}",
      label: "Nom",
      online: {
        title: "En ligne / Hors ligne",
        true: "En ligne",
        false: "Hors ligne",
      },
      recording: {
        status: "Status de l'enregistrement",
        startedOnNumberOfDevices:
          "Enregistrement débuté | Enregistrement débuté sur un appareil | Enregistrement débuté sur {count} appareils",
        started: "Enregistrement débuté",
        stop: "Arrêter",
        trigger: "Débuter",
        cancel: "Annuler",
        stopRecording: "Arrêter l'enregistrement",
        triggerRecording: "Débuter un enregistrement",
        cancelRecording: "Annuler l'enregistrement",
        label: {
          label: "Nom ce cet enregistrement",
          duration: "Durée (en minutes)",
        },
      },
      showingTotal: "Aucun Appareil | Un Appareil | {count} Appareils",
      withActivity: "Avec Activité",
      withoutActivity: "Sans Activité",
      timeline: "Ligne du temps",
      lastEvents: "Aucun événement | Un événement | {count} événements",
      latestSensor: "Données des capteurs",
      showMore: "Plus",
    },
    dashboard: {
      incidents: {
        title: "Incidents",
        number: "Aucun Incidents | Un Incident | {count} Incidents",
        active: "Actif",
        resolved: "Résolue",
        all: "Tous",
        titleSingle: "Incident {name}",
        responseTimeline: "Ligne du temps / Réponse",
        historyRequest: "Dernier avis envoyé à {name} à {time}",
        appartment: "Appartement",
        floor: "Étage",
      },
      charts: {
        present: "Présent",
        absent: "Absent",
        total: "Total",
        online: "En ligne",
        offline: "hors ligne",
      },
      quickOverview: {
        presenceTitle: "Présence",
        presenceText:
          "Tous les redisant sont présents. | Un résidant est absent de son appartement. | {count} résidants sont absents de leur appartement.",
        statusTitle: "Appareil connectées",
        statusText:
          "Tous les appareils sont en ligne. | Un appareil est hors-ligne. | {count} appareils sont hors-ligne.",
        alertTitle: "Incidents",
        alertText:
          "Aucun incident à rapporter. | Un incident actif. | {count} incidents actifs.",
      },
    },
    notification: {
      title: "Notification | Notification | Notifications",
      recent: "Notifications récentes",
      number: "Aucun nouveau | Un nouveau | {count} nouveaux",
    },
    home: {
      title: "Accueil",
      attentionRequired: "Attention Requise",
      tenants: "Locataires",
    },
    floor: {
      status: "Status",
      activityLevel: "Niveau d'activité",
      alert: "Alerte",
      alertActive: "Chute",
      noActiveAlert: "Aucune alerte",
      presence: {
        title: "Présence",
        noPresence: "Aucune présence enregistrée",
        lastSeen: "Vue la dernière fois à",
        0: "Indéfini",
        1: "Absent",
        2: "Présent",
        3: "Inconnu",
      },
      event: {
        out: "Sortie",
        new: "nouveau",
        acknowledge: "répondu",
        noActivitySince: "aucune activité depuis {time}",
        fall: "Chute détectée",
        generic: "Alerte active",
        withAppt: {
          fall: "Chute détectée au {appt}",
          presence: "Présence mise à jour au {appt}",
          activity: "Niveau d'activité enregistré au {appt}",
          audioClassification: "",
          connectivity: "",
          heartRate: "",
          respirationRate: "",
          temperature: "Température enregistrée au {appt}",
          pressure: "Pression atmosphérique enregistrée au {appt}",
          humidity: "Niveau d'humidité enregistré au {appt}",
          lightIntensity: "Intensité lumineuse enregistré au {appt}",
        },
        detail: {
          fall: "Chute {type} détecté par {source}",
          fallLevel: {
            0: "douce",
            1: "douce",
            2: "dur",
          },
          fallSource: {
            radar: "radar",
            audio: "audio",
            accelerometer: "accélérometre",
          },
        },
      },
    },
    detail: {
      title: "Détail",
      alertTab: "Alerte active",
      deviceTab: "Appareils",
      timelineTab: "Fil d'évènements",
      alert: {
        fall: "Chute détectée",
        numberOfUnits: "Aucune unité | 1 unité | {count} unités",
        noActiveAlert: "Aucune alerte active",
        eventTimeline: "Fil d'événements",
        addResponse: "Ajouter information / Réponse",
        request: "Une requête a été envoyée à {name}",
        ack: "{user} a accusé la reception de cette alerte",
      },
      devices: {
        title: "Appareil | Appareil | Appareils",
        device: "Appareil",
        noDevice: "Aucun apparail enregistré pour cet appartement",
      },
      timeline: {
        noData: "Aucune données pour cette période",
        presenceDetected: "Présence détectée",
        presenceNotDetected: "Absence",
        alertSent: "Une alerte a été créée",
        requestSent: "Un requête pour assistance a été envoyé à {name}",
        ackReceived: "L'alerte a été traitée par {name}",
        sinceYesterday: "Depuis une journée",
        sinceLastWeek: "Depuis une semaine",
        sinceLastMonth: "Depuis un mois",
        showMore: "Plus",
      },
    },
    date: {
      yesterday: "Hier",
      today: "Aujourd'hui",
    },
    error: {
      title: "404",
      desc: "Désolé une erreur c'est produite.",
    },
    validation: {
      format: {
        email: "Le courriel utilisé n'est pas valide",
        password: "",
        nameUnderscore: "Le nom ne peut contenir un trait de soulignement",
        nameSpace: "Le nom ne peut contenir un espace vide",
        durationPositive: "La durée doit être un nombre positif",
      },
      required: {
        email: "Courriel est requis",
        password: "Mot de passe est requis",
        name: "Nom est requis",
        duration: "Durée est requise",
      },
    },
    recordings: {
      download: {
        download: "Télécharger",
        ready: "Fichier prêt au téléchargement",
      },
      history: "History",
      status: {
        0: "Inconnu",
        1: "En enregistrement",
        2: "Terminé",
        3: "Annulé",
        4: "En erreur",
        5: "En préparation pour le téléversement",
        6: "En téléversement",
        7: "Était déjà en enregistrement",
        8: "Commande d'enregistrement envoyé",
        9: "Enregistrement confirmé",
        10: "Enregistrement effacé",
      },
    },
    loading: "Chargement",
    search: "Rechercher",
  },
  2: "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/too-many-requests":
    "L'accès de ce compte a été temporairement bloqué. Veillez essayer un peu plus tard.",
  "auth/user-not-found": "Aucun accès pour ce courriel.",
  "auth/wrong-password":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/account-exists-with-different-credential":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/invalid-credential":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/operation-not-allowed":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/user-disabled": "Accès .",
  "auth/invalid-verification-code":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/invalid-verification-id":
    "Vous avez entrée une mauvaise combinaison de courriel et mot de passe.",
  "auth/invalid-email": "Vous avez entrée un mauvais courriel.",
};
