/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var msh_resource_v1_device_pb = require('../../../msh/resource/v1/device_pb.js')

var msh_resource_v1_event_pb = require('../../../msh/resource/v1/event_pb.js')

var msh_service_v1_command_pb = require('../../../msh/service/v1/command_pb.js')

var msh_trait_activities_v1_activity_level_pb = require('../../../msh/trait/activities/v1/activity_level_pb.js')

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js')
const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./device_manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.DeviceManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.DeviceManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddRequest,
 *   !proto.msh.service.v1.AddResponse>}
 */
const methodDescriptor_DeviceManager_Add = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/Add',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddRequest,
  proto.msh.service.v1.AddResponse,
  /**
   * @param {!proto.msh.service.v1.AddRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddRequest,
 *   !proto.msh.service.v1.AddResponse>}
 */
const methodInfo_DeviceManager_Add = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddResponse,
  /**
   * @param {!proto.msh.service.v1.AddRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.add =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Add',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Add,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.add =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Add',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Add);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.DeleteRequest,
 *   !proto.msh.service.v1.DeleteResponse>}
 */
const methodDescriptor_DeviceManager_Delete = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/Delete',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.DeleteRequest,
  proto.msh.service.v1.DeleteResponse,
  /**
   * @param {!proto.msh.service.v1.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.DeleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.DeleteRequest,
 *   !proto.msh.service.v1.DeleteResponse>}
 */
const methodInfo_DeviceManager_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.DeleteResponse,
  /**
   * @param {!proto.msh.service.v1.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.DeleteResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.DeleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.DeleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Delete',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Delete,
      callback);
};


/**
 * @param {!proto.msh.service.v1.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.DeleteResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Delete',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.UpdateRequest,
 *   !proto.msh.service.v1.UpdateResponse>}
 */
const methodDescriptor_DeviceManager_Update = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/Update',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.UpdateRequest,
  proto.msh.service.v1.UpdateResponse,
  /**
   * @param {!proto.msh.service.v1.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UpdateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.UpdateRequest,
 *   !proto.msh.service.v1.UpdateResponse>}
 */
const methodInfo_DeviceManager_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.UpdateResponse,
  /**
   * @param {!proto.msh.service.v1.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UpdateResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.UpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.UpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Update',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Update,
      callback);
};


/**
 * @param {!proto.msh.service.v1.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.UpdateResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Update',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ProvisionRequest,
 *   !proto.msh.service.v1.ProvisionResponse>}
 */
const methodDescriptor_DeviceManager_Provision = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/Provision',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ProvisionRequest,
  proto.msh.service.v1.ProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.ProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ProvisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ProvisionRequest,
 *   !proto.msh.service.v1.ProvisionResponse>}
 */
const methodInfo_DeviceManager_Provision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.ProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ProvisionResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ProvisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ProvisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.provision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Provision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Provision,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ProvisionResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.provision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Provision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Provision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AdminProvisionRequest,
 *   !proto.msh.service.v1.AdminProvisionResponse>}
 */
const methodDescriptor_DeviceManager_AdminProvision = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/AdminProvision',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AdminProvisionRequest,
  proto.msh.service.v1.AdminProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.AdminProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AdminProvisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AdminProvisionRequest,
 *   !proto.msh.service.v1.AdminProvisionResponse>}
 */
const methodInfo_DeviceManager_AdminProvision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AdminProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.AdminProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AdminProvisionResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AdminProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AdminProvisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AdminProvisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.adminProvision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/AdminProvision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_AdminProvision,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AdminProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AdminProvisionResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.adminProvision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/AdminProvision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_AdminProvision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.UnProvisionRequest,
 *   !proto.msh.service.v1.UnProvisionResponse>}
 */
const methodDescriptor_DeviceManager_UnProvision = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/UnProvision',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.UnProvisionRequest,
  proto.msh.service.v1.UnProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.UnProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UnProvisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.UnProvisionRequest,
 *   !proto.msh.service.v1.UnProvisionResponse>}
 */
const methodInfo_DeviceManager_UnProvision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.UnProvisionResponse,
  /**
   * @param {!proto.msh.service.v1.UnProvisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UnProvisionResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.UnProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.UnProvisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.UnProvisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.unProvision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/UnProvision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_UnProvision,
      callback);
};


/**
 * @param {!proto.msh.service.v1.UnProvisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.UnProvisionResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.unProvision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/UnProvision',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_UnProvision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetRequest,
 *   !proto.msh.service.v1.GetResponse>}
 */
const methodDescriptor_DeviceManager_Get = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/Get',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetRequest,
  proto.msh.service.v1.GetResponse,
  /**
   * @param {!proto.msh.service.v1.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetRequest,
 *   !proto.msh.service.v1.GetResponse>}
 */
const methodInfo_DeviceManager_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetResponse,
  /**
   * @param {!proto.msh.service.v1.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Get',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Get,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/Get',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetDeviceByIDRequest,
 *   !proto.msh.service.v1.GetDeviceByIDResponse>}
 */
const methodDescriptor_DeviceManager_GetDeviceByID = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/GetDeviceByID',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetDeviceByIDRequest,
  proto.msh.service.v1.GetDeviceByIDResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceByIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetDeviceByIDRequest,
 *   !proto.msh.service.v1.GetDeviceByIDResponse>}
 */
const methodInfo_DeviceManager_GetDeviceByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetDeviceByIDResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceByIDResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetDeviceByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetDeviceByIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetDeviceByIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.getDeviceByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceByID',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceByID,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetDeviceByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetDeviceByIDResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.getDeviceByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceByID',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ListRequest,
 *   !proto.msh.service.v1.ListResponse>}
 */
const methodDescriptor_DeviceManager_List = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/List',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ListRequest,
  proto.msh.service.v1.ListResponse,
  /**
   * @param {!proto.msh.service.v1.ListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ListRequest,
 *   !proto.msh.service.v1.ListResponse>}
 */
const methodInfo_DeviceManager_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ListResponse,
  /**
   * @param {!proto.msh.service.v1.ListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/List',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_List,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ListResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/List',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendDeviceCommandRequest,
 *   !proto.msh.service.v1.SendDeviceCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendDeviceCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendDeviceCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendDeviceCommandRequest,
  proto.msh.service.v1.SendDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendDeviceCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendDeviceCommandRequest,
 *   !proto.msh.service.v1.SendDeviceCommandResponse>}
 */
const methodInfo_DeviceManager_SendDeviceCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendDeviceCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendDeviceCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendDeviceCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendDeviceCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendDeviceCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendDeviceCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendDeviceCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendDeviceCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendRebootCommandRequest,
 *   !proto.msh.service.v1.SendRebootCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendRebootCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendRebootCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendRebootCommandRequest,
  proto.msh.service.v1.SendRebootCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendRebootCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendRebootCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendRebootCommandRequest,
 *   !proto.msh.service.v1.SendRebootCommandResponse>}
 */
const methodInfo_DeviceManager_SendRebootCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendRebootCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendRebootCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendRebootCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendRebootCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendRebootCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendRebootCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendRebootCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendRebootCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendRebootCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendRebootCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendRebootCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendRebootCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendRebootCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendRebootCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendStartRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStartRecordingDeviceCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendStartRecordingDeviceCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendStartRecordingDeviceCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendStartRecordingDeviceCommandRequest,
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendStartRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStartRecordingDeviceCommandResponse>}
 */
const methodInfo_DeviceManager_SendStartRecordingDeviceCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendStartRecordingDeviceCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendStartRecordingDeviceCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStartRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStartRecordingDeviceCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendStartRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendStartRecordingDeviceCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendStartRecordingDeviceCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStartRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStartRecordingDeviceCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendStopRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStopRecordingDeviceCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendStopRecordingDeviceCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendStopRecordingDeviceCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendStopRecordingDeviceCommandRequest,
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendStopRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStopRecordingDeviceCommandResponse>}
 */
const methodInfo_DeviceManager_SendStopRecordingDeviceCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStopRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendStopRecordingDeviceCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendStopRecordingDeviceCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStopRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStopRecordingDeviceCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendStopRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendStopRecordingDeviceCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendStopRecordingDeviceCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStopRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStopRecordingDeviceCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendCancelRecordingDeviceCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendCancelRecordingDeviceCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest,
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse>}
 */
const methodInfo_DeviceManager_SendCancelRecordingDeviceCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendCancelRecordingDeviceCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendCancelRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendCancelRecordingDeviceCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendCancelRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendCancelRecordingDeviceCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendCancelRecordingDeviceCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendCancelRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendCancelRecordingDeviceCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse>}
 */
const methodDescriptor_DeviceManager_SendStartGroupRecordingDeviceCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/SendStartGroupRecordingDeviceCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest,
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest,
 *   !proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse>}
 */
const methodInfo_DeviceManager_SendStartGroupRecordingDeviceCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.sendStartGroupRecordingDeviceCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStartGroupRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStartGroupRecordingDeviceCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendStartGroupRecordingDeviceCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.sendStartGroupRecordingDeviceCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/SendStartGroupRecordingDeviceCommand',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_SendStartGroupRecordingDeviceCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetADLDeviceEventsRequest,
 *   !proto.msh.service.v1.GetADLDeviceEventsResponse>}
 */
const methodDescriptor_DeviceManager_GetADLDeviceEvents = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/GetADLDeviceEvents',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetADLDeviceEventsRequest,
  proto.msh.service.v1.GetADLDeviceEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLDeviceEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetADLDeviceEventsRequest,
 *   !proto.msh.service.v1.GetADLDeviceEventsResponse>}
 */
const methodInfo_DeviceManager_GetADLDeviceEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetADLDeviceEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLDeviceEventsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetADLDeviceEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetADLDeviceEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.getADLDeviceEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetADLDeviceEvents',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetADLDeviceEvents,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetADLDeviceEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetADLDeviceEventsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.getADLDeviceEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetADLDeviceEvents',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetADLDeviceEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest,
 *   !proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse>}
 */
const methodDescriptor_DeviceManager_GetDeviceActivityLevelHistory = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/GetDeviceActivityLevelHistory',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest,
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest,
 *   !proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse>}
 */
const methodInfo_DeviceManager_GetDeviceActivityLevelHistory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.getDeviceActivityLevelHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceActivityLevelHistory',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceActivityLevelHistory,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetDeviceActivityLevelHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetDeviceActivityLevelHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.getDeviceActivityLevelHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceActivityLevelHistory',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceActivityLevelHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetDeviceByProductNameRequest,
 *   !proto.msh.service.v1.GetDeviceByProductNameResponse>}
 */
const methodDescriptor_DeviceManager_GetDeviceByProductName = new grpc.web.MethodDescriptor(
  '/msh.service.v1.DeviceManager/GetDeviceByProductName',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetDeviceByProductNameRequest,
  proto.msh.service.v1.GetDeviceByProductNameResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceByProductNameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetDeviceByProductNameRequest,
 *   !proto.msh.service.v1.GetDeviceByProductNameResponse>}
 */
const methodInfo_DeviceManager_GetDeviceByProductName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetDeviceByProductNameResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceByProductNameResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetDeviceByProductNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetDeviceByProductNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.DeviceManagerClient.prototype.getDeviceByProductName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceByProductName',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceByProductName,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetDeviceByProductNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetDeviceByProductNameResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.DeviceManagerPromiseClient.prototype.getDeviceByProductName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.DeviceManager/GetDeviceByProductName',
      request,
      metadata || {},
      methodDescriptor_DeviceManager_GetDeviceByProductName);
};


module.exports = proto.msh.service.v1;

