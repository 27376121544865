// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
// action types
import {
  BREADCRUMB_SET_REQUEST,
  BREADCRUMB_SET_SUCCESS,
} from "@/store/constants";
export const APPEND_BREADCRUM = "appendBreadcrumb";

export default {
  state: {
    breadcrumbs: [],
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs(state) {
      return state.breadcrumbs;
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle(state) {
      let last = state.breadcrumbs[state.breadcrumbs.length - 1];
      if (last && last.title) {
        return last.title;
      }
    },
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [BREADCRUMB_SET_REQUEST](state, payload) {
      state.commit(BREADCRUMB_SET_SUCCESS, payload);
    },
  },

  mutations: {
    [BREADCRUMB_SET_SUCCESS](state, payload) {
      state.breadcrumbs = payload;
    },
  },
};
