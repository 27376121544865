// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.

// Global application events definition
// This module contains all of the events used in the applicaton
// To be used with the main.js => bus

// Auth
export const AUTH_LOGINCONFIRMED = "event:auth-loginConfirmed";
export const AUTH_LOGINERROR = "event:auth-loginError";
export const AUTH_LOGOUTERROR = "event:auth-logoutError";
export const AUTH_LOGOUTCONFIRMED = "event:auth-logoutConfirmed";

// Account
export const ACCOUNT_CHANGED_EVENT = "event:account-changedEvent";
//Component Events
export const SIDENAVIGATION_TOGGLE = "sidenavigation:toggle";

// Building
export const BUILDING_SET_CURRENTCONFIRMED = "event:building-currentConfirmed";
export const BUILDING_CHANGED_CURRENTCONFIRMED =
  "event:building-currentChangedConfirmed";
// Errors
export const ERROR_EVENT = "event:error";

// Pusher - Server events
export const PUSHER_SUBSCRIPTION_SUCCEEDED = "pusher:subscription_succeeded";

// Device - Trigger recording results
export const DEVICE_TRIGGER_RECORDING_SUCCEEDED =
  "event:device-triggerRecordingSucceeded";
export const DEVICE_TRIGGER_RECORDING_ERROR =
  "event:device-triggerRecordingError";
// Device - Stop recording results
export const DEVICE_STOP_RECORDING_SUCCEEDED =
  "event:device-stopRecordingSucceeded";
export const DEVICE_STOP_RECORDING_ERROR = "event:device-stopRecordingError";
export const DEVICE_CANCEL_RECORDING_SUCCEEDED =
  "event:device-cancelRecordingSucceeded";
export const DEVICE_CANCEL_RECORDING_ERROR =
  "event:device-cancelRecordingError";
export const DEVICE_UNPROVISION_DEVICE_SUCCEEDED =
  "event:device-unprovisionSucceeded";
export const DEVICE_UNPROVISION_DEVICE_ERROR = "event:device-unprovisionError";
export const DEVICE_PROVISION_DEVICE_SUCCEEDED =
  "event:device-provisionSucceeded";
export const DEVICE_PROVISION_DEVICE_ERROR = "event:device-provisionError";
