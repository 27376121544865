/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./utils_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.UtilsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.UtilsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.PingRequest,
 *   !proto.msh.service.v1.PingResponse>}
 */
const methodDescriptor_Utils_Ping = new grpc.web.MethodDescriptor(
  '/msh.service.v1.Utils/Ping',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.PingRequest,
  proto.msh.service.v1.PingResponse,
  /**
   * @param {!proto.msh.service.v1.PingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.PingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.PingRequest,
 *   !proto.msh.service.v1.PingResponse>}
 */
const methodInfo_Utils_Ping = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.PingResponse,
  /**
   * @param {!proto.msh.service.v1.PingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.PingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.PingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.PingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.PingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.UtilsClient.prototype.ping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.Utils/Ping',
      request,
      metadata || {},
      methodDescriptor_Utils_Ping,
      callback);
};


/**
 * @param {!proto.msh.service.v1.PingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.PingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.UtilsPromiseClient.prototype.ping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.Utils/Ping',
      request,
      metadata || {},
      methodDescriptor_Utils_Ping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SecurePingRequest,
 *   !proto.msh.service.v1.SecurePingResponse>}
 */
const methodDescriptor_Utils_SecurePing = new grpc.web.MethodDescriptor(
  '/msh.service.v1.Utils/SecurePing',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SecurePingRequest,
  proto.msh.service.v1.SecurePingResponse,
  /**
   * @param {!proto.msh.service.v1.SecurePingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SecurePingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SecurePingRequest,
 *   !proto.msh.service.v1.SecurePingResponse>}
 */
const methodInfo_Utils_SecurePing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SecurePingResponse,
  /**
   * @param {!proto.msh.service.v1.SecurePingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SecurePingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SecurePingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SecurePingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SecurePingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.UtilsClient.prototype.securePing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.Utils/SecurePing',
      request,
      metadata || {},
      methodDescriptor_Utils_SecurePing,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SecurePingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SecurePingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.UtilsPromiseClient.prototype.securePing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.Utils/SecurePing',
      request,
      metadata || {},
      methodDescriptor_Utils_SecurePing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ServiceAccountSecurePingRequest,
 *   !proto.msh.service.v1.ServiceAccountSecurePingResponse>}
 */
const methodDescriptor_Utils_ServiceAccountSecurePing = new grpc.web.MethodDescriptor(
  '/msh.service.v1.Utils/ServiceAccountSecurePing',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ServiceAccountSecurePingRequest,
  proto.msh.service.v1.ServiceAccountSecurePingResponse,
  /**
   * @param {!proto.msh.service.v1.ServiceAccountSecurePingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ServiceAccountSecurePingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ServiceAccountSecurePingRequest,
 *   !proto.msh.service.v1.ServiceAccountSecurePingResponse>}
 */
const methodInfo_Utils_ServiceAccountSecurePing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ServiceAccountSecurePingResponse,
  /**
   * @param {!proto.msh.service.v1.ServiceAccountSecurePingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ServiceAccountSecurePingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ServiceAccountSecurePingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ServiceAccountSecurePingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ServiceAccountSecurePingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.UtilsClient.prototype.serviceAccountSecurePing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.Utils/ServiceAccountSecurePing',
      request,
      metadata || {},
      methodDescriptor_Utils_ServiceAccountSecurePing,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ServiceAccountSecurePingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ServiceAccountSecurePingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.UtilsPromiseClient.prototype.serviceAccountSecurePing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.Utils/ServiceAccountSecurePing',
      request,
      metadata || {},
      methodDescriptor_Utils_ServiceAccountSecurePing);
};


module.exports = proto.msh.service.v1;

