import timestampProto from "@/grpc/google/protobuf/timestamp_pb";
import commonDef from "@/grpc/msh/common/v1/common_pb";

const dateUtils = {
  /**
   * Generate a date range since one day
   */
  generateDateRangeForTwelveHours() {
    const today = new Date();
    const twelveHourAgo = new Date();
    twelveHourAgo.setHours(today.getHours() - 12);
    const dateStart = new timestampProto.Timestamp();
    dateStart.fromDate(twelveHourAgo);
    const dateEnd = new timestampProto.Timestamp();
    dateEnd.fromDate(today);
    const protoRange = new commonDef.TimeRange();
    protoRange.setStartTime(dateStart);
    protoRange.setEndTime(dateEnd);
    return protoRange;
  },
  /**
   * Generate a date range since one day
   */
  generateDateRangeForOneDay() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const dateStart = new timestampProto.Timestamp();
    dateStart.fromDate(yesterday);
    const dateEnd = new timestampProto.Timestamp();
    dateEnd.fromDate(today);
    const protoRange = new commonDef.TimeRange();
    protoRange.setStartTime(dateStart);
    protoRange.setEndTime(dateEnd);
    return protoRange;
  },
  /**
   * Generate a date range since one week (7 days)
   */
  generateDateRangeForOneWeek() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 7);
    const dateStart = new timestampProto.Timestamp();
    dateStart.fromDate(yesterday);
    const dateEnd = new timestampProto.Timestamp();
    dateEnd.fromDate(today);
    const protoRange = new commonDef.TimeRange();
    protoRange.setStartTime(dateStart);
    protoRange.setEndTime(dateEnd);
    return protoRange;
  },
  /**
   * Generate a date range since one month
   */
  generateDateRangeForOneMonth() {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);
    const dateStart = new timestampProto.Timestamp();
    dateStart.fromDate(lastMonth);
    const dateEnd = new timestampProto.Timestamp();
    dateEnd.fromDate(today);
    const protoRange = new commonDef.TimeRange();
    protoRange.setStartTime(dateStart);
    protoRange.setEndTime(dateEnd);
    return protoRange;
  },
  /**
   * Check if the passed date is part of the current date
   */
  isDateToday(dateToCheck) {
    const today = new Date();
    if (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    ) {
      return true;
    }
    return false;
  },
  /**
   * Check if the passed date is yesterday
   */
  isDateYesterday(dateToCheck) {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    if (
      dateToCheck.getDate() === yesterday.getDate() &&
      dateToCheck.getMonth() === yesterday.getMonth() &&
      dateToCheck.getFullYear() === yesterday.getFullYear()
    ) {
      return true;
    }
    return false;
  },
};

export default dateUtils;
