// source: msh/trait/sensor/v1/temperature.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var msh_common_v1_capture_mode_pb = require('../../../../msh/common/v1/capture_mode_pb.js');
goog.object.extend(proto, msh_common_v1_capture_mode_pb);
goog.exportSymbol('proto.msh.trait.sensor.v1.TemperatureTrait', null, global);
goog.exportSymbol('proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.sensor.v1.TemperatureTrait = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.sensor.v1.TemperatureTrait, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.sensor.v1.TemperatureTrait.displayName = 'proto.msh.trait.sensor.v1.TemperatureTrait';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.displayName = 'proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.sensor.v1.TemperatureTrait.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.sensor.v1.TemperatureTrait.toObject = function(includeInstance, msg) {
  var f, obj = {
    temperature: (f = msg.getTemperature()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.sensor.v1.TemperatureTrait;
  return proto.msh.trait.sensor.v1.TemperatureTrait.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.sensor.v1.TemperatureTrait.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.sensor.v1.TemperatureTrait.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    trait: (f = msg.getTrait()) && proto.msh.trait.sensor.v1.TemperatureTrait.toObject(includeInstance, f),
    captureMode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent;
  return proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.msh.trait.sensor.v1.TemperatureTrait;
      reader.readMessage(value,proto.msh.trait.sensor.v1.TemperatureTrait.deserializeBinaryFromReader);
      msg.setTrait(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.common.v1.CaptureMode} */ (reader.readEnum());
      msg.setCaptureMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTrait();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.msh.trait.sensor.v1.TemperatureTrait.serializeBinaryToWriter
    );
  }
  f = message.getCaptureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} returns this
*/
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} returns this
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TemperatureTrait trait = 2;
 * @return {?proto.msh.trait.sensor.v1.TemperatureTrait}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.getTrait = function() {
  return /** @type{?proto.msh.trait.sensor.v1.TemperatureTrait} */ (
    jspb.Message.getWrapperField(this, proto.msh.trait.sensor.v1.TemperatureTrait, 2));
};


/**
 * @param {?proto.msh.trait.sensor.v1.TemperatureTrait|undefined} value
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} returns this
*/
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.setTrait = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} returns this
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.clearTrait = function() {
  return this.setTrait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.hasTrait = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.CaptureMode capture_mode = 3;
 * @return {!proto.msh.common.v1.CaptureMode}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.getCaptureMode = function() {
  return /** @type {!proto.msh.common.v1.CaptureMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.common.v1.CaptureMode} value
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} returns this
 */
proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent.prototype.setCaptureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.FloatValue temperature = 1;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.getTemperature = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 1));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait} returns this
*/
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.sensor.v1.TemperatureTrait} returns this
 */
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.sensor.v1.TemperatureTrait.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.msh.trait.sensor.v1);
