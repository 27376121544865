// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.

// Global application store definition
// This module contains all of the actions and commits used in the application.
// Stored them in a central area to make it clearer
import gRPCService from "@/services/grpc.service";
import urlsafeService from "@/services/urlsafe.service";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import deviceProtoDef from "@/grpc/msh/service/v1/device_manager_pb";
import {
  FLOOR_RESET_REQUEST,
  FLOOR_FORCE_UPDATE,
  FLOOR_GET_DEVICES_REQUEST,
  FLOOR_GET_DEVICE_REQUEST,
  FLOOR_RESET_SUCCESS,
  FLOOR_GET_DEVICES_SUCCESS,
  FLOOR_GET_DEVICE_SUCCESS,
  PUSHER_ONLINE_OFFLINE_EVENT,
  PUSHER_CAST_VOTE_PRESENCE,
  DEVICE_UPDATE_ONLINE_STATUS,
  DEVICE_TRIGGER_RECORDING_REQUEST,
  DEVICE_STOP_RECORDING_REQUEST,
  DEVICE_CANCEL_RECORDING_REQUEST,
  FLOOR_FETCH_STRUCTURE_REQUEST,
  FLOOR_STRUCTURE_FETCH_SUCCESS,
  PUSHER_UPDATE_STRUCTURE,
  PUSHER_CREATE_STRUCTURE,
  PUSHER_ADD_ALERT,
  PUSHER_ALERT_ACKNOWLEDGE,
  PUSHER_RECORDING_CHANGED,
  DEVICE_UNPROVISION_DEVICE_REQUEST,
  DEVICE_PROVISION_DEVICE_REQUEST,
  DEVICE_GET_BY_PRODUCT_NAME_REQUEST_PROMISE,
} from "@/store/constants";
import {
  AUTH_LOGOUTCONFIRMED,
  DEVICE_TRIGGER_RECORDING_SUCCEEDED,
  DEVICE_TRIGGER_RECORDING_ERROR,
  DEVICE_STOP_RECORDING_ERROR,
  DEVICE_STOP_RECORDING_SUCCEEDED,
  DEVICE_CANCEL_RECORDING_ERROR,
  DEVICE_CANCEL_RECORDING_SUCCEEDED,
  DEVICE_UNPROVISION_DEVICE_ERROR,
  DEVICE_UNPROVISION_DEVICE_SUCCEEDED,
  DEVICE_PROVISION_DEVICE_ERROR,
  DEVICE_PROVISION_DEVICE_SUCCEEDED,
} from "@/events";
import { bus } from "@/main.js";
import store from "@/store";
import Vue from "vue";

export const registerFloorsCallbacks = () => {
  bus.$on(AUTH_LOGOUTCONFIRMED, () => {
    store.dispatch(FLOOR_RESET_REQUEST);
  });
};

const state = {
  floor_appartments: [],
  floor_devices: [],
  // floors: []
};

let buildingIDInUse = "";

const actions = {
  //
  // FLOOR_FORCE_UPDATE
  //
  [FLOOR_FORCE_UPDATE](context, buildingId) {
    // Makes sure the devices will be updated
    buildingIDInUse = "";
    context.dispatch(FLOOR_GET_DEVICES_REQUEST, buildingId);
  },
  //
  // FLOOR_GET_DEVICES_REQUEST
  //
  [FLOOR_GET_DEVICES_REQUEST](context, buildingId) {
    // pusher will take care of updating the data
    // So if this is the same building, let's not reload the data
    if (buildingIDInUse == buildingId) {
      return;
    }
    context.commit(FLOOR_RESET_SUCCESS);
    const request = new structureProtoDef.GetFloorsRequest([buildingId, true]);
    gRPCService
      .building()
      .getFloors(request, {})
      .then((floorsResponse) => {
        context.commit(
          FLOOR_GET_DEVICES_SUCCESS,
          floorsResponse.getFloorsList()
        );
        // do magic
        //connect.commit(FLOOR_GET_DEVICES_SUCCESS);
      });
    buildingIDInUse = buildingId;
  },
  //
  // FLOOR_RESET_REQUEST
  //
  [FLOOR_RESET_REQUEST](context) {
    context.commit(FLOOR_RESET_SUCCESS);
  },
  //
  // FLOOR_GET_DEVICE_REQUEST
  //
  [FLOOR_GET_DEVICE_REQUEST](context, deviceId) {
    const deviceRequest = new deviceProtoDef.GetDeviceByIDRequest([deviceId]);
    // deviceRequest.setDeviceId(deviceId);
    gRPCService
      .device()
      .getDeviceByID(deviceRequest, {})
      .then((deviceResponse) => {
        context.commit(
          FLOOR_GET_DEVICE_SUCCESS,
          deviceResponse.getDevice().toObject()
        );
      });
  },
  //
  // PUSHER_ONLINE_OFFLINE_EVENT
  //
  [PUSHER_ONLINE_OFFLINE_EVENT](context, payload) {
    if (payload) {
      if (payload.pubsubMessage.onlineOfflineEvent) {
        let online_value = false;
        if (payload.pubsubMessage.onlineOfflineEvent.trait.isOnline) {
          online_value = true;
        }
        let commit_payload = {
          deviceId: payload.deviceId,
          online_value: online_value,
        };
        context.commit(DEVICE_UPDATE_ONLINE_STATUS, commit_payload);
      }
    }
  },
  //
  // ALL PUSHER UPDATES related to a single update of a structure/appartment
  //
  [PUSHER_CAST_VOTE_PRESENCE](context, payload) {
    if (payload) {
      context.dispatch(FLOOR_FETCH_STRUCTURE_REQUEST, payload.structureId);
    }
  },
  [PUSHER_UPDATE_STRUCTURE](context, payload) {
    if (payload) {
      context.dispatch(
        FLOOR_FETCH_STRUCTURE_REQUEST,
        payload.structure.business.structureId
      );
    }
  },
  [PUSHER_ADD_ALERT](context, payload) {
    if (payload && payload.alertCreated) {
      context.dispatch(
        FLOOR_FETCH_STRUCTURE_REQUEST,
        urlsafeService.generateUrlsafeKey(payload.alertId, ["Alert"])
      );
    }
  },
  [PUSHER_ALERT_ACKNOWLEDGE](context, payload) {
    if (payload) {
      // payload contains alertId and alertAckId
      context.dispatch(
        FLOOR_FETCH_STRUCTURE_REQUEST,
        urlsafeService.generateUrlsafeKey(payload.alertId, ["Alert"])
      );
    }
  },
  //
  // Create structure
  //
  [PUSHER_CREATE_STRUCTURE](context, payload) {
    if (payload) {
      let pusher_building_id = urlsafeService.returnIdForType(
        payload.structure.business.structureId,
        "Building"
      );
      let current_building_id = urlsafeService.returnIdForType(
        buildingIDInUse,
        "Building"
      );
      if (pusher_building_id == current_building_id) {
        let buildingToUse = buildingIDInUse;
        buildingIDInUse = "";
        context.dispatch(FLOOR_GET_DEVICES_REQUEST, buildingToUse);
      }
    }
  },
  //
  // PUSHER_RECORDING_CHANGED
  // Called on a device recording changed event (start, finished, errored, ...)
  [PUSHER_RECORDING_CHANGED](context, payload) {
    if (payload) {
      let pusher_building_id = urlsafeService.returnIdForType(
        payload.deviceId,
        "Building"
      );
      let current_building_id = urlsafeService.returnIdForType(
        buildingIDInUse,
        "Building"
      );
      if (pusher_building_id == current_building_id) {
        // Check that the device is in the current building to call the update device action
        context.dispatch(FLOOR_GET_DEVICE_REQUEST, payload.deviceId);
      }
    }
  },
  //
  // Fetch a structure
  //
  [FLOOR_FETCH_STRUCTURE_REQUEST](context, structureId) {
    let request = new structureProtoDef.GetStructuresRequest([
      structureId,
      false,
      ["", 1],
      false,
    ]);
    gRPCService
      .structure()
      .getStructures(request, {})
      .then((structuresResponse) => {
        // Assuming we have one result here
        if (structuresResponse.getStructuresList().length > 0) {
          context.commit(
            FLOOR_STRUCTURE_FETCH_SUCCESS,
            structuresResponse.getStructuresList()[0]
          );
        }
      });
  },
  //
  // DEVICE_TRIGGER_RECORDING_REQUEST
  //
  [DEVICE_TRIGGER_RECORDING_REQUEST](context, recording) {
    if (recording.deviceIds) {
      let recordingRequest =
        new deviceProtoDef.SendStartGroupRecordingDeviceCommandRequest();
      recordingRequest.setAccountId(recording.accountId);
      recordingRequest.setDeviceIdsList(recording.deviceIds);
      recordingRequest.setDuration(recording.duration);
      recordingRequest.setLabel(recording.label);

      gRPCService
        .device()
        .sendStartGroupRecordingDeviceCommand(recordingRequest, {})
        .then((recordingResponse) => {
          bus.$emit(
            DEVICE_TRIGGER_RECORDING_SUCCEEDED,
            recordingResponse.toObject()
          );
        })
        .catch((err) => {
          bus.$emit(DEVICE_TRIGGER_RECORDING_ERROR, err.message);
        });
    } else {
      let recordingRequest =
        new deviceProtoDef.SendStartRecordingDeviceCommandRequest();
      recordingRequest.setDeviceId(recording.deviceId);
      recordingRequest.setDuration(recording.duration);
      recordingRequest.setLabel(recording.label);

      gRPCService
        .device()
        .sendStartRecordingDeviceCommand(recordingRequest, {})
        .then((recordingResponse) => {
          bus.$emit(
            DEVICE_TRIGGER_RECORDING_SUCCEEDED,
            recordingResponse.toObject()
          );
        })
        .catch((err) => {
          bus.$emit(DEVICE_TRIGGER_RECORDING_ERROR, err.message);
        });
    }
  },
  //
  // DEVICE_STOP_RECORDING_REQUEST
  //
  [DEVICE_STOP_RECORDING_REQUEST](context, deviceId) {
    let stopgRPCRequest =
      new deviceProtoDef.SendStopRecordingDeviceCommandRequest();
    stopgRPCRequest.setDeviceId(deviceId);

    gRPCService
      .device()
      .sendStopRecordingDeviceCommand(stopgRPCRequest, {})
      .then((recordingResponse) => {
        bus.$emit(DEVICE_STOP_RECORDING_SUCCEEDED, deviceId);
      })
      .catch((err) => {
        bus.$emit(DEVICE_STOP_RECORDING_ERROR, deviceId);
      });
  },
  [DEVICE_CANCEL_RECORDING_REQUEST](context, deviceId) {
    let stopgRPCRequest =
      new deviceProtoDef.SendCancelRecordingDeviceCommandRequest();
    stopgRPCRequest.setDeviceId(deviceId);

    gRPCService
      .device()
      .sendCancelRecordingDeviceCommand(stopgRPCRequest, {})
      .then((recordingResponse) => {
        bus.$emit(DEVICE_CANCEL_RECORDING_SUCCEEDED, deviceId);
      })
      .catch((err) => {
        bus.$emit(DEVICE_CANCEL_RECORDING_ERROR, deviceId);
      });
  },
  //
  // DEVICE_PROVISION_DEVICE_REQUEST
  //
  [DEVICE_PROVISION_DEVICE_REQUEST](context, provRequest) {
    let provisionRequest = new deviceProtoDef.ProvisionRequest();
    provisionRequest.setSerialNumber(provRequest.serialNumber);
    provisionRequest.setPublicKey(provRequest.publicKey);
    provisionRequest.setLabel(provRequest.deviceLabel);
    provisionRequest.setStructureId(provRequest.structureId);
    gRPCService
      .device()
      .provision(provisionRequest, {})
      .then((provisionResponse) => {
        bus.$emit(DEVICE_PROVISION_DEVICE_SUCCEEDED, {
          serialNumber: provRequest.serialNumber,
          device: provisionResponse.getDevice().toObject(),
        });
        // Make sure we will get the list for device the next we need it.
        store.dispatch(FLOOR_FORCE_UPDATE, buildingIDInUse);
      })
      .catch((err) => {
        bus.$emit(DEVICE_PROVISION_DEVICE_ERROR, {
          serialNumber: provRequest.serialNumber,
          error: err,
        });
      });
  },
  //
  // DEVICE_UNPROVISION_DEVICE_REQUEST
  //
  [DEVICE_UNPROVISION_DEVICE_REQUEST](context, unprovRequest) {
    let unprovisionRequest = new deviceProtoDef.UnProvisionRequest();
    unprovisionRequest.setSerialNumber(unprovRequest.serialNumber);
    unprovisionRequest.setStructureId(unprovRequest.structureId);
    gRPCService
      .device()
      .unProvision(unprovisionRequest, {})
      .then((unprovisionResponse) => {
        bus.$emit(
          DEVICE_UNPROVISION_DEVICE_SUCCEEDED,
          unprovRequest.serialNumber
        );
      })
      .catch(() => {
        bus.$emit(DEVICE_UNPROVISION_DEVICE_ERROR, unprovRequest.serialNumber);
      });
  },
  //
  // DEVICE_GET_BY_PRODUCT_NAME_REQUEST
  //
  [DEVICE_GET_BY_PRODUCT_NAME_REQUEST_PROMISE](context, productName) {
    return new Promise((resolve, reject) => {
      let request = new deviceProtoDef.GetDeviceByProductNameRequest();
      request.setProductName(productName);
      gRPCService
        .device()
        .getDeviceByProductName(request, {})
        .then((getResponse) => {
          if (getResponse.getDevice()) {
            resolve(getResponse.getDevice().toObject());
          } else {
            // reject ?
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  //
  // FLOOR_GET_DEVICES_SUCCESS
  //
  [FLOOR_GET_DEVICES_SUCCESS](state, floorList) {
    state.floor_devices = [];
    for (let floor of floorList) {
      let floor_name = floor.getLabel();
      for (let structure of floor.getStructuresList()) {
        let structure_name = structure.getBusiness().getLabel();
        let numeric_structure_id = urlsafeService.returnIdForType(
          structure.getBusiness().getStructureId(),
          "Structure"
        );
        // let device_statuses = [];
        // Device section of the store
        for (let device of structure.getBusiness().getDevicesList()) {
          let new_device = device.toObject();
          new_device.numeric_device_id = urlsafeService.returnIdForType(
            device.getDeviceId(),
            "Device"
          );
          // device_statuses.push({numeric_device_id: new_device.numeric_device_id, status: new_device.rawStorage.status});
          new_device.floor_name = floor_name;
          new_device.structure_name = structure_name;
          new_device.numeric_structure_id = numeric_structure_id;
          state.floor_devices.push(new_device);
        }
        // Structure section of the store
        let new_structure = structure.getBusiness().toObject();
        new_structure.numeric_structure_id = numeric_structure_id;
        new_structure.floor_name = floor_name;
        // let global_recording_status =  ( device_statuses.some((element) => element == 1) ? 1 : 2 )
        // new_structure.recording_status = {
        //   global: global_recording_status,
        //   device_status: device_statuses
        // }
        state.floor_appartments.push(new_structure);
      }
    }
  },
  //
  // FLOOR_GET_DEVICE_SUCCESS
  //
  [FLOOR_GET_DEVICE_SUCCESS](state, device) {
    // update device in floor_devices
    let pusher_numeric_device_id = urlsafeService.returnIdForType(
      device.deviceId,
      "Device"
    );
    let position = null;
    state.floor_devices.forEach((element, index) => {
      if (element.numeric_device_id === pusher_numeric_device_id) {
        position = index;
      }
    });
    if (position != null) {
      let device_to_be_updated = state.floor_devices[position];
      // Searching the device to update for information only available when working from the floor call
      device.floor_name = device_to_be_updated.floor_name;
      device.numeric_device_id = device_to_be_updated.numeric_device_id;
      device.structure_name = device_to_be_updated.structure_name;
      device.numeric_structure_id = device_to_be_updated.numeric_structure_id;
      Vue.set(state.floor_devices, position, device);
    }
  },
  //
  // FLOOR_RESET_SUCCESS
  //
  [FLOOR_RESET_SUCCESS](state) {
    state.floor_devices = [];
    state.floor_appartments = [];
    buildingIDInUse = "";
  },
  //
  // Update one device online status
  //
  [DEVICE_UPDATE_ONLINE_STATUS](state, payload) {
    let index_to_modify = null;
    state.floor_devices.forEach((element, index) => {
      // Search for the device to update
      // Using deviceId here might be risky but it's the only thing I have (different API could produce different urlsafe id)
      if (element.deviceId == payload.deviceId) {
        index_to_modify = index;
      }
    });
    if (index_to_modify) {
      let new_proto = state.floor_devices[index_to_modify];
      new_proto.isOnline.value = payload.online_value;
      // This update the array and trigger the update in the UI
      Vue.set(state.floor_devices, index_to_modify, new_proto);
    }
  },
  //
  // Update structure in the state list
  //
  [FLOOR_STRUCTURE_FETCH_SUCCESS](state, payload) {
    let index_to_modify = null;
    let new_structure = payload.getBusiness().toObject();
    new_structure.numeric_structure_id = urlsafeService.returnIdForType(
      payload.getBusiness().getStructureId(),
      "Structure"
    );
    if (
      state.floor_appartments.some((appart, index) => {
        if (
          appart.numeric_structure_id === new_structure.numeric_structure_id
        ) {
          index_to_modify = index;
          return true;
        }
        return false;
      })
    ) {
      // This is an update, using the index from the iterate to fetch the floor_name
      // We don't have it with the getStructure call
      new_structure.floor_name =
        state.floor_appartments[index_to_modify].floor_name;
      // This update the array and trigger the update in the UI
      Vue.set(state.floor_appartments, index_to_modify, new_structure);
    }
  },
};

export default {
  state,
  actions,
  mutations,
};
