// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import {
  GRPC_CALLS_INPROGRESS_REQUEST,
  GRPC_CALLS_COMPLETED_REQUEST,
  GRPC_CALL_SET_STATUS_REQUEST,
  GRPC_CALL_SET_STATUS_SUCCESS,
} from "@/store/constants";
import Vue from "vue";

// mutation types
export const ENABLE_TIMER = "enableTimer";
export const DISARM_TIMER = "disableTime";

const state = {
  loading: false,
  calls: {},
};

var loadingTimeout;

const actions = {
  //
  // GRPC_CALLS_INPROGRESS_REQUEST
  //
  [GRPC_CALLS_INPROGRESS_REQUEST](context) {
    context.commit(ENABLE_TIMER);
  },
  //
  //GRPC_CALLS_COMPLETED_REQUEST
  //
  [GRPC_CALLS_COMPLETED_REQUEST](context) {
    context.commit(DISARM_TIMER);
  },
  //
  // GRPC_CALL_SET_STATUS_REQUEST
  //
  [GRPC_CALL_SET_STATUS_REQUEST](context, event) {
    context.commit(GRPC_CALL_SET_STATUS_SUCCESS, event);
  },
};

const mutations = {
  //
  // ENABLE_TIMER
  //
  [ENABLE_TIMER](state) {
    // console.log("pre-loading");
    loadingTimeout = setTimeout(function () {
      state.loading = true;
      // console.log("loading");
    }, 400);
  },
  //
  // DISARM_TIMER
  //
  [DISARM_TIMER](state) {
    clearTimeout(loadingTimeout);
    loadingTimeout = null;
    // console.log("done loading");
    state.loading = false;
  },
  //
  // GRPC_CALL_SET_STATUS_SUCCESS
  //
  [GRPC_CALL_SET_STATUS_SUCCESS](state, event) {
    // Setting the loading with Vue.set to make sure it's reactif for everyone
    Vue.set(state.calls, event.module, event.status);
  },
};

const getters = {
  isLoading: (state) => (grpc_method) => {
    return state.calls[grpc_method] != "status:completed";
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
