/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js')

var msh_common_v1_language_pb = require('../../../msh/common/v1/language_pb.js')

var msh_resource_v1_user_pb = require('../../../msh/resource/v1/user_pb.js')

var msh_resource_v1_account_pb = require('../../../msh/resource/v1/account_pb.js')
const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./account_manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.AccountManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.AccountManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.CreateConsumerAccountRequest,
 *   !proto.msh.service.v1.CreateConsumerAccountResponse>}
 */
const methodDescriptor_AccountManager_CreateConsumerAccount = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/CreateConsumerAccount',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.CreateConsumerAccountRequest,
  proto.msh.service.v1.CreateConsumerAccountResponse,
  /**
   * @param {!proto.msh.service.v1.CreateConsumerAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateConsumerAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.CreateConsumerAccountRequest,
 *   !proto.msh.service.v1.CreateConsumerAccountResponse>}
 */
const methodInfo_AccountManager_CreateConsumerAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.CreateConsumerAccountResponse,
  /**
   * @param {!proto.msh.service.v1.CreateConsumerAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateConsumerAccountResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.CreateConsumerAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.CreateConsumerAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.CreateConsumerAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.createConsumerAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/CreateConsumerAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_CreateConsumerAccount,
      callback);
};


/**
 * @param {!proto.msh.service.v1.CreateConsumerAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.CreateConsumerAccountResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.createConsumerAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/CreateConsumerAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_CreateConsumerAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.CreateBusinessAccountRequest,
 *   !proto.msh.service.v1.CreateBusinessAccountResponse>}
 */
const methodDescriptor_AccountManager_CreateBusinessAccount = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/CreateBusinessAccount',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.CreateBusinessAccountRequest,
  proto.msh.service.v1.CreateBusinessAccountResponse,
  /**
   * @param {!proto.msh.service.v1.CreateBusinessAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.CreateBusinessAccountRequest,
 *   !proto.msh.service.v1.CreateBusinessAccountResponse>}
 */
const methodInfo_AccountManager_CreateBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.CreateBusinessAccountResponse,
  /**
   * @param {!proto.msh.service.v1.CreateBusinessAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.CreateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.CreateBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.CreateBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.createBusinessAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/CreateBusinessAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_CreateBusinessAccount,
      callback);
};


/**
 * @param {!proto.msh.service.v1.CreateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.CreateBusinessAccountResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.createBusinessAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/CreateBusinessAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_CreateBusinessAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddBusinessAccountUserRequest,
 *   !proto.msh.service.v1.AddBusinessAccountUserResponse>}
 */
const methodDescriptor_AccountManager_AddBusinessAccountUser = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/AddBusinessAccountUser',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddBusinessAccountUserRequest,
  proto.msh.service.v1.AddBusinessAccountUserResponse,
  /**
   * @param {!proto.msh.service.v1.AddBusinessAccountUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddBusinessAccountUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddBusinessAccountUserRequest,
 *   !proto.msh.service.v1.AddBusinessAccountUserResponse>}
 */
const methodInfo_AccountManager_AddBusinessAccountUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddBusinessAccountUserResponse,
  /**
   * @param {!proto.msh.service.v1.AddBusinessAccountUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddBusinessAccountUserResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddBusinessAccountUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddBusinessAccountUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddBusinessAccountUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.addBusinessAccountUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/AddBusinessAccountUser',
      request,
      metadata || {},
      methodDescriptor_AccountManager_AddBusinessAccountUser,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddBusinessAccountUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddBusinessAccountUserResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.addBusinessAccountUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/AddBusinessAccountUser',
      request,
      metadata || {},
      methodDescriptor_AccountManager_AddBusinessAccountUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetUserRequest,
 *   !proto.msh.service.v1.GetUserResponse>}
 */
const methodDescriptor_AccountManager_GetUser = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/GetUser',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetUserRequest,
  proto.msh.service.v1.GetUserResponse,
  /**
   * @param {!proto.msh.service.v1.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetUserRequest,
 *   !proto.msh.service.v1.GetUserResponse>}
 */
const methodInfo_AccountManager_GetUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetUserResponse,
  /**
   * @param {!proto.msh.service.v1.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetUser',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetUser,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetUser',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetAccountRequest,
 *   !proto.msh.service.v1.GetAccountResponse>}
 */
const methodDescriptor_AccountManager_GetAccount = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/GetAccount',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetAccountRequest,
  proto.msh.service.v1.GetAccountResponse,
  /**
   * @param {!proto.msh.service.v1.GetAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetAccountRequest,
 *   !proto.msh.service.v1.GetAccountResponse>}
 */
const methodInfo_AccountManager_GetAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetAccountResponse,
  /**
   * @param {!proto.msh.service.v1.GetAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAccountResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.getAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetAccount,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetAccountResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetAccount',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetCurrentAccountsRequest,
 *   !proto.msh.service.v1.GetCurrentAccountsResponse>}
 */
const methodDescriptor_AccountManager_GetCurrentAccounts = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/GetCurrentAccounts',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetCurrentAccountsRequest,
  proto.msh.service.v1.GetCurrentAccountsResponse,
  /**
   * @param {!proto.msh.service.v1.GetCurrentAccountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetCurrentAccountsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetCurrentAccountsRequest,
 *   !proto.msh.service.v1.GetCurrentAccountsResponse>}
 */
const methodInfo_AccountManager_GetCurrentAccounts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetCurrentAccountsResponse,
  /**
   * @param {!proto.msh.service.v1.GetCurrentAccountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetCurrentAccountsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetCurrentAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetCurrentAccountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetCurrentAccountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.getCurrentAccounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetCurrentAccounts',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetCurrentAccounts,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetCurrentAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetCurrentAccountsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.getCurrentAccounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/GetCurrentAccounts',
      request,
      metadata || {},
      methodDescriptor_AccountManager_GetCurrentAccounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SetUserContactTimeRequest,
 *   !proto.msh.service.v1.SetUserContactTimeResponse>}
 */
const methodDescriptor_AccountManager_SetUserContactTime = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AccountManager/SetUserContactTime',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SetUserContactTimeRequest,
  proto.msh.service.v1.SetUserContactTimeResponse,
  /**
   * @param {!proto.msh.service.v1.SetUserContactTimeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SetUserContactTimeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SetUserContactTimeRequest,
 *   !proto.msh.service.v1.SetUserContactTimeResponse>}
 */
const methodInfo_AccountManager_SetUserContactTime = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SetUserContactTimeResponse,
  /**
   * @param {!proto.msh.service.v1.SetUserContactTimeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SetUserContactTimeResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SetUserContactTimeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SetUserContactTimeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SetUserContactTimeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AccountManagerClient.prototype.setUserContactTime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AccountManager/SetUserContactTime',
      request,
      metadata || {},
      methodDescriptor_AccountManager_SetUserContactTime,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SetUserContactTimeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SetUserContactTimeResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AccountManagerPromiseClient.prototype.setUserContactTime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AccountManager/SetUserContactTime',
      request,
      metadata || {},
      methodDescriptor_AccountManager_SetUserContactTime);
};


module.exports = proto.msh.service.v1;

