// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import {
  AUTH_LOGIN_REQUEST,
  AUTH_AUTO_LOGIN_REQUEST,
  AUTH_AUTO_LOGIN_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_VERIFY_REQUEST,
} from "@/store/constants";
import {
  AUTH_LOGINCONFIRMED,
  AUTH_LOGINERROR,
  AUTH_LOGOUTCONFIRMED,
  AUTH_LOGOUTERROR,
  ROUTER_LOGINGCONFIRMED,
} from "@/events";
import JwtService from "@/services/jwt.service";
import { signInWithEmailAndPassword } from "firebase/auth";
import { bus, auth } from "@/main.js";

const state = {
  user: null,
  isAuthenticated: JwtService.isTokenValid(),
};

const actions = {
  [AUTH_LOGIN_REQUEST](context, credentials) {
    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then((userCredential) => {
        // Signed In

        userCredential.user
          .getIdTokenResult()
          .then((result) => {
            JwtService.setEmail(userCredential.user.email);
            JwtService.setUserId(userCredential.user.uid);
            JwtService.saveToken(result);
            context.commit(AUTH_LOGIN_SUCCESS, userCredential.user);
          })
          .catch((error) => {
            // getting token failed
            bus.$emit(AUTH_LOGINERROR, error.message);
          });
      })
      .catch((error) => {
        // Signin failed
        bus.$emit(AUTH_LOGINERROR, error.message);
      });
  },

  [AUTH_AUTO_LOGIN_REQUEST](context, user) {
    // This happens when the page is reloaded
    // and Firebase Auth is already set.
    // Is this case, isAuthenticated will be true but state.user will be null
    const currentUser = auth.currentUser;
    if (JwtService.isTokenValid() && !state.user) {
      context.commit(AUTH_AUTO_LOGIN_SUCCESS, user);
    }
  },
  //
  // AUTH_VERIFY_REQUEST
  //
  [AUTH_VERIFY_REQUEST](context) {
    if (!JwtService.isTokenValid()) {
      // when not valid we check the firebase current user to refresh the token
      if (auth.currentUser) {
        auth.currentUser
          .getIdTokenResult(true)
          .then((idTokenResult) => {
            JwtService.setEmail(auth.currentUser.email);
            JwtService.setUserId(auth.currentUser.uid);
            JwtService.saveToken(idTokenResult);
          })
          .catch((error) => {
            context.dispatch(AUTH_LOGOUT_REQUEST, error.message);
          });
      } else {
        // this means we are not logged
        context.commit(AUTH_LOGOUT_REQUEST);
      }
    }
  },

  [AUTH_LOGOUT_REQUEST](context, error) {
    JwtService.destroyToken();
    context.commit(AUTH_LOGOUT_SUCCESS);
    if (error) {
      bus.$emit(AUTH_LOGOUTERROR, error);
    }
  },
};

const mutations = {
  //
  // This login happens through the login page
  [AUTH_LOGIN_SUCCESS](state, user) {
    state.user = user;
    state.isAuthenticated = true;
    bus.$emit(AUTH_LOGINCONFIRMED, user);
  },

  //
  // This loggin happens from reloading the page.
  // So we will automatically log in (without going through the login page)
  [AUTH_AUTO_LOGIN_SUCCESS](state, user) {
    state.user = user;
    state.isAuthenticated = true;
    bus.$emit(AUTH_LOGINCONFIRMED, user);
  },

  [AUTH_LOGOUT_SUCCESS](state) {
    var user = state.user;
    state.user = null;
    state.isAuthenticated = false;
    bus.$emit(AUTH_LOGOUTCONFIRMED, user);
  },
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getEmail(state) {
    if (state.user) {
      return state.user.email;
    }
    return "";
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
