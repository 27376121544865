// source: msh/service/v1/index_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var msh_resource_v1_raw_storage_index_pb = require('../../../msh/resource/v1/raw_storage_index_pb.js');
goog.object.extend(proto, msh_resource_v1_raw_storage_index_pb);
var msh_resource_v1_recording_pb = require('../../../msh/resource/v1/recording_pb.js');
goog.object.extend(proto, msh_resource_v1_recording_pb);
goog.exportSymbol('proto.msh.service.v1.AddRemoveRecordingStatusRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddRemoveRecordingStatusResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.ConfirmRecordingRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.ConfirmRecordingResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateIndexRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateIndexResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.DeleteIndexRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.DeleteIndexResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.FloorIndex', null, global);
goog.exportSymbol('proto.msh.service.v1.FloorRecordings', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRecordingRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRecordingResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.Index', null, global);
goog.exportSymbol('proto.msh.service.v1.ListIndexRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.ListIndexResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.ListRecordingsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.ListRecordingsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.RequestDownloadUrlRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.RequestDownloadUrlResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.StructureIndex', null, global);
goog.exportSymbol('proto.msh.service.v1.StructureRecordings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ConfirmRecordingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ConfirmRecordingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ConfirmRecordingRequest.displayName = 'proto.msh.service.v1.ConfirmRecordingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ConfirmRecordingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ConfirmRecordingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ConfirmRecordingResponse.displayName = 'proto.msh.service.v1.ConfirmRecordingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddRemoveRecordingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddRemoveRecordingStatusRequest.displayName = 'proto.msh.service.v1.AddRemoveRecordingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddRemoveRecordingStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddRemoveRecordingStatusResponse.displayName = 'proto.msh.service.v1.AddRemoveRecordingStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateIndexRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.CreateIndexRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateIndexRequest.displayName = 'proto.msh.service.v1.CreateIndexRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateIndexResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.CreateIndexResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateIndexResponse.displayName = 'proto.msh.service.v1.CreateIndexResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.DeleteIndexRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.DeleteIndexRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.DeleteIndexRequest.displayName = 'proto.msh.service.v1.DeleteIndexRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.DeleteIndexResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.DeleteIndexResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.DeleteIndexResponse.displayName = 'proto.msh.service.v1.DeleteIndexResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.RequestDownloadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.RequestDownloadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.RequestDownloadUrlRequest.displayName = 'proto.msh.service.v1.RequestDownloadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.RequestDownloadUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.RequestDownloadUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.RequestDownloadUrlResponse.displayName = 'proto.msh.service.v1.RequestDownloadUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRecordingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRecordingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRecordingRequest.displayName = 'proto.msh.service.v1.GetRecordingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRecordingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRecordingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRecordingResponse.displayName = 'proto.msh.service.v1.GetRecordingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListRecordingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ListRecordingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListRecordingsRequest.displayName = 'proto.msh.service.v1.ListRecordingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListRecordingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.ListRecordingsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.ListRecordingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListRecordingsResponse.displayName = 'proto.msh.service.v1.ListRecordingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.FloorRecordings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.FloorRecordings.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.FloorRecordings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.FloorRecordings.displayName = 'proto.msh.service.v1.FloorRecordings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.StructureRecordings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.StructureRecordings.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.StructureRecordings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.StructureRecordings.displayName = 'proto.msh.service.v1.StructureRecordings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListIndexRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.ListIndexRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListIndexRequest.displayName = 'proto.msh.service.v1.ListIndexRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.ListIndexResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.ListIndexResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.ListIndexResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.ListIndexResponse.displayName = 'proto.msh.service.v1.ListIndexResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.FloorIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.FloorIndex.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.FloorIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.FloorIndex.displayName = 'proto.msh.service.v1.FloorIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.StructureIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.StructureIndex.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.StructureIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.StructureIndex.displayName = 'proto.msh.service.v1.StructureIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.Index = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.Index, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.Index.displayName = 'proto.msh.service.v1.Index';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ConfirmRecordingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ConfirmRecordingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ConfirmRecordingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ConfirmRecordingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recording: (f = msg.getRecording()) && msh_resource_v1_recording_pb.Recording.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ConfirmRecordingRequest}
 */
proto.msh.service.v1.ConfirmRecordingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ConfirmRecordingRequest;
  return proto.msh.service.v1.ConfirmRecordingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ConfirmRecordingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ConfirmRecordingRequest}
 */
proto.msh.service.v1.ConfirmRecordingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_recording_pb.Recording;
      reader.readMessage(value,msh_resource_v1_recording_pb.Recording.deserializeBinaryFromReader);
      msg.setRecording(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ConfirmRecordingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ConfirmRecordingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ConfirmRecordingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ConfirmRecordingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecording();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_recording_pb.Recording.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Recording recording = 1;
 * @return {?proto.msh.resource.v1.Recording}
 */
proto.msh.service.v1.ConfirmRecordingRequest.prototype.getRecording = function() {
  return /** @type{?proto.msh.resource.v1.Recording} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_recording_pb.Recording, 1));
};


/**
 * @param {?proto.msh.resource.v1.Recording|undefined} value
 * @return {!proto.msh.service.v1.ConfirmRecordingRequest} returns this
*/
proto.msh.service.v1.ConfirmRecordingRequest.prototype.setRecording = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.ConfirmRecordingRequest} returns this
 */
proto.msh.service.v1.ConfirmRecordingRequest.prototype.clearRecording = function() {
  return this.setRecording(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.ConfirmRecordingRequest.prototype.hasRecording = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ConfirmRecordingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ConfirmRecordingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ConfirmRecordingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ConfirmRecordingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recording: (f = msg.getRecording()) && msh_resource_v1_recording_pb.Recording.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ConfirmRecordingResponse}
 */
proto.msh.service.v1.ConfirmRecordingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ConfirmRecordingResponse;
  return proto.msh.service.v1.ConfirmRecordingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ConfirmRecordingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ConfirmRecordingResponse}
 */
proto.msh.service.v1.ConfirmRecordingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_recording_pb.Recording;
      reader.readMessage(value,msh_resource_v1_recording_pb.Recording.deserializeBinaryFromReader);
      msg.setRecording(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ConfirmRecordingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ConfirmRecordingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ConfirmRecordingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ConfirmRecordingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecording();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_recording_pb.Recording.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Recording recording = 1;
 * @return {?proto.msh.resource.v1.Recording}
 */
proto.msh.service.v1.ConfirmRecordingResponse.prototype.getRecording = function() {
  return /** @type{?proto.msh.resource.v1.Recording} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_recording_pb.Recording, 1));
};


/**
 * @param {?proto.msh.resource.v1.Recording|undefined} value
 * @return {!proto.msh.service.v1.ConfirmRecordingResponse} returns this
*/
proto.msh.service.v1.ConfirmRecordingResponse.prototype.setRecording = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.ConfirmRecordingResponse} returns this
 */
proto.msh.service.v1.ConfirmRecordingResponse.prototype.clearRecording = function() {
  return this.setRecording(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.ConfirmRecordingResponse.prototype.hasRecording = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddRemoveRecordingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddRemoveRecordingStatusRequest}
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddRemoveRecordingStatusRequest;
  return proto.msh.service.v1.AddRemoveRecordingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddRemoveRecordingStatusRequest}
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddRemoveRecordingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string index_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.prototype.getIndexId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} returns this
 */
proto.msh.service.v1.AddRemoveRecordingStatusRequest.prototype.setIndexId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddRemoveRecordingStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddRemoveRecordingStatusResponse}
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddRemoveRecordingStatusResponse;
  return proto.msh.service.v1.AddRemoveRecordingStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddRemoveRecordingStatusResponse}
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddRemoveRecordingStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRemoveRecordingStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateIndexRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateIndexRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateIndexRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateIndexRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: (f = msg.getIndex()) && msh_resource_v1_raw_storage_index_pb.RawStorageIndex.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateIndexRequest}
 */
proto.msh.service.v1.CreateIndexRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateIndexRequest;
  return proto.msh.service.v1.CreateIndexRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateIndexRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateIndexRequest}
 */
proto.msh.service.v1.CreateIndexRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_raw_storage_index_pb.RawStorageIndex;
      reader.readMessage(value,msh_resource_v1_raw_storage_index_pb.RawStorageIndex.deserializeBinaryFromReader);
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateIndexRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateIndexRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateIndexRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateIndexRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_raw_storage_index_pb.RawStorageIndex.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.RawStorageIndex index = 1;
 * @return {?proto.msh.resource.v1.RawStorageIndex}
 */
proto.msh.service.v1.CreateIndexRequest.prototype.getIndex = function() {
  return /** @type{?proto.msh.resource.v1.RawStorageIndex} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_raw_storage_index_pb.RawStorageIndex, 1));
};


/**
 * @param {?proto.msh.resource.v1.RawStorageIndex|undefined} value
 * @return {!proto.msh.service.v1.CreateIndexRequest} returns this
*/
proto.msh.service.v1.CreateIndexRequest.prototype.setIndex = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.CreateIndexRequest} returns this
 */
proto.msh.service.v1.CreateIndexRequest.prototype.clearIndex = function() {
  return this.setIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.CreateIndexRequest.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateIndexResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateIndexResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateIndexResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateIndexResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: (f = msg.getIndex()) && msh_resource_v1_raw_storage_index_pb.RawStorageIndex.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateIndexResponse}
 */
proto.msh.service.v1.CreateIndexResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateIndexResponse;
  return proto.msh.service.v1.CreateIndexResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateIndexResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateIndexResponse}
 */
proto.msh.service.v1.CreateIndexResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_raw_storage_index_pb.RawStorageIndex;
      reader.readMessage(value,msh_resource_v1_raw_storage_index_pb.RawStorageIndex.deserializeBinaryFromReader);
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateIndexResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateIndexResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateIndexResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateIndexResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_raw_storage_index_pb.RawStorageIndex.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.RawStorageIndex index = 1;
 * @return {?proto.msh.resource.v1.RawStorageIndex}
 */
proto.msh.service.v1.CreateIndexResponse.prototype.getIndex = function() {
  return /** @type{?proto.msh.resource.v1.RawStorageIndex} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_raw_storage_index_pb.RawStorageIndex, 1));
};


/**
 * @param {?proto.msh.resource.v1.RawStorageIndex|undefined} value
 * @return {!proto.msh.service.v1.CreateIndexResponse} returns this
*/
proto.msh.service.v1.CreateIndexResponse.prototype.setIndex = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.CreateIndexResponse} returns this
 */
proto.msh.service.v1.CreateIndexResponse.prototype.clearIndex = function() {
  return this.setIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.CreateIndexResponse.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.DeleteIndexRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.DeleteIndexRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.DeleteIndexRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteIndexRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.DeleteIndexRequest}
 */
proto.msh.service.v1.DeleteIndexRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.DeleteIndexRequest;
  return proto.msh.service.v1.DeleteIndexRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.DeleteIndexRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.DeleteIndexRequest}
 */
proto.msh.service.v1.DeleteIndexRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.DeleteIndexRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.DeleteIndexRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.DeleteIndexRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteIndexRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string index_id = 1;
 * @return {string}
 */
proto.msh.service.v1.DeleteIndexRequest.prototype.getIndexId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.DeleteIndexRequest} returns this
 */
proto.msh.service.v1.DeleteIndexRequest.prototype.setIndexId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.DeleteIndexResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.DeleteIndexResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.DeleteIndexResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteIndexResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.DeleteIndexResponse}
 */
proto.msh.service.v1.DeleteIndexResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.DeleteIndexResponse;
  return proto.msh.service.v1.DeleteIndexResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.DeleteIndexResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.DeleteIndexResponse}
 */
proto.msh.service.v1.DeleteIndexResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.DeleteIndexResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.DeleteIndexResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.DeleteIndexResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.DeleteIndexResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.RequestDownloadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.RequestDownloadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.RequestDownloadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.RequestDownloadUrlRequest}
 */
proto.msh.service.v1.RequestDownloadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.RequestDownloadUrlRequest;
  return proto.msh.service.v1.RequestDownloadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.RequestDownloadUrlRequest}
 */
proto.msh.service.v1.RequestDownloadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.RequestDownloadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.RequestDownloadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.RequestDownloadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.msh.service.v1.RequestDownloadUrlRequest.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.RequestDownloadUrlRequest} returns this
 */
proto.msh.service.v1.RequestDownloadUrlRequest.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.RequestDownloadUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.RequestDownloadUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.RequestDownloadUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.RequestDownloadUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.RequestDownloadUrlResponse}
 */
proto.msh.service.v1.RequestDownloadUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.RequestDownloadUrlResponse;
  return proto.msh.service.v1.RequestDownloadUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.RequestDownloadUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.RequestDownloadUrlResponse}
 */
proto.msh.service.v1.RequestDownloadUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.RequestDownloadUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.RequestDownloadUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.RequestDownloadUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.RequestDownloadUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.msh.service.v1.RequestDownloadUrlResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.RequestDownloadUrlResponse} returns this
 */
proto.msh.service.v1.RequestDownloadUrlResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRecordingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRecordingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRecordingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRecordingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    withHistory: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRecordingRequest}
 */
proto.msh.service.v1.GetRecordingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRecordingRequest;
  return proto.msh.service.v1.GetRecordingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRecordingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRecordingRequest}
 */
proto.msh.service.v1.GetRecordingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRecordingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRecordingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRecordingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRecordingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWithHistory();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetRecordingRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetRecordingRequest} returns this
 */
proto.msh.service.v1.GetRecordingRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool with_history = 2;
 * @return {boolean}
 */
proto.msh.service.v1.GetRecordingRequest.prototype.getWithHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.GetRecordingRequest} returns this
 */
proto.msh.service.v1.GetRecordingRequest.prototype.setWithHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRecordingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRecordingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRecordingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRecordingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recording: (f = msg.getRecording()) && msh_resource_v1_recording_pb.Recording.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRecordingResponse}
 */
proto.msh.service.v1.GetRecordingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRecordingResponse;
  return proto.msh.service.v1.GetRecordingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRecordingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRecordingResponse}
 */
proto.msh.service.v1.GetRecordingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_recording_pb.Recording;
      reader.readMessage(value,msh_resource_v1_recording_pb.Recording.deserializeBinaryFromReader);
      msg.setRecording(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRecordingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRecordingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRecordingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRecordingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecording();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_recording_pb.Recording.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Recording recording = 1;
 * @return {?proto.msh.resource.v1.Recording}
 */
proto.msh.service.v1.GetRecordingResponse.prototype.getRecording = function() {
  return /** @type{?proto.msh.resource.v1.Recording} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_recording_pb.Recording, 1));
};


/**
 * @param {?proto.msh.resource.v1.Recording|undefined} value
 * @return {!proto.msh.service.v1.GetRecordingResponse} returns this
*/
proto.msh.service.v1.GetRecordingResponse.prototype.setRecording = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetRecordingResponse} returns this
 */
proto.msh.service.v1.GetRecordingResponse.prototype.clearRecording = function() {
  return this.setRecording(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetRecordingResponse.prototype.hasRecording = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListRecordingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListRecordingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListRecordingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRecordingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ancestorId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListRecordingsRequest}
 */
proto.msh.service.v1.ListRecordingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListRecordingsRequest;
  return proto.msh.service.v1.ListRecordingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListRecordingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListRecordingsRequest}
 */
proto.msh.service.v1.ListRecordingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAncestorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListRecordingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListRecordingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListRecordingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRecordingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAncestorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ancestor_id = 1;
 * @return {string}
 */
proto.msh.service.v1.ListRecordingsRequest.prototype.getAncestorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ListRecordingsRequest} returns this
 */
proto.msh.service.v1.ListRecordingsRequest.prototype.setAncestorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.ListRecordingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListRecordingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListRecordingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListRecordingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRecordingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    proto.msh.service.v1.FloorRecordings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListRecordingsResponse}
 */
proto.msh.service.v1.ListRecordingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListRecordingsResponse;
  return proto.msh.service.v1.ListRecordingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListRecordingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListRecordingsResponse}
 */
proto.msh.service.v1.ListRecordingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.FloorRecordings;
      reader.readMessage(value,proto.msh.service.v1.FloorRecordings.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListRecordingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListRecordingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListRecordingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListRecordingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.FloorRecordings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FloorRecordings floors = 1;
 * @return {!Array<!proto.msh.service.v1.FloorRecordings>}
 */
proto.msh.service.v1.ListRecordingsResponse.prototype.getFloorsList = function() {
  return /** @type{!Array<!proto.msh.service.v1.FloorRecordings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.FloorRecordings, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.FloorRecordings>} value
 * @return {!proto.msh.service.v1.ListRecordingsResponse} returns this
*/
proto.msh.service.v1.ListRecordingsResponse.prototype.setFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.FloorRecordings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.FloorRecordings}
 */
proto.msh.service.v1.ListRecordingsResponse.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.FloorRecordings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.ListRecordingsResponse} returns this
 */
proto.msh.service.v1.ListRecordingsResponse.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.FloorRecordings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.FloorRecordings.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.FloorRecordings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.FloorRecordings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.FloorRecordings.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    structuresList: jspb.Message.toObjectList(msg.getStructuresList(),
    proto.msh.service.v1.StructureRecordings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.FloorRecordings}
 */
proto.msh.service.v1.FloorRecordings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.FloorRecordings;
  return proto.msh.service.v1.FloorRecordings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.FloorRecordings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.FloorRecordings}
 */
proto.msh.service.v1.FloorRecordings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumericId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.msh.service.v1.StructureRecordings;
      reader.readMessage(value,proto.msh.service.v1.StructureRecordings.deserializeBinaryFromReader);
      msg.addStructures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.FloorRecordings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.FloorRecordings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.FloorRecordings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.FloorRecordings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStructuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.msh.service.v1.StructureRecordings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string numeric_id = 1;
 * @return {string}
 */
proto.msh.service.v1.FloorRecordings.prototype.getNumericId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.FloorRecordings} returns this
 */
proto.msh.service.v1.FloorRecordings.prototype.setNumericId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.FloorRecordings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.FloorRecordings} returns this
 */
proto.msh.service.v1.FloorRecordings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StructureRecordings structures = 3;
 * @return {!Array<!proto.msh.service.v1.StructureRecordings>}
 */
proto.msh.service.v1.FloorRecordings.prototype.getStructuresList = function() {
  return /** @type{!Array<!proto.msh.service.v1.StructureRecordings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.StructureRecordings, 3));
};


/**
 * @param {!Array<!proto.msh.service.v1.StructureRecordings>} value
 * @return {!proto.msh.service.v1.FloorRecordings} returns this
*/
proto.msh.service.v1.FloorRecordings.prototype.setStructuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.service.v1.StructureRecordings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.StructureRecordings}
 */
proto.msh.service.v1.FloorRecordings.prototype.addStructures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.service.v1.StructureRecordings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.FloorRecordings} returns this
 */
proto.msh.service.v1.FloorRecordings.prototype.clearStructuresList = function() {
  return this.setStructuresList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.StructureRecordings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.StructureRecordings.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.StructureRecordings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.StructureRecordings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.StructureRecordings.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recordingsList: jspb.Message.toObjectList(msg.getRecordingsList(),
    msh_resource_v1_recording_pb.Recording.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.StructureRecordings}
 */
proto.msh.service.v1.StructureRecordings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.StructureRecordings;
  return proto.msh.service.v1.StructureRecordings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.StructureRecordings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.StructureRecordings}
 */
proto.msh.service.v1.StructureRecordings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumericId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new msh_resource_v1_recording_pb.Recording;
      reader.readMessage(value,msh_resource_v1_recording_pb.Recording.deserializeBinaryFromReader);
      msg.addRecordings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.StructureRecordings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.StructureRecordings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.StructureRecordings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.StructureRecordings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecordingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      msh_resource_v1_recording_pb.Recording.serializeBinaryToWriter
    );
  }
};


/**
 * optional string numeric_id = 1;
 * @return {string}
 */
proto.msh.service.v1.StructureRecordings.prototype.getNumericId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.StructureRecordings} returns this
 */
proto.msh.service.v1.StructureRecordings.prototype.setNumericId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.StructureRecordings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.StructureRecordings} returns this
 */
proto.msh.service.v1.StructureRecordings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated msh.resource.v1.Recording recordings = 3;
 * @return {!Array<!proto.msh.resource.v1.Recording>}
 */
proto.msh.service.v1.StructureRecordings.prototype.getRecordingsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Recording>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_recording_pb.Recording, 3));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Recording>} value
 * @return {!proto.msh.service.v1.StructureRecordings} returns this
*/
proto.msh.service.v1.StructureRecordings.prototype.setRecordingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.resource.v1.Recording=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Recording}
 */
proto.msh.service.v1.StructureRecordings.prototype.addRecordings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.resource.v1.Recording, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.StructureRecordings} returns this
 */
proto.msh.service.v1.StructureRecordings.prototype.clearRecordingsList = function() {
  return this.setRecordingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListIndexRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListIndexRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListIndexRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListIndexRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ancestorId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListIndexRequest}
 */
proto.msh.service.v1.ListIndexRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListIndexRequest;
  return proto.msh.service.v1.ListIndexRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListIndexRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListIndexRequest}
 */
proto.msh.service.v1.ListIndexRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAncestorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListIndexRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListIndexRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListIndexRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListIndexRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAncestorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ancestor_id = 1;
 * @return {string}
 */
proto.msh.service.v1.ListIndexRequest.prototype.getAncestorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.ListIndexRequest} returns this
 */
proto.msh.service.v1.ListIndexRequest.prototype.setAncestorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.ListIndexResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.ListIndexResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.ListIndexResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.ListIndexResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListIndexResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    proto.msh.service.v1.FloorIndex.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.ListIndexResponse}
 */
proto.msh.service.v1.ListIndexResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.ListIndexResponse;
  return proto.msh.service.v1.ListIndexResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.ListIndexResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.ListIndexResponse}
 */
proto.msh.service.v1.ListIndexResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.FloorIndex;
      reader.readMessage(value,proto.msh.service.v1.FloorIndex.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.ListIndexResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.ListIndexResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.ListIndexResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.ListIndexResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.FloorIndex.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FloorIndex floors = 1;
 * @return {!Array<!proto.msh.service.v1.FloorIndex>}
 */
proto.msh.service.v1.ListIndexResponse.prototype.getFloorsList = function() {
  return /** @type{!Array<!proto.msh.service.v1.FloorIndex>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.FloorIndex, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.FloorIndex>} value
 * @return {!proto.msh.service.v1.ListIndexResponse} returns this
*/
proto.msh.service.v1.ListIndexResponse.prototype.setFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.FloorIndex=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.FloorIndex}
 */
proto.msh.service.v1.ListIndexResponse.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.FloorIndex, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.ListIndexResponse} returns this
 */
proto.msh.service.v1.ListIndexResponse.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.FloorIndex.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.FloorIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.FloorIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.FloorIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.FloorIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    structuresList: jspb.Message.toObjectList(msg.getStructuresList(),
    proto.msh.service.v1.StructureIndex.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.FloorIndex}
 */
proto.msh.service.v1.FloorIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.FloorIndex;
  return proto.msh.service.v1.FloorIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.FloorIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.FloorIndex}
 */
proto.msh.service.v1.FloorIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumericId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.msh.service.v1.StructureIndex;
      reader.readMessage(value,proto.msh.service.v1.StructureIndex.deserializeBinaryFromReader);
      msg.addStructures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.FloorIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.FloorIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.FloorIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.FloorIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStructuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.msh.service.v1.StructureIndex.serializeBinaryToWriter
    );
  }
};


/**
 * optional string numeric_id = 1;
 * @return {string}
 */
proto.msh.service.v1.FloorIndex.prototype.getNumericId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.FloorIndex} returns this
 */
proto.msh.service.v1.FloorIndex.prototype.setNumericId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.FloorIndex.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.FloorIndex} returns this
 */
proto.msh.service.v1.FloorIndex.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StructureIndex structures = 3;
 * @return {!Array<!proto.msh.service.v1.StructureIndex>}
 */
proto.msh.service.v1.FloorIndex.prototype.getStructuresList = function() {
  return /** @type{!Array<!proto.msh.service.v1.StructureIndex>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.StructureIndex, 3));
};


/**
 * @param {!Array<!proto.msh.service.v1.StructureIndex>} value
 * @return {!proto.msh.service.v1.FloorIndex} returns this
*/
proto.msh.service.v1.FloorIndex.prototype.setStructuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.service.v1.StructureIndex=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.StructureIndex}
 */
proto.msh.service.v1.FloorIndex.prototype.addStructures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.service.v1.StructureIndex, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.FloorIndex} returns this
 */
proto.msh.service.v1.FloorIndex.prototype.clearStructuresList = function() {
  return this.setStructuresList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.StructureIndex.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.StructureIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.StructureIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.StructureIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.StructureIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    indexesList: jspb.Message.toObjectList(msg.getIndexesList(),
    proto.msh.service.v1.Index.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.StructureIndex}
 */
proto.msh.service.v1.StructureIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.StructureIndex;
  return proto.msh.service.v1.StructureIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.StructureIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.StructureIndex}
 */
proto.msh.service.v1.StructureIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumericId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.msh.service.v1.Index;
      reader.readMessage(value,proto.msh.service.v1.Index.deserializeBinaryFromReader);
      msg.addIndexes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.StructureIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.StructureIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.StructureIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.StructureIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndexesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.msh.service.v1.Index.serializeBinaryToWriter
    );
  }
};


/**
 * optional string numeric_id = 1;
 * @return {string}
 */
proto.msh.service.v1.StructureIndex.prototype.getNumericId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.StructureIndex} returns this
 */
proto.msh.service.v1.StructureIndex.prototype.setNumericId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.StructureIndex.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.StructureIndex} returns this
 */
proto.msh.service.v1.StructureIndex.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Index indexes = 3;
 * @return {!Array<!proto.msh.service.v1.Index>}
 */
proto.msh.service.v1.StructureIndex.prototype.getIndexesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.Index>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.Index, 3));
};


/**
 * @param {!Array<!proto.msh.service.v1.Index>} value
 * @return {!proto.msh.service.v1.StructureIndex} returns this
*/
proto.msh.service.v1.StructureIndex.prototype.setIndexesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.service.v1.Index=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.Index}
 */
proto.msh.service.v1.StructureIndex.prototype.addIndexes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.service.v1.Index, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.StructureIndex} returns this
 */
proto.msh.service.v1.StructureIndex.prototype.clearIndexesList = function() {
  return this.setIndexesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.Index.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.Index.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.Index} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.Index.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.Index}
 */
proto.msh.service.v1.Index.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.Index;
  return proto.msh.service.v1.Index.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.Index} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.Index}
 */
proto.msh.service.v1.Index.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.Index.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.Index.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.Index} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.Index.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.msh.service.v1.Index.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.Index.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.msh.service.v1.Index.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 size = 4;
 * @return {number}
 */
proto.msh.service.v1.Index.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string user_email = 5;
 * @return {string}
 */
proto.msh.service.v1.Index.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.Index.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.Index} returns this
*/
proto.msh.service.v1.Index.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Index} returns this
 */
proto.msh.service.v1.Index.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Index.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.msh.service.v1);
