// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import store from "@/store";
import JwtService from "@/services/jwt.service";
import { firebasePerf } from "@/main";

import utilProtoDef from "@/grpc/msh/service/v1/utils_pb";
import utilStub from "@/grpc/msh/service/v1/utils_grpc_web_pb";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import structureStub from "@/grpc/msh/service/v1/structure_manager_grpc_web_pb";
import buildingStub from "@/grpc/msh/service/v1/structure_manager_grpc_web_pb";
import accountProtoDef from "@/grpc/msh/service/v1/account_manager_pb";
import accountStub from "@/grpc/msh/service/v1/account_manager_grpc_web_pb";
import deviceProtoDef from "@/grpc/msh/service/v1/device_manager_pb";
import deviceStub from "@/grpc/msh/service/v1/device_manager_grpc_web_pb";
import indexProtoDef from "@/grpc/msh/service/v1/index_manager_pb";
import indexStub from "@/grpc/msh/service/v1/index_manager_grpc_web_pb";
import alertProtoDef from "@/grpc/msh/service/v1/alert_manager_pb";
import alertStub from "@/grpc/msh/service/v1/alert_manager_grpc_web_pb";
import eventProtoDef from "@/grpc/msh/resource/v1/event_pb";
import { trace } from "firebase/performance";

import commonDef from "@/grpc/msh/common/v1/common_pb";

import {
  GRPC_CALLS_INPROGRESS_REQUEST,
  GRPC_CALLS_COMPLETED_REQUEST,
  GRPC_CALL_SET_STATUS_REQUEST,
  AUTH_VERIFY_REQUEST,
  STATUS_LOADING,
  STATUS_COMPLETED,
} from "@/store/constants";

// see https://grpc.io/blog/grpc-web-interceptor/
const AuthInterceptor = function () {};
const PerformanceInterceptor = function () {};
const LoadingInterceptor = function () {};

/**
 * Auth interceptor to be used in both unary and stream operation. Adding
 * an 'Authorization' metadata that will be sent along any gRPC call.
 */
AuthInterceptor.prototype.intercept = async function (request, invoker) {
  // this will still go through , event if the AUTH_VERIFY FAILS.
  await store.dispatch(AUTH_VERIFY_REQUEST);
  const metadata = request.getMetadata();
  const accessToken = JwtService.getToken();
  metadata.Authorization = `Bearer ${accessToken}`;

  return invoker(request);
};

PerformanceInterceptor.prototype.intercept = async function (request, invoker) {
  const t = trace(firebasePerf, request.getMethodDescriptor().name);
  t.start();
  return invoker(request).finally(() => {
    t.stop();
  });
};

LoadingInterceptor.prototype.rpc_counter = 0;
LoadingInterceptor.prototype.intercept = async function (request, invoker) {
  this.rpc_counter++;

  store.dispatch(GRPC_CALL_SET_STATUS_REQUEST, {
    module: request.getMethodDescriptor().name,
    status: STATUS_LOADING,
  });
  if (this.rpc_counter === 1) {
    store.dispatch(GRPC_CALLS_INPROGRESS_REQUEST);
  }

  return invoker(request).finally(() => {
    store.dispatch(GRPC_CALL_SET_STATUS_REQUEST, {
      module: request.getMethodDescriptor().name,
      status: STATUS_COMPLETED,
    });
    this.rpc_counter--;

    if (this.rpc_counter === 0) {
      store.dispatch(GRPC_CALLS_COMPLETED_REQUEST);
    }
    //
  });
};

// Client to be set on init.
let utilClient;
let structureClient;
let accountClient;
let buildingClient;
let deviceClient;
let alertClient;
let indexClient;

// See .env section on README to understand properties configuration
const apiUrl = process.env.VUE_APP_GRPC_API_URL;

const interceptors = [
  new PerformanceInterceptor(),
  new AuthInterceptor(),
  new LoadingInterceptor(),
];

const gRPCService = {
  init() {
    // To have remote config here we need to have a wait somewhere for the config to be ready before getting any clients
    utilClient = new utilStub.UtilsPromiseClient(apiUrl, null, {
      unaryInterceptors: interceptors,
    });
    structureClient = new structureStub.StructureManagerPromiseClient(
      apiUrl,
      null,
      { unaryInterceptors: interceptors }
    );
    accountClient = new accountStub.AccountManagerPromiseClient(apiUrl, null, {
      unaryInterceptors: interceptors,
    });
    buildingClient = new buildingStub.BuildingManagerPromiseClient(
      apiUrl,
      null,
      { unaryInterceptors: interceptors }
    );
    deviceClient = new deviceStub.DeviceManagerPromiseClient(apiUrl, null, {
      unaryInterceptors: interceptors,
    });
    alertClient = new alertStub.AlertManagerPromiseClient(apiUrl, null, {
      unaryInterceptors: interceptors,
    });
    indexClient = new indexStub.IndexManagerPromiseClient(apiUrl, null, {
      unaryInterceptors: interceptors,
    });
  },

  // Debug tools to remove
  account() {
    return accountClient;
  },
  building() {
    return buildingClient;
  },
  structure() {
    return structureClient;
  },
  alert() {
    return alertClient;
  },
  device() {
    return deviceClient;
  },
  index() {
    return indexClient;
  },
};

export default gRPCService;
