// source: msh/service/v1/structure_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_address_pb = require('../../../msh/common/v1/address_pb.js');
goog.object.extend(proto, msh_common_v1_address_pb);
var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_resource_v1_alert_pb = require('../../../msh/resource/v1/alert_pb.js');
goog.object.extend(proto, msh_resource_v1_alert_pb);
var msh_resource_v1_event_pb = require('../../../msh/resource/v1/event_pb.js');
goog.object.extend(proto, msh_resource_v1_event_pb);
var msh_resource_v1_structure_pb = require('../../../msh/resource/v1/structure_pb.js');
goog.object.extend(proto, msh_resource_v1_structure_pb);
var msh_trait_activities_v1_activity_level_pb = require('../../../msh/trait/activities/v1/activity_level_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_activity_level_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.msh.service.v1.AddContactRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddContactResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.AddFloorRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddFloorResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.AddRoomRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddRoomResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateBuildingRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateBuildingResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateStructureRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.CreateStructureResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLBuildingEventsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLBuildingEventsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLStructureEventsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetADLStructureEventsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetActivityLevelHistoryRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetActivityLevelHistoryResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAirQualitiesRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAirQualitiesResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetBuildingAlertsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetBuildingAlertsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetBuildingsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetBuildingsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceOnlineCountRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetDeviceOnlineCountResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetFloorsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetFloorsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetHRHistoryRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetHRHistoryResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetHRHistoryResponse.HRHistory', null, global);
goog.exportSymbol('proto.msh.service.v1.GetHumiditiesRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetHumiditiesResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetLightIntensitiesRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetLightIntensitiesResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetPressuresRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetPressuresResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRespirationHistoryRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRespirationHistoryResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRoomsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetRoomsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetStructuresRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetStructuresResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetTemperaturesRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetTemperaturesResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendCancelRecordingStructureCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendCancelRecordingStructureCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartRecordingStructureCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStartRecordingStructureCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStopRecordingStructureCommandRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.SendStopRecordingStructureCommandResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.UpdateStructureRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.UpdateStructureResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateBuildingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.CreateBuildingRequest.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.CreateBuildingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateBuildingRequest.displayName = 'proto.msh.service.v1.CreateBuildingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateBuildingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.CreateBuildingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateBuildingResponse.displayName = 'proto.msh.service.v1.CreateBuildingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetBuildingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetBuildingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetBuildingsRequest.displayName = 'proto.msh.service.v1.GetBuildingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetBuildingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetBuildingsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetBuildingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetBuildingsResponse.displayName = 'proto.msh.service.v1.GetBuildingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddFloorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddFloorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddFloorRequest.displayName = 'proto.msh.service.v1.AddFloorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddFloorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddFloorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddFloorResponse.displayName = 'proto.msh.service.v1.AddFloorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetFloorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetFloorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetFloorsRequest.displayName = 'proto.msh.service.v1.GetFloorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetFloorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetFloorsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetFloorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetFloorsResponse.displayName = 'proto.msh.service.v1.GetFloorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddRoomRequest.displayName = 'proto.msh.service.v1.AddRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddRoomResponse.displayName = 'proto.msh.service.v1.AddRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateStructureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.CreateStructureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateStructureRequest.displayName = 'proto.msh.service.v1.CreateStructureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.CreateStructureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.CreateStructureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.CreateStructureResponse.displayName = 'proto.msh.service.v1.CreateStructureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetStructuresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetStructuresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetStructuresRequest.displayName = 'proto.msh.service.v1.GetStructuresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UpdateStructureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UpdateStructureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UpdateStructureRequest.displayName = 'proto.msh.service.v1.UpdateStructureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.UpdateStructureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.UpdateStructureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.UpdateStructureResponse.displayName = 'proto.msh.service.v1.UpdateStructureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetStructuresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetStructuresResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetStructuresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetStructuresResponse.displayName = 'proto.msh.service.v1.GetStructuresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRoomsRequest.displayName = 'proto.msh.service.v1.GetRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetRoomsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRoomsResponse.displayName = 'proto.msh.service.v1.GetRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddContactRequest.displayName = 'proto.msh.service.v1.AddContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddContactResponse.displayName = 'proto.msh.service.v1.AddContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLStructureEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetADLStructureEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLStructureEventsRequest.displayName = 'proto.msh.service.v1.GetADLStructureEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLStructureEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetADLStructureEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetADLStructureEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLStructureEventsResponse.displayName = 'proto.msh.service.v1.GetADLStructureEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceOnlineCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceOnlineCountRequest.displayName = 'proto.msh.service.v1.GetDeviceOnlineCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetDeviceOnlineCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetDeviceOnlineCountResponse.displayName = 'proto.msh.service.v1.GetDeviceOnlineCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetBuildingAlertsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetBuildingAlertsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetBuildingAlertsRequest.displayName = 'proto.msh.service.v1.GetBuildingAlertsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetBuildingAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetBuildingAlertsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetBuildingAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetBuildingAlertsResponse.displayName = 'proto.msh.service.v1.GetBuildingAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStartRecordingStructureCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartRecordingStructureCommandRequest.displayName = 'proto.msh.service.v1.SendStartRecordingStructureCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.SendStartRecordingStructureCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.SendStartRecordingStructureCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse.displayName = 'proto.msh.service.v1.SendStartRecordingStructureCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendStopRecordingStructureCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStopRecordingStructureCommandRequest.displayName = 'proto.msh.service.v1.SendStopRecordingStructureCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.SendStopRecordingStructureCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.SendStopRecordingStructureCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse.displayName = 'proto.msh.service.v1.SendStopRecordingStructureCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.SendCancelRecordingStructureCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.displayName = 'proto.msh.service.v1.SendCancelRecordingStructureCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.SendCancelRecordingStructureCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.displayName = 'proto.msh.service.v1.SendCancelRecordingStructureCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetActivityLevelHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetActivityLevelHistoryRequest.displayName = 'proto.msh.service.v1.GetActivityLevelHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetActivityLevelHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetActivityLevelHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetActivityLevelHistoryResponse.displayName = 'proto.msh.service.v1.GetActivityLevelHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.displayName = 'proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLBuildingEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetADLBuildingEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLBuildingEventsRequest.displayName = 'proto.msh.service.v1.GetADLBuildingEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLBuildingEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetADLBuildingEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetADLBuildingEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLBuildingEventsResponse.displayName = 'proto.msh.service.v1.GetADLBuildingEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.displayName = 'proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRespirationHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRespirationHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRespirationHistoryRequest.displayName = 'proto.msh.service.v1.GetRespirationHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRespirationHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetRespirationHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetRespirationHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRespirationHistoryResponse.displayName = 'proto.msh.service.v1.GetRespirationHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.displayName = 'proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetHRHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetHRHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetHRHistoryRequest.displayName = 'proto.msh.service.v1.GetHRHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetHRHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetHRHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetHRHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetHRHistoryResponse.displayName = 'proto.msh.service.v1.GetHRHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetHRHistoryResponse.HRHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetHRHistoryResponse.HRHistory.displayName = 'proto.msh.service.v1.GetHRHistoryResponse.HRHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetTemperaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetTemperaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetTemperaturesRequest.displayName = 'proto.msh.service.v1.GetTemperaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetTemperaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetTemperaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetTemperaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetTemperaturesResponse.displayName = 'proto.msh.service.v1.GetTemperaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetHumiditiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetHumiditiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetHumiditiesRequest.displayName = 'proto.msh.service.v1.GetHumiditiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetHumiditiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetHumiditiesResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetHumiditiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetHumiditiesResponse.displayName = 'proto.msh.service.v1.GetHumiditiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetPressuresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetPressuresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetPressuresRequest.displayName = 'proto.msh.service.v1.GetPressuresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetPressuresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetPressuresResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetPressuresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetPressuresResponse.displayName = 'proto.msh.service.v1.GetPressuresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAirQualitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAirQualitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAirQualitiesRequest.displayName = 'proto.msh.service.v1.GetAirQualitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAirQualitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetAirQualitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetAirQualitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAirQualitiesResponse.displayName = 'proto.msh.service.v1.GetAirQualitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetLightIntensitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetLightIntensitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetLightIntensitiesRequest.displayName = 'proto.msh.service.v1.GetLightIntensitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetLightIntensitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetLightIntensitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetLightIntensitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetLightIntensitiesResponse.displayName = 'proto.msh.service.v1.GetLightIntensitiesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.CreateBuildingRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateBuildingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateBuildingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateBuildingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    managersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    address: (f = msg.getAddress()) && msh_common_v1_address_pb.AddressFormat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateBuildingRequest}
 */
proto.msh.service.v1.CreateBuildingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateBuildingRequest;
  return proto.msh.service.v1.CreateBuildingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateBuildingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateBuildingRequest}
 */
proto.msh.service.v1.CreateBuildingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addManagers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    case 5:
      var value = new msh_common_v1_address_pb.AddressFormat;
      reader.readMessage(value,msh_common_v1_address_pb.AddressFormat.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateBuildingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateBuildingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateBuildingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManagersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_address_pb.AddressFormat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string managers = 3;
 * @return {!Array<string>}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.getManagersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.setManagersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.addManagers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.clearManagersList = function() {
  return this.setManagersList([]);
};


/**
 * repeated string users = 4;
 * @return {!Array<string>}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional msh.common.v1.AddressFormat address = 5;
 * @return {?proto.msh.common.v1.AddressFormat}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.getAddress = function() {
  return /** @type{?proto.msh.common.v1.AddressFormat} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_address_pb.AddressFormat, 5));
};


/**
 * @param {?proto.msh.common.v1.AddressFormat|undefined} value
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
*/
proto.msh.service.v1.CreateBuildingRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.CreateBuildingRequest} returns this
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.CreateBuildingRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateBuildingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateBuildingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateBuildingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateBuildingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    building: (f = msg.getBuilding()) && msh_resource_v1_structure_pb.Building.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateBuildingResponse}
 */
proto.msh.service.v1.CreateBuildingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateBuildingResponse;
  return proto.msh.service.v1.CreateBuildingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateBuildingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateBuildingResponse}
 */
proto.msh.service.v1.CreateBuildingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Building;
      reader.readMessage(value,msh_resource_v1_structure_pb.Building.deserializeBinaryFromReader);
      msg.setBuilding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateBuildingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateBuildingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateBuildingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateBuildingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuilding();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Building.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Building building = 1;
 * @return {?proto.msh.resource.v1.Building}
 */
proto.msh.service.v1.CreateBuildingResponse.prototype.getBuilding = function() {
  return /** @type{?proto.msh.resource.v1.Building} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Building, 1));
};


/**
 * @param {?proto.msh.resource.v1.Building|undefined} value
 * @return {!proto.msh.service.v1.CreateBuildingResponse} returns this
*/
proto.msh.service.v1.CreateBuildingResponse.prototype.setBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.CreateBuildingResponse} returns this
 */
proto.msh.service.v1.CreateBuildingResponse.prototype.clearBuilding = function() {
  return this.setBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.CreateBuildingResponse.prototype.hasBuilding = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetBuildingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetBuildingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recursive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetBuildingsRequest}
 */
proto.msh.service.v1.GetBuildingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetBuildingsRequest;
  return proto.msh.service.v1.GetBuildingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetBuildingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetBuildingsRequest}
 */
proto.msh.service.v1.GetBuildingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecursive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetBuildingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetBuildingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecursive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetBuildingsRequest} returns this
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool recursive = 2;
 * @return {boolean}
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.getRecursive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.GetBuildingsRequest} returns this
 */
proto.msh.service.v1.GetBuildingsRequest.prototype.setRecursive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetBuildingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetBuildingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetBuildingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetBuildingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingsList: jspb.Message.toObjectList(msg.getBuildingsList(),
    msh_resource_v1_structure_pb.Building.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetBuildingsResponse}
 */
proto.msh.service.v1.GetBuildingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetBuildingsResponse;
  return proto.msh.service.v1.GetBuildingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetBuildingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetBuildingsResponse}
 */
proto.msh.service.v1.GetBuildingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Building;
      reader.readMessage(value,msh_resource_v1_structure_pb.Building.deserializeBinaryFromReader);
      msg.addBuildings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetBuildingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetBuildingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetBuildingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Building.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Building buildings = 1;
 * @return {!Array<!proto.msh.resource.v1.Building>}
 */
proto.msh.service.v1.GetBuildingsResponse.prototype.getBuildingsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Building>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_structure_pb.Building, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Building>} value
 * @return {!proto.msh.service.v1.GetBuildingsResponse} returns this
*/
proto.msh.service.v1.GetBuildingsResponse.prototype.setBuildingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Building=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Building}
 */
proto.msh.service.v1.GetBuildingsResponse.prototype.addBuildings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Building, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetBuildingsResponse} returns this
 */
proto.msh.service.v1.GetBuildingsResponse.prototype.clearBuildingsList = function() {
  return this.setBuildingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddFloorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddFloorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddFloorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddFloorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddFloorRequest}
 */
proto.msh.service.v1.AddFloorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddFloorRequest;
  return proto.msh.service.v1.AddFloorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddFloorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddFloorRequest}
 */
proto.msh.service.v1.AddFloorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddFloorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddFloorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddFloorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddFloorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string building_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AddFloorRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddFloorRequest} returns this
 */
proto.msh.service.v1.AddFloorRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.msh.service.v1.AddFloorRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddFloorRequest} returns this
 */
proto.msh.service.v1.AddFloorRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddFloorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddFloorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddFloorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddFloorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    floor: (f = msg.getFloor()) && msh_resource_v1_structure_pb.Floor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddFloorResponse}
 */
proto.msh.service.v1.AddFloorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddFloorResponse;
  return proto.msh.service.v1.AddFloorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddFloorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddFloorResponse}
 */
proto.msh.service.v1.AddFloorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Floor;
      reader.readMessage(value,msh_resource_v1_structure_pb.Floor.deserializeBinaryFromReader);
      msg.setFloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddFloorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddFloorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddFloorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddFloorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Floor.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Floor floor = 1;
 * @return {?proto.msh.resource.v1.Floor}
 */
proto.msh.service.v1.AddFloorResponse.prototype.getFloor = function() {
  return /** @type{?proto.msh.resource.v1.Floor} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Floor, 1));
};


/**
 * @param {?proto.msh.resource.v1.Floor|undefined} value
 * @return {!proto.msh.service.v1.AddFloorResponse} returns this
*/
proto.msh.service.v1.AddFloorResponse.prototype.setFloor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.AddFloorResponse} returns this
 */
proto.msh.service.v1.AddFloorResponse.prototype.clearFloor = function() {
  return this.setFloor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.AddFloorResponse.prototype.hasFloor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetFloorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetFloorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetFloorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetFloorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recursive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetFloorsRequest}
 */
proto.msh.service.v1.GetFloorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetFloorsRequest;
  return proto.msh.service.v1.GetFloorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetFloorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetFloorsRequest}
 */
proto.msh.service.v1.GetFloorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecursive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetFloorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetFloorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetFloorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetFloorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecursive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetFloorsRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetFloorsRequest} returns this
 */
proto.msh.service.v1.GetFloorsRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool recursive = 2;
 * @return {boolean}
 */
proto.msh.service.v1.GetFloorsRequest.prototype.getRecursive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.GetFloorsRequest} returns this
 */
proto.msh.service.v1.GetFloorsRequest.prototype.setRecursive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetFloorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetFloorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetFloorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetFloorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetFloorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    msh_resource_v1_structure_pb.Floor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetFloorsResponse}
 */
proto.msh.service.v1.GetFloorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetFloorsResponse;
  return proto.msh.service.v1.GetFloorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetFloorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetFloorsResponse}
 */
proto.msh.service.v1.GetFloorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Floor;
      reader.readMessage(value,msh_resource_v1_structure_pb.Floor.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetFloorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetFloorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetFloorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetFloorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Floor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Floor floors = 1;
 * @return {!Array<!proto.msh.resource.v1.Floor>}
 */
proto.msh.service.v1.GetFloorsResponse.prototype.getFloorsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Floor>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_structure_pb.Floor, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Floor>} value
 * @return {!proto.msh.service.v1.GetFloorsResponse} returns this
*/
proto.msh.service.v1.GetFloorsResponse.prototype.setFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Floor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Floor}
 */
proto.msh.service.v1.GetFloorsResponse.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Floor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetFloorsResponse} returns this
 */
proto.msh.service.v1.GetFloorsResponse.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddRoomRequest}
 */
proto.msh.service.v1.AddRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddRoomRequest;
  return proto.msh.service.v1.AddRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddRoomRequest}
 */
proto.msh.service.v1.AddRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AddRoomRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRoomRequest} returns this
 */
proto.msh.service.v1.AddRoomRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.AddRoomRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddRoomRequest} returns this
 */
proto.msh.service.v1.AddRoomRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    room: (f = msg.getRoom()) && msh_resource_v1_structure_pb.Room.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddRoomResponse}
 */
proto.msh.service.v1.AddRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddRoomResponse;
  return proto.msh.service.v1.AddRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddRoomResponse}
 */
proto.msh.service.v1.AddRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Room;
      reader.readMessage(value,msh_resource_v1_structure_pb.Room.deserializeBinaryFromReader);
      msg.setRoom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Room.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Room room = 1;
 * @return {?proto.msh.resource.v1.Room}
 */
proto.msh.service.v1.AddRoomResponse.prototype.getRoom = function() {
  return /** @type{?proto.msh.resource.v1.Room} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Room, 1));
};


/**
 * @param {?proto.msh.resource.v1.Room|undefined} value
 * @return {!proto.msh.service.v1.AddRoomResponse} returns this
*/
proto.msh.service.v1.AddRoomResponse.prototype.setRoom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.AddRoomResponse} returns this
 */
proto.msh.service.v1.AddRoomResponse.prototype.clearRoom = function() {
  return this.setRoom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.AddRoomResponse.prototype.hasRoom = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateStructureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateStructureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateStructureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateStructureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateStructureRequest}
 */
proto.msh.service.v1.CreateStructureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateStructureRequest;
  return proto.msh.service.v1.CreateStructureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateStructureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateStructureRequest}
 */
proto.msh.service.v1.CreateStructureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateStructureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateStructureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateStructureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateStructureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string parent_id = 1;
 * @return {string}
 */
proto.msh.service.v1.CreateStructureRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.CreateStructureRequest} returns this
 */
proto.msh.service.v1.CreateStructureRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.CreateStructureRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.CreateStructureRequest} returns this
 */
proto.msh.service.v1.CreateStructureRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.CreateStructureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.CreateStructureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.CreateStructureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateStructureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    structure: (f = msg.getStructure()) && msh_resource_v1_structure_pb.Structure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.CreateStructureResponse}
 */
proto.msh.service.v1.CreateStructureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.CreateStructureResponse;
  return proto.msh.service.v1.CreateStructureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.CreateStructureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.CreateStructureResponse}
 */
proto.msh.service.v1.CreateStructureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Structure;
      reader.readMessage(value,msh_resource_v1_structure_pb.Structure.deserializeBinaryFromReader);
      msg.setStructure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.CreateStructureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.CreateStructureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.CreateStructureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.CreateStructureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructure();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Structure.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Structure structure = 1;
 * @return {?proto.msh.resource.v1.Structure}
 */
proto.msh.service.v1.CreateStructureResponse.prototype.getStructure = function() {
  return /** @type{?proto.msh.resource.v1.Structure} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Structure, 1));
};


/**
 * @param {?proto.msh.resource.v1.Structure|undefined} value
 * @return {!proto.msh.service.v1.CreateStructureResponse} returns this
*/
proto.msh.service.v1.CreateStructureResponse.prototype.setStructure = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.CreateStructureResponse} returns this
 */
proto.msh.service.v1.CreateStructureResponse.prototype.clearStructure = function() {
  return this.setStructure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.CreateStructureResponse.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetStructuresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetStructuresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetStructuresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recursive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f),
    withActiveAlerts: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetStructuresRequest}
 */
proto.msh.service.v1.GetStructuresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetStructuresRequest;
  return proto.msh.service.v1.GetStructuresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetStructuresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetStructuresRequest}
 */
proto.msh.service.v1.GetStructuresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecursive(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithActiveAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetStructuresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetStructuresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetStructuresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecursive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
  f = message.getWithActiveAlerts();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string parent_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetStructuresRequest} returns this
 */
proto.msh.service.v1.GetStructuresRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool recursive = 2;
 * @return {boolean}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.getRecursive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.GetStructuresRequest} returns this
 */
proto.msh.service.v1.GetStructuresRequest.prototype.setRecursive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetStructuresRequest} returns this
*/
proto.msh.service.v1.GetStructuresRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetStructuresRequest} returns this
 */
proto.msh.service.v1.GetStructuresRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool with_active_alerts = 4;
 * @return {boolean}
 */
proto.msh.service.v1.GetStructuresRequest.prototype.getWithActiveAlerts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.GetStructuresRequest} returns this
 */
proto.msh.service.v1.GetStructuresRequest.prototype.setWithActiveAlerts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UpdateStructureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UpdateStructureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateStructureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UpdateStructureRequest}
 */
proto.msh.service.v1.UpdateStructureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UpdateStructureRequest;
  return proto.msh.service.v1.UpdateStructureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UpdateStructureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UpdateStructureRequest}
 */
proto.msh.service.v1.UpdateStructureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UpdateStructureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UpdateStructureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateStructureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.UpdateStructureRequest} returns this
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.UpdateStructureRequest} returns this
 */
proto.msh.service.v1.UpdateStructureRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.UpdateStructureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.UpdateStructureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.UpdateStructureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateStructureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    structure: (f = msg.getStructure()) && msh_resource_v1_structure_pb.Structure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.UpdateStructureResponse}
 */
proto.msh.service.v1.UpdateStructureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.UpdateStructureResponse;
  return proto.msh.service.v1.UpdateStructureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.UpdateStructureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.UpdateStructureResponse}
 */
proto.msh.service.v1.UpdateStructureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Structure;
      reader.readMessage(value,msh_resource_v1_structure_pb.Structure.deserializeBinaryFromReader);
      msg.setStructure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.UpdateStructureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.UpdateStructureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.UpdateStructureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.UpdateStructureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructure();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Structure.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Structure structure = 1;
 * @return {?proto.msh.resource.v1.Structure}
 */
proto.msh.service.v1.UpdateStructureResponse.prototype.getStructure = function() {
  return /** @type{?proto.msh.resource.v1.Structure} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Structure, 1));
};


/**
 * @param {?proto.msh.resource.v1.Structure|undefined} value
 * @return {!proto.msh.service.v1.UpdateStructureResponse} returns this
*/
proto.msh.service.v1.UpdateStructureResponse.prototype.setStructure = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.UpdateStructureResponse} returns this
 */
proto.msh.service.v1.UpdateStructureResponse.prototype.clearStructure = function() {
  return this.setStructure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.UpdateStructureResponse.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetStructuresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetStructuresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetStructuresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetStructuresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    structuresList: jspb.Message.toObjectList(msg.getStructuresList(),
    msh_resource_v1_structure_pb.Structure.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetStructuresResponse}
 */
proto.msh.service.v1.GetStructuresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetStructuresResponse;
  return proto.msh.service.v1.GetStructuresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetStructuresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetStructuresResponse}
 */
proto.msh.service.v1.GetStructuresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Structure;
      reader.readMessage(value,msh_resource_v1_structure_pb.Structure.deserializeBinaryFromReader);
      msg.addStructures(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetStructuresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetStructuresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetStructuresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Structure.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Structure structures = 1;
 * @return {!Array<!proto.msh.resource.v1.Structure>}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.getStructuresList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Structure>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_structure_pb.Structure, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Structure>} value
 * @return {!proto.msh.service.v1.GetStructuresResponse} returns this
*/
proto.msh.service.v1.GetStructuresResponse.prototype.setStructuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Structure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Structure}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.addStructures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Structure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetStructuresResponse} returns this
 */
proto.msh.service.v1.GetStructuresResponse.prototype.clearStructuresList = function() {
  return this.setStructuresList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetStructuresResponse} returns this
*/
proto.msh.service.v1.GetStructuresResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetStructuresResponse} returns this
 */
proto.msh.service.v1.GetStructuresResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetStructuresResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRoomsRequest}
 */
proto.msh.service.v1.GetRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRoomsRequest;
  return proto.msh.service.v1.GetRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRoomsRequest}
 */
proto.msh.service.v1.GetRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetRoomsRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetRoomsRequest} returns this
 */
proto.msh.service.v1.GetRoomsRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetRoomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    msh_resource_v1_structure_pb.Room.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRoomsResponse}
 */
proto.msh.service.v1.GetRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRoomsResponse;
  return proto.msh.service.v1.GetRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRoomsResponse}
 */
proto.msh.service.v1.GetRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_structure_pb.Room;
      reader.readMessage(value,msh_resource_v1_structure_pb.Room.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_structure_pb.Room.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Room rooms = 1;
 * @return {!Array<!proto.msh.resource.v1.Room>}
 */
proto.msh.service.v1.GetRoomsResponse.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Room>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_structure_pb.Room, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Room>} value
 * @return {!proto.msh.service.v1.GetRoomsResponse} returns this
*/
proto.msh.service.v1.GetRoomsResponse.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Room=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Room}
 */
proto.msh.service.v1.GetRoomsResponse.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Room, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetRoomsResponse} returns this
 */
proto.msh.service.v1.GetRoomsResponse.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    buildingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddContactRequest}
 */
proto.msh.service.v1.AddContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddContactRequest;
  return proto.msh.service.v1.AddContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddContactRequest}
 */
proto.msh.service.v1.AddContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AddContactRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddContactRequest} returns this
 */
proto.msh.service.v1.AddContactRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string building_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AddContactRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddContactRequest} returns this
 */
proto.msh.service.v1.AddContactRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 level = 3;
 * @return {number}
 */
proto.msh.service.v1.AddContactRequest.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.AddContactRequest} returns this
 */
proto.msh.service.v1.AddContactRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddContactResponse}
 */
proto.msh.service.v1.AddContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddContactResponse;
  return proto.msh.service.v1.AddContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddContactResponse}
 */
proto.msh.service.v1.AddContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLStructureEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLStructureEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLStructureEventsRequest;
  return proto.msh.service.v1.GetADLStructureEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLStructureEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLStructureEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest} returns this
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest} returns this
*/
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest} returns this
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest} returns this
*/
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLStructureEventsRequest} returns this
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLStructureEventsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetADLStructureEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLStructureEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLStructureEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLStructureEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLStructureEventsResponse;
  return proto.msh.service.v1.GetADLStructureEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLStructureEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLStructureEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLStructureEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLStructureEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse} returns this
*/
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse} returns this
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse} returns this
*/
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLStructureEventsResponse} returns this
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLStructureEventsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceOnlineCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountRequest}
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceOnlineCountRequest;
  return proto.msh.service.v1.GetDeviceOnlineCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountRequest}
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceOnlineCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountRequest} returns this
 */
proto.msh.service.v1.GetDeviceOnlineCountRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetDeviceOnlineCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalDevices: jspb.Message.getFieldWithDefault(msg, 1, 0),
    onlineDevices: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountResponse}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetDeviceOnlineCountResponse;
  return proto.msh.service.v1.GetDeviceOnlineCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountResponse}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalDevices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOnlineDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetDeviceOnlineCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalDevices();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOnlineDevices();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 total_devices = 1;
 * @return {number}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.getTotalDevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountResponse} returns this
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.setTotalDevices = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 online_devices = 2;
 * @return {number}
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.getOnlineDevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetDeviceOnlineCountResponse} returns this
 */
proto.msh.service.v1.GetDeviceOnlineCountResponse.prototype.setOnlineDevices = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetBuildingAlertsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingAlertsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_alert_pb.AlertFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetBuildingAlertsRequest;
  return proto.msh.service.v1.GetBuildingAlertsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 2:
      var value = new msh_resource_v1_alert_pb.AlertFilter;
      reader.readMessage(value,msh_resource_v1_alert_pb.AlertFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetBuildingAlertsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingAlertsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_alert_pb.AlertFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest} returns this
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.AlertFilter filter = 2;
 * @return {?proto.msh.resource.v1.AlertFilter}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.AlertFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_alert_pb.AlertFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.AlertFilter|undefined} value
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest} returns this
*/
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest} returns this
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest} returns this
*/
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetBuildingAlertsRequest} returns this
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetBuildingAlertsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetBuildingAlertsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetBuildingAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetBuildingAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    msh_resource_v1_alert_pb.Alert.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetBuildingAlertsResponse;
  return proto.msh.service.v1.GetBuildingAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetBuildingAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetBuildingAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetBuildingAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetBuildingAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Alert alerts = 1;
 * @return {!Array<!proto.msh.resource.v1.Alert>}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_alert_pb.Alert, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Alert>} value
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse} returns this
*/
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Alert}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse} returns this
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse} returns this
*/
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetBuildingAlertsResponse} returns this
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetBuildingAlertsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartRecordingStructureCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userTag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartRecordingStructureCommandRequest;
  return proto.msh.service.v1.SendStartRecordingStructureCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartRecordingStructureCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 duration = 2;
 * @return {number}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_tag = 4;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.getUserTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandRequest.prototype.setUserTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStartRecordingStructureCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    msh_common_v1_common_pb.IOTCoreCommandStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStartRecordingStructureCommandResponse;
  return proto.msh.service.v1.SendStartRecordingStructureCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated msh.common.v1.IOTCoreCommandStatus status = 2;
 * @return {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 2));
};


/**
 * @param {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} value
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} returns this
*/
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.msh.common.v1.IOTCoreCommandStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.msh.common.v1.IOTCoreCommandStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.SendStartRecordingStructureCommandResponse} returns this
 */
proto.msh.service.v1.SendStartRecordingStructureCommandResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStopRecordingStructureCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStopRecordingStructureCommandRequest;
  return proto.msh.service.v1.SendStopRecordingStructureCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStopRecordingStructureCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendStopRecordingStructureCommandRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendStopRecordingStructureCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    msh_common_v1_common_pb.IOTCoreCommandStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendStopRecordingStructureCommandResponse;
  return proto.msh.service.v1.SendStopRecordingStructureCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.common.v1.IOTCoreCommandStatus status = 1;
 * @return {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 1));
};


/**
 * @param {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} value
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse} returns this
*/
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.common.v1.IOTCoreCommandStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.common.v1.IOTCoreCommandStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.SendStopRecordingStructureCommandResponse} returns this
 */
proto.msh.service.v1.SendStopRecordingStructureCommandResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendCancelRecordingStructureCommandRequest;
  return proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} returns this
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    msh_common_v1_common_pb.IOTCoreCommandStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.SendCancelRecordingStructureCommandResponse;
  return proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_common_v1_common_pb.IOTCoreCommandStatus;
      reader.readMessage(value,msh_common_v1_common_pb.IOTCoreCommandStatus.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_common_v1_common_pb.IOTCoreCommandStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.common.v1.IOTCoreCommandStatus status = 1;
 * @return {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_common_v1_common_pb.IOTCoreCommandStatus, 1));
};


/**
 * @param {!Array<!proto.msh.common.v1.IOTCoreCommandStatus>} value
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse} returns this
*/
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.common.v1.IOTCoreCommandStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.common.v1.IOTCoreCommandStatus}
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.common.v1.IOTCoreCommandStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse} returns this
 */
proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetActivityLevelHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetActivityLevelHistoryRequest;
  return proto.msh.service.v1.GetActivityLevelHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.common.v1.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetActivityLevelHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest} returns this
*/
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.TimeFrame time_frame = 3;
 * @return {!proto.msh.common.v1.TimeFrame}
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.msh.common.v1.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.common.v1.TimeFrame} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryRequest} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetActivityLevelHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetActivityLevelHistoryResponse;
  return proto.msh.service.v1.GetActivityLevelHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory;
      reader.readMessage(value,proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetActivityLevelHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activityLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory;
  return proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActivityLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivityLevel();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} returns this
*/
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double activity_level = 2;
 * @return {number}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.getActivityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory.prototype.setActivityLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated ActivityHistory activities = 1;
 * @return {!Array<!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory>}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory>} value
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse} returns this
*/
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory}
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.GetActivityLevelHistoryResponse.ActivityHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetActivityLevelHistoryResponse} returns this
 */
proto.msh.service.v1.GetActivityLevelHistoryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLBuildingEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLBuildingEventsRequest;
  return proto.msh.service.v1.GetADLBuildingEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLBuildingEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsRequest} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLBuildingEventsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLBuildingEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adlEventsList: jspb.Message.toObjectList(msg.getAdlEventsList(),
    proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLBuildingEventsResponse;
  return proto.msh.service.v1.GetADLBuildingEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent;
      reader.readMessage(value,proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.deserializeBinaryFromReader);
      msg.addAdlEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLBuildingEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdlEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && msh_resource_v1_event_pb.Event.toObject(includeInstance, f),
    structure: (f = msg.getStructure()) && msh_resource_v1_structure_pb.Structure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent;
  return proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new msh_resource_v1_structure_pb.Structure;
      reader.readMessage(value,msh_resource_v1_structure_pb.Structure.deserializeBinaryFromReader);
      msg.setStructure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getStructure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_structure_pb.Structure.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Event event = 1;
 * @return {?proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.getEvent = function() {
  return /** @type{?proto.msh.resource.v1.Event} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {?proto.msh.resource.v1.Event|undefined} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional msh.resource.v1.Structure structure = 2;
 * @return {?proto.msh.resource.v1.Structure}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.getStructure = function() {
  return /** @type{?proto.msh.resource.v1.Structure} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_structure_pb.Structure, 2));
};


/**
 * @param {?proto.msh.resource.v1.Structure|undefined} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.setStructure = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.clearStructure = function() {
  return this.setStructure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ADLEvent adl_events = 1;
 * @return {!Array<!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent>}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.getAdlEventsList = function() {
  return /** @type{!Array<!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent>} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.setAdlEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.addAdlEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.GetADLBuildingEventsResponse.ADLEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.clearAdlEventsList = function() {
  return this.setAdlEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse} returns this
*/
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetADLBuildingEventsResponse} returns this
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetADLBuildingEventsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRespirationHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRespirationHistoryRequest;
  return proto.msh.service.v1.GetRespirationHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.common.v1.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRespirationHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest} returns this
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest} returns this
*/
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest} returns this
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.TimeFrame time_frame = 3;
 * @return {!proto.msh.common.v1.TimeFrame}
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.msh.common.v1.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.common.v1.TimeFrame} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryRequest} returns this
 */
proto.msh.service.v1.GetRespirationHistoryRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetRespirationHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRespirationHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratesList: jspb.Message.toObjectList(msg.getRatesList(),
    proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRespirationHistoryResponse;
  return proto.msh.service.v1.GetRespirationHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory;
      reader.readMessage(value,proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.deserializeBinaryFromReader);
      msg.addRates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRespirationHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory;
  return proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} returns this
*/
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} returns this
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double rate = 2;
 * @return {number}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory} returns this
 */
proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated RespirationHistory rates = 1;
 * @return {!Array<!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory>}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.getRatesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory>} value
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse} returns this
*/
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.setRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory}
 */
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.addRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.GetRespirationHistoryResponse.RespirationHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetRespirationHistoryResponse} returns this
 */
proto.msh.service.v1.GetRespirationHistoryResponse.prototype.clearRatesList = function() {
  return this.setRatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetHRHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetHRHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetHRHistoryRequest}
 */
proto.msh.service.v1.GetHRHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetHRHistoryRequest;
  return proto.msh.service.v1.GetHRHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetHRHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetHRHistoryRequest}
 */
proto.msh.service.v1.GetHRHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.common.v1.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetHRHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetHRHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetHRHistoryRequest} returns this
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetHRHistoryRequest} returns this
*/
proto.msh.service.v1.GetHRHistoryRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetHRHistoryRequest} returns this
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.TimeFrame time_frame = 3;
 * @return {!proto.msh.common.v1.TimeFrame}
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.msh.common.v1.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.common.v1.TimeFrame} value
 * @return {!proto.msh.service.v1.GetHRHistoryRequest} returns this
 */
proto.msh.service.v1.GetHRHistoryRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetHRHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetHRHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetHRHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetHRHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratesList: jspb.Message.toObjectList(msg.getRatesList(),
    proto.msh.service.v1.GetHRHistoryResponse.HRHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse}
 */
proto.msh.service.v1.GetHRHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetHRHistoryResponse;
  return proto.msh.service.v1.GetHRHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetHRHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse}
 */
proto.msh.service.v1.GetHRHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.service.v1.GetHRHistoryResponse.HRHistory;
      reader.readMessage(value,proto.msh.service.v1.GetHRHistoryResponse.HRHistory.deserializeBinaryFromReader);
      msg.addRates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetHRHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetHRHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetHRHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msh.service.v1.GetHRHistoryResponse.HRHistory.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetHRHistoryResponse.HRHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetHRHistoryResponse.HRHistory;
  return proto.msh.service.v1.GetHRHistoryResponse.HRHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetHRHistoryResponse.HRHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} returns this
*/
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} returns this
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double rate = 2;
 * @return {number}
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory} returns this
 */
proto.msh.service.v1.GetHRHistoryResponse.HRHistory.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated HRHistory rates = 1;
 * @return {!Array<!proto.msh.service.v1.GetHRHistoryResponse.HRHistory>}
 */
proto.msh.service.v1.GetHRHistoryResponse.prototype.getRatesList = function() {
  return /** @type{!Array<!proto.msh.service.v1.GetHRHistoryResponse.HRHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.GetHRHistoryResponse.HRHistory, 1));
};


/**
 * @param {!Array<!proto.msh.service.v1.GetHRHistoryResponse.HRHistory>} value
 * @return {!proto.msh.service.v1.GetHRHistoryResponse} returns this
*/
proto.msh.service.v1.GetHRHistoryResponse.prototype.setRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.GetHRHistoryResponse.HRHistory}
 */
proto.msh.service.v1.GetHRHistoryResponse.prototype.addRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.service.v1.GetHRHistoryResponse.HRHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetHRHistoryResponse} returns this
 */
proto.msh.service.v1.GetHRHistoryResponse.prototype.clearRatesList = function() {
  return this.setRatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetTemperaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetTemperaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetTemperaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetTemperaturesRequest}
 */
proto.msh.service.v1.GetTemperaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetTemperaturesRequest;
  return proto.msh.service.v1.GetTemperaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetTemperaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetTemperaturesRequest}
 */
proto.msh.service.v1.GetTemperaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetTemperaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetTemperaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetTemperaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetTemperaturesRequest} returns this
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetTemperaturesRequest} returns this
*/
proto.msh.service.v1.GetTemperaturesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetTemperaturesRequest} returns this
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetTemperaturesRequest} returns this
*/
proto.msh.service.v1.GetTemperaturesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetTemperaturesRequest} returns this
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetTemperaturesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetTemperaturesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetTemperaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetTemperaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetTemperaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetTemperaturesResponse}
 */
proto.msh.service.v1.GetTemperaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetTemperaturesResponse;
  return proto.msh.service.v1.GetTemperaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetTemperaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetTemperaturesResponse}
 */
proto.msh.service.v1.GetTemperaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetTemperaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetTemperaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetTemperaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetTemperaturesResponse} returns this
*/
proto.msh.service.v1.GetTemperaturesResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetTemperaturesResponse} returns this
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetTemperaturesResponse} returns this
*/
proto.msh.service.v1.GetTemperaturesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetTemperaturesResponse} returns this
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetTemperaturesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetHumiditiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetHumiditiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHumiditiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetHumiditiesRequest}
 */
proto.msh.service.v1.GetHumiditiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetHumiditiesRequest;
  return proto.msh.service.v1.GetHumiditiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetHumiditiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetHumiditiesRequest}
 */
proto.msh.service.v1.GetHumiditiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetHumiditiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetHumiditiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHumiditiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetHumiditiesRequest} returns this
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetHumiditiesRequest} returns this
*/
proto.msh.service.v1.GetHumiditiesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetHumiditiesRequest} returns this
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetHumiditiesRequest} returns this
*/
proto.msh.service.v1.GetHumiditiesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetHumiditiesRequest} returns this
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetHumiditiesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetHumiditiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetHumiditiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetHumiditiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHumiditiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetHumiditiesResponse}
 */
proto.msh.service.v1.GetHumiditiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetHumiditiesResponse;
  return proto.msh.service.v1.GetHumiditiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetHumiditiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetHumiditiesResponse}
 */
proto.msh.service.v1.GetHumiditiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetHumiditiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetHumiditiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetHumiditiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetHumiditiesResponse} returns this
*/
proto.msh.service.v1.GetHumiditiesResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetHumiditiesResponse} returns this
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetHumiditiesResponse} returns this
*/
proto.msh.service.v1.GetHumiditiesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetHumiditiesResponse} returns this
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetHumiditiesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetPressuresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetPressuresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetPressuresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetPressuresRequest}
 */
proto.msh.service.v1.GetPressuresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetPressuresRequest;
  return proto.msh.service.v1.GetPressuresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetPressuresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetPressuresRequest}
 */
proto.msh.service.v1.GetPressuresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetPressuresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetPressuresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetPressuresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetPressuresRequest} returns this
 */
proto.msh.service.v1.GetPressuresRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetPressuresRequest} returns this
*/
proto.msh.service.v1.GetPressuresRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetPressuresRequest} returns this
 */
proto.msh.service.v1.GetPressuresRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetPressuresRequest} returns this
*/
proto.msh.service.v1.GetPressuresRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetPressuresRequest} returns this
 */
proto.msh.service.v1.GetPressuresRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetPressuresRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetPressuresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetPressuresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetPressuresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetPressuresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetPressuresResponse}
 */
proto.msh.service.v1.GetPressuresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetPressuresResponse;
  return proto.msh.service.v1.GetPressuresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetPressuresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetPressuresResponse}
 */
proto.msh.service.v1.GetPressuresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetPressuresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetPressuresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetPressuresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetPressuresResponse} returns this
*/
proto.msh.service.v1.GetPressuresResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetPressuresResponse} returns this
 */
proto.msh.service.v1.GetPressuresResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetPressuresResponse} returns this
*/
proto.msh.service.v1.GetPressuresResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetPressuresResponse} returns this
 */
proto.msh.service.v1.GetPressuresResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetPressuresResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAirQualitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAirQualitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAirQualitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest}
 */
proto.msh.service.v1.GetAirQualitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAirQualitiesRequest;
  return proto.msh.service.v1.GetAirQualitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAirQualitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest}
 */
proto.msh.service.v1.GetAirQualitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAirQualitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAirQualitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAirQualitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest} returns this
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest} returns this
*/
proto.msh.service.v1.GetAirQualitiesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest} returns this
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest} returns this
*/
proto.msh.service.v1.GetAirQualitiesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAirQualitiesRequest} returns this
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAirQualitiesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetAirQualitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAirQualitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAirQualitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAirQualitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse}
 */
proto.msh.service.v1.GetAirQualitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAirQualitiesResponse;
  return proto.msh.service.v1.GetAirQualitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAirQualitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse}
 */
proto.msh.service.v1.GetAirQualitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAirQualitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAirQualitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAirQualitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse} returns this
*/
proto.msh.service.v1.GetAirQualitiesResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse} returns this
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse} returns this
*/
proto.msh.service.v1.GetAirQualitiesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAirQualitiesResponse} returns this
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAirQualitiesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetLightIntensitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetLightIntensitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_event_pb.EventFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetLightIntensitiesRequest;
  return proto.msh.service.v1.GetLightIntensitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_event_pb.EventFilter;
      reader.readMessage(value,msh_resource_v1_event_pb.EventFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetLightIntensitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetLightIntensitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_event_pb.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest} returns this
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.EventFilter filter = 2;
 * @return {?proto.msh.resource.v1.EventFilter}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.EventFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_event_pb.EventFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.EventFilter|undefined} value
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest} returns this
*/
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest} returns this
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest} returns this
*/
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetLightIntensitiesRequest} returns this
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetLightIntensitiesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetLightIntensitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetLightIntensitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetLightIntensitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetLightIntensitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msh_resource_v1_event_pb.Event.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetLightIntensitiesResponse;
  return proto.msh.service.v1.GetLightIntensitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetLightIntensitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_event_pb.Event;
      reader.readMessage(value,msh_resource_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetLightIntensitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetLightIntensitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetLightIntensitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Event events = 1;
 * @return {!Array<!proto.msh.resource.v1.Event>}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Event>} value
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse} returns this
*/
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse} returns this
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse} returns this
*/
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetLightIntensitiesResponse} returns this
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetLightIntensitiesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.msh.service.v1);
