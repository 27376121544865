// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
console.log(
  " __  __                       _           _     _   _            _ _   _     \n" +
    "|  \\/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__  \n" +
    "| |\\/| |/ _ \\ / _ \\| '_ \\/ __| '_ \\ / _ \\| __| | |_| |/ _ \\/ _` | | __| '_ \\ \n" +
    "| |  | | (_) | (_) | | | \\__ \\ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | | \n" +
    "|_|  |_|\\___/ \\___/|_| |_|___/_| |_|\\___/ \\__| |_| |_|\\___|\\__,_|_|\\__|_| |_| \n"
);
console.log("You are curious, aren't you.");

import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import {
  AUTH_AUTO_LOGIN_REQUEST,
  AUTH_LOGOUT_REQUEST,
} from "@/store/constants";
import { registerStoreCallbacks } from "@/store";
import router from "@/router";
import { registerRouterCallbacks } from "@/router";
import { registerPusherCallbacks } from "@/services/server-events.service";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import VueGtag from "vue-gtag";
import "@fortawesome/fontawesome-free/css/all.css";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import gRPCService from "@/services/grpc.service";
import stackDriverService from "@/services/stackdriver.service";
import { getAuth } from "firebase/auth";
import VueGoogleCharts from "vue-google-charts";

// Initialize Stack Driver
stackDriverService.init();

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebasePerf = getPerformance(firebaseApp);
export const auth = getAuth(firebaseApp);

auth.onAuthStateChanged((user) => {
  if (user) {
    // if we have a valid token, let's set the user
    store.dispatch(AUTH_AUTO_LOGIN_REQUEST, user);
    // User is signed in.
  } else {
    // If we're not already logout , let's logout
    if (store.getters.isAuthenticated) {
      store.dispatch(AUTH_LOGOUT_REQUEST);
    }
  }
});

gRPCService.init();

Vue.config.productionTip = true;

// This is a global message bus and thus cannot be used
// directly on a vue with v-on
export const bus = new Vue();

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ANALYTICS_ID },
    appName: process.env.VUE_APP_ANALYTICS_NAME,
    pageTrackerScreenviewEnabled: true,
  },
  router
);
// Register Google-charts plugin
Vue.use(VueGoogleCharts);

registerRouterCallbacks();
registerStoreCallbacks();
registerPusherCallbacks();
