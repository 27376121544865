// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import StackdriverErrorReporter from "stackdriver-errors-js";

var stackDriverInstance;

const stackDriverService = {
  init() {
    // Initialize Stack Driver
    stackDriverInstance = new StackdriverErrorReporter();
    stackDriverInstance.start({
      key: process.env.VUE_APP_CRASHANALYTICS_API_KEY,
      projectId: process.env.VUE_APP_CRASHANALYTICS_PROJECT_ID,
      // The following optional arguments can also be provided:
      service: "Portal",
      // Name of the service reporting the error, defaults to 'web'.

      // version: myServiceVersion,
      // Version identifier of the servic2e reporting the error.

      // reportUncaughtExceptions: false
      // Set to false to prevent reporting unhandled exceptions, default: `true`.

      // reportUnhandledPromiseRejections: false
      // Set to false to prevent reporting unhandled promise rejections, default: `true`.

      // disabled: true
      // Set to true to not send error reports, this can be used when developing locally, default: `false`.

      // context: {user: 'user1'}
      // You can set the user later using setUser()
    });
  },

  getInstance() {
    return stackDriverInstance;
  },
};

export default stackDriverService;
