// source: msh/resource/v1/device.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var msh_trait_sensor_v1_raw_storage_pb = require('../../../msh/trait/sensor/v1/raw_storage_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_raw_storage_pb);
var msh_trait_thermal_v1_thermal_pb = require('../../../msh/trait/thermal/v1/thermal_pb.js');
goog.object.extend(proto, msh_trait_thermal_v1_thermal_pb);
var msh_trait_power_v1_battery_power_source_pb = require('../../../msh/trait/power/v1/battery_power_source_pb.js');
goog.object.extend(proto, msh_trait_power_v1_battery_power_source_pb);
var msh_trait_load_v1_load_pb = require('../../../msh/trait/load/v1/load_pb.js');
goog.object.extend(proto, msh_trait_load_v1_load_pb);
var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
goog.exportSymbol('proto.msh.resource.v1.Device', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Device.displayName = 'proto.msh.resource.v1.Device';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    registryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cloudLocation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    blocked: (f = msg.getBlocked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rawStorage: (f = msg.getRawStorage()) && msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.toObject(includeInstance, f),
    cpuThermal: (f = msg.getCpuThermal()) && msh_trait_thermal_v1_thermal_pb.ThermalTrait.ThermalReading.toObject(includeInstance, f),
    battery: (f = msg.getBattery()) && msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.Battery.toObject(includeInstance, f),
    powerSource: (f = msg.getPowerSource()) && msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.PowerSource.toObject(includeInstance, f),
    load: (f = msg.getLoad()) && msh_trait_load_v1_load_pb.LoadTrait.Load.toObject(includeInstance, f),
    iotDeviceId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    otaIncrementalVersion: jspb.Message.getFieldWithDefault(msg, 16, 0),
    appVersionCode: jspb.Message.getFieldWithDefault(msg, 17, 0),
    isOnline: (f = msg.getIsOnline()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    onlineLastUpdated: (f = msg.getOnlineLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    batteryLevelLastUpdated: (f = msg.getBatteryLevelLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    powerSourceLastUpdated: (f = msg.getPowerSourceLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Device}
 */
proto.msh.resource.v1.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Device;
  return proto.msh.resource.v1.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Device}
 */
proto.msh.resource.v1.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloudLocation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBlocked(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 8:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 10:
      var value = new msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait;
      reader.readMessage(value,msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.deserializeBinaryFromReader);
      msg.setRawStorage(value);
      break;
    case 11:
      var value = new msh_trait_thermal_v1_thermal_pb.ThermalTrait.ThermalReading;
      reader.readMessage(value,msh_trait_thermal_v1_thermal_pb.ThermalTrait.ThermalReading.deserializeBinaryFromReader);
      msg.setCpuThermal(value);
      break;
    case 12:
      var value = new msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.Battery;
      reader.readMessage(value,msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 13:
      var value = new msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.PowerSource;
      reader.readMessage(value,msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.PowerSource.deserializeBinaryFromReader);
      msg.setPowerSource(value);
      break;
    case 14:
      var value = new msh_trait_load_v1_load_pb.LoadTrait.Load;
      reader.readMessage(value,msh_trait_load_v1_load_pb.LoadTrait.Load.deserializeBinaryFromReader);
      msg.setLoad(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIotDeviceId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOtaIncrementalVersion(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppVersionCode(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsOnline(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOnlineLastUpdated(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBatteryLevelLastUpdated(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPowerSourceLastUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCloudLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBlocked();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRawStorage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.serializeBinaryToWriter
    );
  }
  f = message.getCpuThermal();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      msh_trait_thermal_v1_thermal_pb.ThermalTrait.ThermalReading.serializeBinaryToWriter
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.Battery.serializeBinaryToWriter
    );
  }
  f = message.getPowerSource();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.PowerSource.serializeBinaryToWriter
    );
  }
  f = message.getLoad();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      msh_trait_load_v1_load_pb.LoadTrait.Load.serializeBinaryToWriter
    );
  }
  f = message.getIotDeviceId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOtaIncrementalVersion();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getAppVersionCode();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getIsOnline();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOnlineLastUpdated();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBatteryLevelLastUpdated();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPowerSourceLastUpdated();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string registry_id = 4;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cloud_location = 5;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getCloudLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setCloudLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.BoolValue blocked = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.msh.resource.v1.Device.prototype.getBlocked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setBlocked = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearBlocked = function() {
  return this.setBlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasBlocked = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Device.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 8;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Device.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 8));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string label = 9;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional msh.trait.sensor.v1.RawStorageTrait raw_storage = 10;
 * @return {?proto.msh.trait.sensor.v1.RawStorageTrait}
 */
proto.msh.resource.v1.Device.prototype.getRawStorage = function() {
  return /** @type{?proto.msh.trait.sensor.v1.RawStorageTrait} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait, 10));
};


/**
 * @param {?proto.msh.trait.sensor.v1.RawStorageTrait|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setRawStorage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearRawStorage = function() {
  return this.setRawStorage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasRawStorage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional msh.trait.thermal.v1.ThermalTrait.ThermalReading cpu_thermal = 11;
 * @return {?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading}
 */
proto.msh.resource.v1.Device.prototype.getCpuThermal = function() {
  return /** @type{?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} */ (
    jspb.Message.getWrapperField(this, msh_trait_thermal_v1_thermal_pb.ThermalTrait.ThermalReading, 11));
};


/**
 * @param {?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setCpuThermal = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearCpuThermal = function() {
  return this.setCpuThermal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasCpuThermal = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional msh.trait.power.v1.BatteryPowerSourceTrait.Battery battery = 12;
 * @return {?proto.msh.trait.power.v1.BatteryPowerSourceTrait.Battery}
 */
proto.msh.resource.v1.Device.prototype.getBattery = function() {
  return /** @type{?proto.msh.trait.power.v1.BatteryPowerSourceTrait.Battery} */ (
    jspb.Message.getWrapperField(this, msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.Battery, 12));
};


/**
 * @param {?proto.msh.trait.power.v1.BatteryPowerSourceTrait.Battery|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional msh.trait.power.v1.BatteryPowerSourceTrait.PowerSource power_source = 13;
 * @return {?proto.msh.trait.power.v1.BatteryPowerSourceTrait.PowerSource}
 */
proto.msh.resource.v1.Device.prototype.getPowerSource = function() {
  return /** @type{?proto.msh.trait.power.v1.BatteryPowerSourceTrait.PowerSource} */ (
    jspb.Message.getWrapperField(this, msh_trait_power_v1_battery_power_source_pb.BatteryPowerSourceTrait.PowerSource, 13));
};


/**
 * @param {?proto.msh.trait.power.v1.BatteryPowerSourceTrait.PowerSource|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setPowerSource = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearPowerSource = function() {
  return this.setPowerSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasPowerSource = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional msh.trait.load.v1.LoadTrait.Load load = 14;
 * @return {?proto.msh.trait.load.v1.LoadTrait.Load}
 */
proto.msh.resource.v1.Device.prototype.getLoad = function() {
  return /** @type{?proto.msh.trait.load.v1.LoadTrait.Load} */ (
    jspb.Message.getWrapperField(this, msh_trait_load_v1_load_pb.LoadTrait.Load, 14));
};


/**
 * @param {?proto.msh.trait.load.v1.LoadTrait.Load|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setLoad = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearLoad = function() {
  return this.setLoad(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasLoad = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string iot_device_id = 15;
 * @return {string}
 */
proto.msh.resource.v1.Device.prototype.getIotDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setIotDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 ota_incremental_version = 16;
 * @return {number}
 */
proto.msh.resource.v1.Device.prototype.getOtaIncrementalVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setOtaIncrementalVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 app_version_code = 17;
 * @return {number}
 */
proto.msh.resource.v1.Device.prototype.getAppVersionCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.setAppVersionCode = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional google.protobuf.BoolValue is_online = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.msh.resource.v1.Device.prototype.getIsOnline = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setIsOnline = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearIsOnline = function() {
  return this.setIsOnline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasIsOnline = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp online_last_updated = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Device.prototype.getOnlineLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setOnlineLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearOnlineLastUpdated = function() {
  return this.setOnlineLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasOnlineLastUpdated = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp battery_level_last_updated = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Device.prototype.getBatteryLevelLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setBatteryLevelLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearBatteryLevelLastUpdated = function() {
  return this.setBatteryLevelLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasBatteryLevelLastUpdated = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp power_source_last_updated = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Device.prototype.getPowerSourceLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Device} returns this
*/
proto.msh.resource.v1.Device.prototype.setPowerSourceLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Device} returns this
 */
proto.msh.resource.v1.Device.prototype.clearPowerSourceLastUpdated = function() {
  return this.setPowerSourceLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Device.prototype.hasPowerSourceLastUpdated = function() {
  return jspb.Message.getField(this, 21) != null;
};


goog.object.extend(exports, proto.msh.resource.v1);
