// source: msh/service/v1/command.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var msh_trait_sensor_v1_raw_storage_pb = require('../../../msh/trait/sensor/v1/raw_storage_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_raw_storage_pb);
var msh_trait_update_v1_ota_pb = require('../../../msh/trait/update/v1/ota_pb.js');
goog.object.extend(proto, msh_trait_update_v1_ota_pb);
var msh_trait_update_v1_app_pb = require('../../../msh/trait/update/v1/app_pb.js');
goog.object.extend(proto, msh_trait_update_v1_app_pb);
var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
goog.exportSymbol('proto.msh.service.v1.Command', null, global);
goog.exportSymbol('proto.msh.service.v1.Command.CommandCase', null, global);
goog.exportSymbol('proto.msh.service.v1.Command.DeviceCommand', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.msh.service.v1.Command.oneofGroups_);
};
goog.inherits(proto.msh.service.v1.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.Command.displayName = 'proto.msh.service.v1.Command';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.msh.service.v1.Command.oneofGroups_ = [[4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.msh.service.v1.Command.CommandCase = {
  COMMAND_NOT_SET: 0,
  RAW_STORAGE_COMMAND: 4,
  STORAGE_URL_COMMAND: 5,
  OTA_COMMAND: 6,
  APP_COMMAND: 7,
  DEVICE_COMMAND: 8
};

/**
 * @return {proto.msh.service.v1.Command.CommandCase}
 */
proto.msh.service.v1.Command.prototype.getCommandCase = function() {
  return /** @type {proto.msh.service.v1.Command.CommandCase} */(jspb.Message.computeOneofCase(this, proto.msh.service.v1.Command.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agent: (f = msg.getAgent()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rawStorageCommand: (f = msg.getRawStorageCommand()) && msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetRecording.toObject(includeInstance, f),
    storageUrlCommand: (f = msg.getStorageUrlCommand()) && msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetStorageUrl.toObject(includeInstance, f),
    otaCommand: (f = msg.getOtaCommand()) && msh_trait_update_v1_ota_pb.OtaTrait.Command.toObject(includeInstance, f),
    appCommand: (f = msg.getAppCommand()) && msh_trait_update_v1_app_pb.AppTrait.Command.toObject(includeInstance, f),
    deviceCommand: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.Command}
 */
proto.msh.service.v1.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.Command;
  return proto.msh.service.v1.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.Command}
 */
proto.msh.service.v1.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 4:
      var value = new msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetRecording;
      reader.readMessage(value,msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetRecording.deserializeBinaryFromReader);
      msg.setRawStorageCommand(value);
      break;
    case 5:
      var value = new msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetStorageUrl;
      reader.readMessage(value,msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetStorageUrl.deserializeBinaryFromReader);
      msg.setStorageUrlCommand(value);
      break;
    case 6:
      var value = new msh_trait_update_v1_ota_pb.OtaTrait.Command;
      reader.readMessage(value,msh_trait_update_v1_ota_pb.OtaTrait.Command.deserializeBinaryFromReader);
      msg.setOtaCommand(value);
      break;
    case 7:
      var value = new msh_trait_update_v1_app_pb.AppTrait.Command;
      reader.readMessage(value,msh_trait_update_v1_app_pb.AppTrait.Command.deserializeBinaryFromReader);
      msg.setAppCommand(value);
      break;
    case 8:
      var value = /** @type {!proto.msh.service.v1.Command.DeviceCommand} */ (reader.readEnum());
      msg.setDeviceCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRawStorageCommand();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetRecording.serializeBinaryToWriter
    );
  }
  f = message.getStorageUrlCommand();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetStorageUrl.serializeBinaryToWriter
    );
  }
  f = message.getOtaCommand();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      msh_trait_update_v1_ota_pb.OtaTrait.Command.serializeBinaryToWriter
    );
  }
  f = message.getAppCommand();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      msh_trait_update_v1_app_pb.AppTrait.Command.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.msh.service.v1.Command.DeviceCommand} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.msh.service.v1.Command.DeviceCommand = {
  DEVICE_COMMAND_UNSPECIFIED: 0,
  DEVICE_COMMAND_REBOOT: 1
};

/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.msh.service.v1.Command.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.common.v1.Agent agent = 2;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.service.v1.Command.prototype.getAgent = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 2));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.service.v1.Command.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional msh.trait.sensor.v1.RawStorageTrait.SetRecording raw_storage_command = 4;
 * @return {?proto.msh.trait.sensor.v1.RawStorageTrait.SetRecording}
 */
proto.msh.service.v1.Command.prototype.getRawStorageCommand = function() {
  return /** @type{?proto.msh.trait.sensor.v1.RawStorageTrait.SetRecording} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetRecording, 4));
};


/**
 * @param {?proto.msh.trait.sensor.v1.RawStorageTrait.SetRecording|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setRawStorageCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.msh.service.v1.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearRawStorageCommand = function() {
  return this.setRawStorageCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasRawStorageCommand = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional msh.trait.sensor.v1.RawStorageTrait.SetStorageUrl storage_url_command = 5;
 * @return {?proto.msh.trait.sensor.v1.RawStorageTrait.SetStorageUrl}
 */
proto.msh.service.v1.Command.prototype.getStorageUrlCommand = function() {
  return /** @type{?proto.msh.trait.sensor.v1.RawStorageTrait.SetStorageUrl} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_raw_storage_pb.RawStorageTrait.SetStorageUrl, 5));
};


/**
 * @param {?proto.msh.trait.sensor.v1.RawStorageTrait.SetStorageUrl|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setStorageUrlCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.msh.service.v1.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearStorageUrlCommand = function() {
  return this.setStorageUrlCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasStorageUrlCommand = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional msh.trait.update.v1.OtaTrait.Command ota_command = 6;
 * @return {?proto.msh.trait.update.v1.OtaTrait.Command}
 */
proto.msh.service.v1.Command.prototype.getOtaCommand = function() {
  return /** @type{?proto.msh.trait.update.v1.OtaTrait.Command} */ (
    jspb.Message.getWrapperField(this, msh_trait_update_v1_ota_pb.OtaTrait.Command, 6));
};


/**
 * @param {?proto.msh.trait.update.v1.OtaTrait.Command|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setOtaCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.msh.service.v1.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearOtaCommand = function() {
  return this.setOtaCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasOtaCommand = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional msh.trait.update.v1.AppTrait.Command app_command = 7;
 * @return {?proto.msh.trait.update.v1.AppTrait.Command}
 */
proto.msh.service.v1.Command.prototype.getAppCommand = function() {
  return /** @type{?proto.msh.trait.update.v1.AppTrait.Command} */ (
    jspb.Message.getWrapperField(this, msh_trait_update_v1_app_pb.AppTrait.Command, 7));
};


/**
 * @param {?proto.msh.trait.update.v1.AppTrait.Command|undefined} value
 * @return {!proto.msh.service.v1.Command} returns this
*/
proto.msh.service.v1.Command.prototype.setAppCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.msh.service.v1.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearAppCommand = function() {
  return this.setAppCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasAppCommand = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DeviceCommand device_command = 8;
 * @return {!proto.msh.service.v1.Command.DeviceCommand}
 */
proto.msh.service.v1.Command.prototype.getDeviceCommand = function() {
  return /** @type {!proto.msh.service.v1.Command.DeviceCommand} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.msh.service.v1.Command.DeviceCommand} value
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.setDeviceCommand = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.msh.service.v1.Command.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.msh.service.v1.Command} returns this
 */
proto.msh.service.v1.Command.prototype.clearDeviceCommand = function() {
  return jspb.Message.setOneofField(this, 8, proto.msh.service.v1.Command.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.Command.prototype.hasDeviceCommand = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.msh.service.v1);
