// source: msh/resource/v1/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_resource_v1_user_pb = require('../../../msh/resource/v1/user_pb.js');
goog.object.extend(proto, msh_resource_v1_user_pb);
goog.exportSymbol('proto.msh.resource.v1.Account', null, global);
goog.exportSymbol('proto.msh.resource.v1.Account.Business', null, global);
goog.exportSymbol('proto.msh.resource.v1.Account.Consumer', null, global);
goog.exportSymbol('proto.msh.resource.v1.Account.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.msh.resource.v1.Account.oneofGroups_);
};
goog.inherits(proto.msh.resource.v1.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Account.displayName = 'proto.msh.resource.v1.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Account.Consumer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Account.Consumer.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Account.Consumer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Account.Consumer.displayName = 'proto.msh.resource.v1.Account.Consumer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Account.Business = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Account.Business.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Account.Business, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Account.Business.displayName = 'proto.msh.resource.v1.Account.Business';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.msh.resource.v1.Account.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.msh.resource.v1.Account.TypeCase = {
  TYPE_NOT_SET: 0,
  CONSUMER: 5,
  BUSINESS: 6
};

/**
 * @return {proto.msh.resource.v1.Account.TypeCase}
 */
proto.msh.resource.v1.Account.prototype.getTypeCase = function() {
  return /** @type {proto.msh.resource.v1.Account.TypeCase} */(jspb.Message.computeOneofCase(this, proto.msh.resource.v1.Account.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumer: (f = msg.getConsumer()) && proto.msh.resource.v1.Account.Consumer.toObject(includeInstance, f),
    business: (f = msg.getBusiness()) && proto.msh.resource.v1.Account.Business.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Account}
 */
proto.msh.resource.v1.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Account;
  return proto.msh.resource.v1.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Account}
 */
proto.msh.resource.v1.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.msh.resource.v1.Account.Consumer;
      reader.readMessage(value,proto.msh.resource.v1.Account.Consumer.deserializeBinaryFromReader);
      msg.setConsumer(value);
      break;
    case 6:
      var value = new proto.msh.resource.v1.Account.Business;
      reader.readMessage(value,proto.msh.resource.v1.Account.Business.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.msh.resource.v1.Account.Consumer.serializeBinaryToWriter
    );
  }
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.msh.resource.v1.Account.Business.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Account.Consumer.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Account.Consumer.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Account.Consumer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Account.Consumer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.Consumer.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: (f = msg.getOwner()) && msh_resource_v1_user_pb.User.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Account.Consumer}
 */
proto.msh.resource.v1.Account.Consumer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Account.Consumer;
  return proto.msh.resource.v1.Account.Consumer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Account.Consumer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Account.Consumer}
 */
proto.msh.resource.v1.Account.Consumer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 4:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 6:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Account.Consumer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Account.Consumer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Account.Consumer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.Consumer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User owner = 3;
 * @return {?proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getOwner = function() {
  return /** @type{?proto.msh.resource.v1.User} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_user_pb.User, 3));
};


/**
 * @param {?proto.msh.resource.v1.User|undefined} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
*/
proto.msh.resource.v1.Account.Consumer.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.Consumer.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 4));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
*/
proto.msh.resource.v1.Account.Consumer.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Account.Consumer.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional google.protobuf.Timestamp creation_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
*/
proto.msh.resource.v1.Account.Consumer.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.Consumer.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 6;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Account.Consumer.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 6));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
*/
proto.msh.resource.v1.Account.Consumer.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account.Consumer} returns this
 */
proto.msh.resource.v1.Account.Consumer.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.Consumer.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Account.Business.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Account.Business.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Account.Business.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Account.Business} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.Business.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    managersList: jspb.Message.toObjectList(msg.getManagersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Account.Business}
 */
proto.msh.resource.v1.Account.Business.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Account.Business;
  return proto.msh.resource.v1.Account.Business.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Account.Business} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Account.Business}
 */
proto.msh.resource.v1.Account.Business.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addManagers(value);
      break;
    case 4:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 6:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Account.Business.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Account.Business.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Account.Business} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Account.Business.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Account.Business.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.msh.resource.v1.Account.Business.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated User managers = 3;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Account.Business.prototype.getManagersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 3));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
*/
proto.msh.resource.v1.Account.Business.prototype.setManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Account.Business.prototype.addManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.clearManagersList = function() {
  return this.setManagersList([]);
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Account.Business.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 4));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
*/
proto.msh.resource.v1.Account.Business.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Account.Business.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional google.protobuf.Timestamp creation_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Account.Business.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
*/
proto.msh.resource.v1.Account.Business.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.Business.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 6;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Account.Business.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 6));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Account.Business} returns this
*/
proto.msh.resource.v1.Account.Business.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account.Business} returns this
 */
proto.msh.resource.v1.Account.Business.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.Business.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Consumer consumer = 5;
 * @return {?proto.msh.resource.v1.Account.Consumer}
 */
proto.msh.resource.v1.Account.prototype.getConsumer = function() {
  return /** @type{?proto.msh.resource.v1.Account.Consumer} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Account.Consumer, 5));
};


/**
 * @param {?proto.msh.resource.v1.Account.Consumer|undefined} value
 * @return {!proto.msh.resource.v1.Account} returns this
*/
proto.msh.resource.v1.Account.prototype.setConsumer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.msh.resource.v1.Account.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account} returns this
 */
proto.msh.resource.v1.Account.prototype.clearConsumer = function() {
  return this.setConsumer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.prototype.hasConsumer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Business business = 6;
 * @return {?proto.msh.resource.v1.Account.Business}
 */
proto.msh.resource.v1.Account.prototype.getBusiness = function() {
  return /** @type{?proto.msh.resource.v1.Account.Business} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Account.Business, 6));
};


/**
 * @param {?proto.msh.resource.v1.Account.Business|undefined} value
 * @return {!proto.msh.resource.v1.Account} returns this
*/
proto.msh.resource.v1.Account.prototype.setBusiness = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.msh.resource.v1.Account.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Account} returns this
 */
proto.msh.resource.v1.Account.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Account.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.msh.resource.v1);
