// source: msh/common/v1/capture_mode.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.msh.common.v1.CaptureMode', null, global);
/**
 * @enum {number}
 */
proto.msh.common.v1.CaptureMode = {
  CAPTURE_MODE_UNSPECIFIED: 0,
  CAPTURE_MODE_BATCHED: 1,
  CAPTURE_MODE_STREAMED: 2
};

goog.object.extend(exports, proto.msh.common.v1);
