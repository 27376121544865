// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.

// Global application store definition
// This module contains all of the actions and commits used in the application.
// Stored them in a central area to make it clearer
import gRPCService from "@/services/grpc.service";
import urlsafeService from "@/services/urlsafe.service";
import dateUtils from "@/utils/date.utils";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import timestampProto from "@/grpc/google/protobuf/timestamp_pb";
import eventProtoDef from "@/grpc/msh/resource/v1/event_pb";
import alertProtoDef from "@/grpc/msh/service/v1/alert_manager_pb";
import commonDef from "@/grpc/msh/common/v1/common_pb";
import {
  STRUCTURE_RESET_REQUEST,
  STRUCTURE_RESET_SUCCESS,
  STRUCTURE_REQUEST,
  STRUCTURE_FETCH_SUCCESS,
  STRUCTURE_ALERT_REQUEST,
  STRUCTURE_ALERT_SUCCESS,
  STRUCTURE_ADL_REQUEST,
  STRUCTURE_ADL_SUCCESS,
  STRUCTURE_ACTIVITY_LEVEL_REQUEST,
  STRUCTURE_ACTIVITY_LEVEL_SUCCESS,
  STRUCTURE_SENSOR_TEMPERATURE_REQUEST,
  STRUCTURE_SENSOR_TEMPERATURE_SUCCESS,
  STRUCTURE_SENSOR_HUMIDITY_REQUEST,
  STRUCTURE_SENSOR_HUMIDITY_SUCCESS,
  STRUCTURE_SENSOR_AIR_QUALITY_REQUEST,
  STRUCTURE_SENSOR_AIR_QUALITY_SUCCESS,
  STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE,
  BREADCRUMB_SET_REQUEST,

  // PUSHER_ONLINE_OFFLINE_EVENT,
  // PUSHER_CAST_VOTE_PRESENCE,
  // PUSHER_UPDATE_STRUCTURE,
  // PUSHER_ADD_ALERT,
  // PUSHER_ALERT_ACKNOWLEDGE,
} from "@/store/constants";

// import { STRUCTURE_TRIGGER_SUCCEEDED, STRUCTURE_TRIGGER_ERROR } from "@/events";
import { bus } from "@/main.js";
import store from "@/store";

const state = {
  structure: null,
  structure_alert: null,
  structure_temperature: null,
  structure_activity_level: [],
  structure_humidities: [],
  structure_air_qualities: [],
  structure_adl: [],
};

const actions = {
  //
  // STRUCTURE_RESET_REQUEST
  //
  [STRUCTURE_RESET_REQUEST](context) {
    context.commit(STRUCTURE_RESET_SUCCESS);
  },

  //
  // Fetch a structure
  //
  [STRUCTURE_REQUEST](context, structureId) {
    context.dispatch(STRUCTURE_RESET_REQUEST);
    let structureRequest = new structureProtoDef.GetStructuresRequest([
      structureId,
      true,
      ["", 1],
      false,
    ]);
    gRPCService
      .structure()
      .getStructures(structureRequest, {})
      .then((structuresResponse) => {
        if (structuresResponse.getStructuresList().length > 0) {
          // Only getting the one result.
          let structure = structuresResponse.getStructuresList()[0];
          context.commit(STRUCTURE_FETCH_SUCCESS, structure);
          context.dispatch(BREADCRUMB_SET_REQUEST, [
            { title: structure.getBusiness().getLabel() },
          ]);
        }
      });
  },
  //
  // STRUCTURE_ALERT_REQUEST
  //
  [STRUCTURE_ALERT_REQUEST](context, structureId) {
    const getAlertsRequest = new alertProtoDef.GetAlertsRequest([
      structureId,
      [["", ""], 1],
      ["", 1],
    ]);
    gRPCService
      .alert()
      .getAlerts(getAlertsRequest, {})
      .then((alertsResponse) => {
        if (alertsResponse && alertsResponse.getAlertsList().length > 0) {
          context.commit(
            STRUCTURE_ALERT_SUCCESS,
            alertsResponse.getAlertsList()[0].toObject()
          );
        } else {
          context.commit(STRUCTURE_ALERT_SUCCESS, null);
        }
      });
  },
  //
  // STRUCTURE_ADL_REQUEST
  //
  [STRUCTURE_ADL_REQUEST](context, structureId) {
    // Might be from a param ...
    const oneDayRange = dateUtils.generateDateRangeForTwelveHours();
    const eventFilter = new eventProtoDef.EventFilter();
    eventFilter.setTimeRange(oneDayRange);
    const protoPage = new commonDef.Page();
    // Might be from a param ...
    protoPage.setPerPage("250");
    protoPage.setCursor("");
    const adlRequest = new structureProtoDef.GetADLStructureEventsRequest();
    adlRequest.setStructureId(structureId);
    adlRequest.setFilter(eventFilter);
    adlRequest.setPage(protoPage);
    gRPCService
      .structure()
      .getADLStructureEvents(adlRequest, {})
      .then((adlResponse) => {
        // Handle pages ??
        context.commit(STRUCTURE_ADL_SUCCESS, adlResponse.toObject());
      });
  },
  //
  // STRUCTURE_ACTIVITY_LEVEL_REQUEST
  //
  [STRUCTURE_ACTIVITY_LEVEL_REQUEST](context, structureId) {
    const dateEnd = new timestampProto.Timestamp();
    const today = new Date();
    dateEnd.fromDate(today);
    const activityLevelRequest =
      new structureProtoDef.GetActivityLevelHistoryRequest();
    activityLevelRequest.setStructureId(structureId);
    activityLevelRequest.setTimestamp(dateEnd);
    activityLevelRequest.setTimeFrame(1);
    gRPCService
      .structure()
      .getActivityLevelHistory(activityLevelRequest, {})
      .then((activityLevelResponse) => {
        context.commit(
          STRUCTURE_ACTIVITY_LEVEL_SUCCESS,
          activityLevelResponse.toObject()
        );
      });
  },
  //
  // STRUCTURE_SENSOR_TEMPERATURE_REQUEST
  //
  [STRUCTURE_SENSOR_TEMPERATURE_REQUEST](context, structureId) {
    // Might be from a param ...
    const oneDayRange = dateUtils.generateDateRangeForOneDay();
    const eventFilter = new eventProtoDef.EventFilter();
    eventFilter.setTimeRange(oneDayRange);
    const protoPage = new commonDef.Page();
    // Might be from a param ...
    protoPage.setPerPage("10");
    protoPage.setCursor("");
    const temperatureRequest = new structureProtoDef.GetTemperaturesRequest();
    temperatureRequest.setStructureId(structureId);
    temperatureRequest.setFilter(eventFilter);
    temperatureRequest.setPage(protoPage);
    gRPCService
      .structure()
      .getTemperatures(temperatureRequest, {})
      .then((temperaturesResponse) => {
        const tempResponse = temperaturesResponse.toObject();
        // data is from the newest to latest, reverse to have better chart data
        context.commit(
          STRUCTURE_SENSOR_TEMPERATURE_SUCCESS,
          tempResponse.eventsList.reverse()
        );
      });
  },
  //
  // STRUCTURE_SENSOR_AIR_QUALITY_REQUEST
  //
  [STRUCTURE_SENSOR_AIR_QUALITY_REQUEST](context, structureId) {
    // Might be from a param ...
    const oneDayRange = dateUtils.generateDateRangeForOneDay();
    const eventFilter = new eventProtoDef.EventFilter();
    eventFilter.setTimeRange(oneDayRange);
    const protoPage = new commonDef.Page();
    // Might be from a param ...
    protoPage.setPerPage("10");
    protoPage.setCursor("");
    const airQualityRequest = new structureProtoDef.GetAirQualitiesRequest();
    airQualityRequest.setStructureId(structureId);
    airQualityRequest.setFilter(eventFilter);
    airQualityRequest.setPage(protoPage);
    gRPCService
      .structure()
      .getAirQualities(airQualityRequest, {})
      .then((airQualityResponse) => {
        const airQuality = airQualityResponse.toObject();
        // data is from the newest to latest, reverse to have better chart data
        context.commit(
          STRUCTURE_SENSOR_AIR_QUALITY_SUCCESS,
          airQuality.eventsList.reverse()
        );
      });
  },
  //
  // STRUCTURE_SENSOR_HUMIDITY_REQUEST
  //
  [STRUCTURE_SENSOR_HUMIDITY_REQUEST](context, structureId) {
    // Might be from a param ...
    const oneDayRange = dateUtils.generateDateRangeForOneDay();
    const eventFilter = new eventProtoDef.EventFilter();
    eventFilter.setTimeRange(oneDayRange);
    const protoPage = new commonDef.Page();
    // Might be from a param ...
    protoPage.setPerPage("10");
    protoPage.setCursor("");
    const humidityRequest = new structureProtoDef.GetHumiditiesRequest();
    humidityRequest.setStructureId(structureId);
    humidityRequest.setFilter(eventFilter);
    humidityRequest.setPage(protoPage);
    gRPCService
      .structure()
      .getHumidities(humidityRequest, {})
      .then((humidityResponse) => {
        const humidity = humidityResponse.toObject();
        context.commit(
          STRUCTURE_SENSOR_HUMIDITY_SUCCESS,
          humidity.eventsList.reverse()
        );
      });
  },
  //
  // STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE
  //
  [STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE](context, alertId) {
    return new Promise((resolve, reject) => {
      const ackRequest = new alertProtoDef.AcknowledgeAlertByUserRequest();
      ackRequest.setAlertId(alertId);

      gRPCService
        .alert()
        .acknowledgeAlertByUser(ackRequest, {})
        .then((ackResponse) => {
          resolve(ackResponse.toObject());
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //
  // PUSHER_ONLINE_OFFLINE_EVENT
  //
  // [PUSHER_ONLINE_OFFLINE_EVENT](context, payload) {
  //   if (payload) {

  //   }
  // },
  // //
  // // ALL PUSHER UPDATES related to a single update of a structure/appartment
  // //
  // [PUSHER_CAST_VOTE_PRESENCE](context, payload) {
  //   if (payload) {

  //   }
  // },
  // [PUSHER_UPDATE_STRUCTURE](context, payload) {
  //   if (payload) {

  //   }
  // },
  // [PUSHER_ADD_ALERT](context, payload) {
  //   if (payload && payload.alertCreated) {

  //   }
  // },
  // [PUSHER_ALERT_ACKNOWLEDGE](context, payload) {
  //   if (payload) {

  //   }
  // },
};

const mutations = {
  //
  // FLOOR_RESET_SUCCESS
  //
  [STRUCTURE_RESET_SUCCESS](state) {
    state.structure = null;
    state.structure_alert = null;
    state.structure_temperature = null;
    state.structure_activity_level = [];
    state.structure_humidities = [];
    state.structure_air_qualities = [];
    state.structure_adl = [];
  },
  //
  // STRUCTURE_FETCH_SUCCESS
  //
  [STRUCTURE_FETCH_SUCCESS](state, payload) {
    let new_structure = payload.getBusiness().toObject();
    new_structure.numeric_structure_id = urlsafeService.returnIdForType(
      payload.getBusiness().getStructureId(),
      "Structure"
    );
    state.structure = new_structure;
  },
  //
  // STRUCTURE_ALERT_SUCCESS
  //
  [STRUCTURE_ALERT_SUCCESS](state, payload) {
    state.structure_alert = payload;
  },
  //
  // STRUCTURE_ADL_SUCCESS
  //
  [STRUCTURE_ADL_SUCCESS](state, payload) {
    state.structure_adl = payload.eventsList;
  },
  //
  // STRUCTURE_ACTIVITY_LEVEL_SUCCESS
  //
  [STRUCTURE_ACTIVITY_LEVEL_SUCCESS](state, payload) {
    state.structure_activity_level = { average: null, chart_data: [] };
    state.structure_activity_level.chart_data.push(["Time", "Activity Level"]);
    const values = [];
    payload.activitiesList.forEach((element) => {
      state.structure_activity_level.chart_data.push([
        new Date(element.timestamp.seconds * 1000),
        element.activityLevel || 0,
      ]);
      values.push(element.activityLevel || 0);
    });
    const sum = values.reduce((a, b) => a + b, 0);
    state.structure_activity_level.average = Math.round(
      sum / payload.activitiesList.length || 0
    );
  },
  //
  // STRUCTURE_SENSOR_TEMPERATURE_SUCCESS
  //
  [STRUCTURE_SENSOR_TEMPERATURE_SUCCESS](state, payload) {
    state.structure_temperature = { values: [], labels: [], chart_data: [] };
    state.structure_temperature.chart_data.push(["Time", "Temperature"]);
    payload.forEach((element) => {
      // const formattedTimestamp = $d(
      //   new Date(element.temperature.timestamp.seconds * 1000),
      //   "time"
      // );
      state.structure_temperature.values.push(
        Math.round(element.temperature.trait.temperature.value)
      );
      // should be $d i18n but might be done in the ui
      state.structure_temperature.labels.push(
        new Date(element.temperature.timestamp.seconds * 1000)
      );

      state.structure_temperature.chart_data.push([
        new Date(element.temperature.timestamp.seconds * 1000),
        Math.round(element.temperature.trait.temperature.value),
      ]);
    });
    if (state.structure_temperature.values.length > 0) {
      let sum = state.structure_temperature.values.reduce((a, b) => a + b, 0);
      state.structure_temperature.average = Math.round(
        sum / state.structure_temperature.values.length || 0
      );
      state.structure_temperature.highest = Math.max(
        ...state.structure_temperature.values
      );
      state.structure_temperature.highest_timestamp =
        state.structure_temperature.labels[
          state.structure_temperature.values.indexOf(
            state.structure_temperature.highest
          )
        ];
    }
  },
  //
  // STRUCTURE_SENSOR_HUMIDITY_SUCCESS
  //
  [STRUCTURE_SENSOR_HUMIDITY_SUCCESS](state, payload) {
    state.structure_humidities = [];
    state.structure_humidities.push(["Time", "Humidity"]);
    payload.forEach((element) => {
      state.structure_humidities.push([
        new Date(element.humidity.timestamp.seconds * 1000),
        element.humidity.trait.humidity,
      ]);
    });
  },
  //
  // STRUCTURE_SENSOR_AIR_QUALITY_SUCCESS
  //
  [STRUCTURE_SENSOR_AIR_QUALITY_SUCCESS](state, payload) {
    state.structure_air_qualities = [];
    state.structure_air_qualities.push(["Time", "Air Quality"]);
    payload.forEach((element) => {
      state.structure_air_qualities.push([
        new Date(element.airQuality.timestamp.seconds * 1000),
        element.airQuality.trait.airQuality,
      ]);
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
