// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import {
  ACCOUNT_CHANGED_EVENT,
  AUTH_LOGINCONFIRMED,
  AUTH_LOGOUTCONFIRMED,
  ERROR_EVENT,
} from "@/events";
import {
  ACCOUNT_GET_REQUEST,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_RESET_REQUEST,
  ACCOUNT_RESET_SUCCESS,
} from "@/store/constants";
import accountProtoDef from "@/grpc/msh/service/v1/account_manager_pb";
import gRPCService from "@/services/grpc.service";
import store from "@/store";
import { bus } from "@/main.js";

// Functions to respond to events
export const registerAccountCallbacks = () => {
  bus.$on(AUTH_LOGINCONFIRMED, (user) => {
    store.dispatch(ACCOUNT_GET_REQUEST, {
      email: user.email,
    });
  });

  bus.$on(AUTH_LOGOUTCONFIRMED, () => {
    store.dispatch(ACCOUNT_RESET_REQUEST);
  });
};

const state = {
  account: {},
};

const actions = {
  //
  // ACCOUNT_GET_REQUEST
  //
  [ACCOUNT_GET_REQUEST](context, payload) {
    const request = new accountProtoDef.GetCurrentAccountsRequest();
    gRPCService
      .account()
      .getCurrentAccounts(request, {})
      .then((response) => {
        // simple validation to make sure we have a proper account id set
        if (
          response &&
          response.getAccountsList() &&
          response.getAccountsList()[0] &&
          response.getAccountsList()[0].hasBusiness()
        ) {
          context.commit(ACCOUNT_GET_SUCCESS, response.getAccountsList()[0]);
        } else {
          bus.$emit(
            ERROR_EVENT,
            "Could not find proper account id for the logged user"
          );
        }
      });
  },

  //
  // ACCOUNT_RESET_REQUEST
  //
  [ACCOUNT_RESET_REQUEST](context) {
    context.commit(ACCOUNT_RESET_SUCCESS);
  },
};

const mutations = {
  //
  // ACCOUNT_GET_SUCCESS
  //
  [ACCOUNT_GET_SUCCESS](state, account) {
    state.account = account;
    bus.$emit(ACCOUNT_CHANGED_EVENT, account.getBusiness().getAccountId());
  },
  //
  // ACCOUNT_RESET_SUCCESS
  //
  [ACCOUNT_RESET_SUCCESS](state) {
    state.account = {};
  },
};

const getters = {
  getAccountId: (state) => {
    return state.account.getBusiness().getAccountId();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
