// source: msh/trait/activities/v1/audio_classification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_capture_mode_pb = require('../../../../msh/common/v1/capture_mode_pb.js');
goog.object.extend(proto, msh_common_v1_capture_mode_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.msh.trait.activities.v1.AudioClassificationTrait', null, global);
goog.exportSymbol('proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.activities.v1.AudioClassificationTrait = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.activities.v1.AudioClassificationTrait, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.activities.v1.AudioClassificationTrait.displayName = 'proto.msh.trait.activities.v1.AudioClassificationTrait';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.displayName = 'proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.activities.v1.AudioClassificationTrait.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.activities.v1.AudioClassificationTrait;
  return proto.msh.trait.activities.v1.AudioClassificationTrait.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.activities.v1.AudioClassificationTrait.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    captureMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 5, ""),
    index: jspb.Message.getFieldWithDefault(msg, 6, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent;
  return proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.msh.common.v1.CaptureMode} */ (reader.readEnum());
      msg.setCaptureMode(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCaptureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
*/
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional msh.common.v1.CaptureMode capture_mode = 2;
 * @return {!proto.msh.common.v1.CaptureMode}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getCaptureMode = function() {
  return /** @type {!proto.msh.common.v1.CaptureMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.msh.common.v1.CaptureMode} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setCaptureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
*/
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
*/
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string label = 5;
 * @return {string}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 index = 6;
 * @return {number}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double score = 7;
 * @return {number}
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} returns this
 */
proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


goog.object.extend(exports, proto.msh.trait.activities.v1);
