// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.

// Global application store definition
// This module contains all of the actions and commits used in the application.
// Stored them in a central area to make it clearer
import gRPCService from "@/services/grpc.service";
import urlsafeService from "@/services/urlsafe.service";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import indexProtoDef from "@/grpc/msh/service/v1/index_manager_pb";
import {
  RECORDINGS_FETCH_REQUEST,
  RECORDINGS_LIST_FOR_FLOOR_REQUEST,
  RECORDINGS_LIST_FOR_STRUCTURE_REQUEST,
  RECORDINGS_FETCH_SUCCESS,
  RECORDINGS_LIST_FOR_FLOOR_SUCCESS,
  RECORDINGS_LIST_FOR_STRUCTURE_SUCCESS,
  RECORDINGS_LIST_FOR_BUILDING_TITLE,
  RECORDINGS_LIST_FOR_FLOOR_TITLE,
  RECORDINGS_LIST_FOR_STRUCTURE_TITLE,
  RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE,
  PUSHER_RECORDING_CHANGED,
  PUSHER_RECORDING_CONFIRMED,
  RECORDING_GET_REQUEST,
  RECORDING_GET_SUCCESS,
  RECORDING_GET_PROMISE,
} from "@/store/constants";

import Vue from "vue";

const state = {
  recordings: [],
  floor_records: [],
  structure_records: [],
  recording_title: "Building",
  floors_label: [],
  structures_label: [],
};

let buildingIDInUse = "";
let numericFloorIDInUse = "";

const actions = {
  //
  // PUSHER_RECORDING_CHANGED
  // Called on a device recording changed event (start, finished, errored, ...)
  [PUSHER_RECORDING_CHANGED](context, payload) {
    if (payload) {
      let pusher_building_id = urlsafeService.returnIdForType(
        payload.deviceId,
        "Building"
      );
      let current_building_id = urlsafeService.returnIdForType(
        buildingIDInUse,
        "Building"
      );
      if (pusher_building_id == current_building_id) {
        context.dispatch(
          RECORDING_GET_REQUEST,
          payload.pubsubMessage.recordingChanged.sessionId
        );
      }
    }
  },
  //
  // PUSHER_RECORDING_CHANGED
  // Called on a device recording changed event (start, finished, errored, ...)
  [PUSHER_RECORDING_CONFIRMED](context, payload) {
    if (payload) {
      let pusher_building_id = urlsafeService.returnIdForType(
        payload.recording.id,
        "Building"
      );
      let current_building_id = urlsafeService.returnIdForType(
        buildingIDInUse,
        "Building"
      );
      if (pusher_building_id == current_building_id) {
        context.dispatch(RECORDING_GET_REQUEST, payload.recording.sessionId);
      }
    }
  },
  //
  // RECORDINGS_FETCH_REQUEST
  // This fetch both the records and the floor structure to have labels
  // Working with a promise to time other action after this one.
  //
  [RECORDINGS_FETCH_REQUEST](context, building_id) {
    return new Promise((resolve, reject) => {
      context.commit(RECORDINGS_LIST_FOR_BUILDING_TITLE);
      if (buildingIDInUse == building_id) {
        resolve();
        return;
      }
      buildingIDInUse = building_id;
      const recordingsRequest = new indexProtoDef.ListRecordingsRequest();
      recordingsRequest.setAncestorId(building_id);

      const request = new structureProtoDef.GetFloorsRequest([
        building_id,
        true,
      ]);
      gRPCService
        .building()
        .getFloors(request, {})
        .then((floorsResponse) => {
          gRPCService
            .index()
            .listRecordings(recordingsRequest, {})
            .then((recordingsResponse) => {
              context.commit(RECORDINGS_FETCH_SUCCESS, {
                records: recordingsResponse.toObject(),
                floors: floorsResponse.getFloorsList(),
              });
              resolve();
            });
        })
        .catch(() => {
          reject();
        });
    });
  },
  //
  // RECORDING_GET_REQUEST
  // This is called via a pusher event
  //
  [RECORDING_GET_REQUEST](context, session_id) {
    const getRecordingRequest = new indexProtoDef.GetRecordingRequest();
    getRecordingRequest.setSessionId(session_id);
    gRPCService
      .index()
      .getRecording(getRecordingRequest, {})
      .then((getResponse) => {
        context.commit(RECORDING_GET_SUCCESS, getResponse.toObject().recording);
      });
  },
  //
  // RECORDING_GET_PROMISE
  //
  [RECORDING_GET_PROMISE](context, session_id) {
    return new Promise((resolve, reject) => {
      const getRecordingRequest = new indexProtoDef.GetRecordingRequest();
      getRecordingRequest.setSessionId(session_id);
      getRecordingRequest.setWithHistory(true);
      gRPCService
        .index()
        .getRecording(getRecordingRequest, {})
        .then((getResponse) => {
          resolve(getResponse.toObject().recording);
        })
        .catch(() => {
          reject();
        });
    });
  },
  //
  // RECORDINGS_LIST_FOR_FLOOR_REQUEST
  //
  [RECORDINGS_LIST_FOR_FLOOR_REQUEST](context, floor_id) {
    context.commit(RECORDINGS_LIST_FOR_FLOOR_TITLE, floor_id);
    if (numericFloorIDInUse == floor_id) {
      return;
    }
    context.commit(RECORDINGS_LIST_FOR_FLOOR_SUCCESS, floor_id);
    numericFloorIDInUse = floor_id;
  },
  //
  // RECORDINGS_LIST_FOR_FLOOR_REQUEST
  //
  [RECORDINGS_LIST_FOR_STRUCTURE_REQUEST](context, { structure_id, floor_id }) {
    context.commit(RECORDINGS_LIST_FOR_STRUCTURE_TITLE, {
      structure_id,
      floor_id,
    });
    context.commit(RECORDINGS_LIST_FOR_FLOOR_SUCCESS, floor_id);
    context.commit(RECORDINGS_LIST_FOR_STRUCTURE_SUCCESS, structure_id);
  },
  //
  // RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE
  //
  [RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE](context, path) {
    return new Promise((resolve, reject) => {
      const request = new indexProtoDef.RequestDownloadUrlRequest();
      // request.setStructureId(downloadRequest.structureId);
      request.setPath(path);
      gRPCService
        .index()
        .requestDownloadUrl(request, {})
        .then((downloadResponse) => {
          resolve(downloadResponse.getUrl());
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  //
  // RECORDINGS_FETCH_SUCCESS
  //
  [RECORDINGS_FETCH_SUCCESS](state, { records, floors }) {
    state.recordings = [];
    state.floor_records = [];
    state.structure_records = [];
    state.floors_label = [];
    state.structures_label = [];
    numericFloorIDInUse = "";

    records.floorsList.forEach((element) => {
      state.recordings.push(element);
    });

    // Setup labels arrays
    for (let floor of floors) {
      let floor_numeric_id = urlsafeService.returnIdForType(
        floor.getFloorId(),
        "Floor"
      );
      state.floors_label.push({
        id: floor.getFloorId(),
        label: floor.getLabel(),
        numericId: floor_numeric_id,
      });
      for (let structure of floor.getStructuresList()) {
        let numeric_structure_id = urlsafeService.returnIdForType(
          structure.getBusiness().getStructureId(),
          "Structure"
        );
        state.structures_label.push({
          id: structure.getBusiness().getStructureId(),
          label: structure.getBusiness().getLabel(),
          numericId: numeric_structure_id,
        });
      }
    }

    state.recordings.forEach((floor) => {
      let label_floor = state.floors_label.find(
        (state_floor) =>
          parseInt(state_floor.numericId) == parseInt(floor.numericId)
      );
      if (label_floor) {
        floor.label = label_floor.label;
      }
      floor.structuresList.forEach((structure) => {
        let structure_floor = state.structures_label.find(
          (state_structure) =>
            parseInt(state_structure.numericId) == parseInt(structure.numericId)
        );
        if (structure_floor) {
          structure.label = structure_floor.label;
        }
      });
    });
  },
  //
  // RECORDING_GET_SUCCESS
  //
  [RECORDING_GET_SUCCESS](state, recording) {
    let numeric_floor_id = urlsafeService.returnIdForType(
      recording.id,
      "Floor"
    );
    let numeric_structure_id = urlsafeService.returnIdForType(
      recording.id,
      "Structure"
    );
    let state_floor = state.recordings.find(
      (state_recording) =>
        parseInt(state_recording.numericId) == parseInt(numeric_floor_id)
    );

    if (state_floor) {
      let state_structure = state_floor.structuresList.find(
        (state_struct) =>
          parseInt(state_struct.numericId) == parseInt(numeric_structure_id)
      );
      if (state_structure) {
        let index_to_modify = null;
        if (
          state_structure.recordingsList.some((struct, index) => {
            if (struct.sessionId === recording.sessionId) {
              index_to_modify = index;
              return true;
            }
            return false;
          })
        ) {
          Vue.set(state_structure.recordingsList, index_to_modify, recording);
        } else {
          // Append in list for new recording
          state_structure.recordingsList.push(recording);
        }
      }
    }
  },

  //
  // RECORDINGS_LIST_FOR_FLOOR_SUCCESS
  //
  [RECORDINGS_LIST_FOR_FLOOR_SUCCESS](state, floor_id) {
    state.floor_records = [];
    state.recordings.forEach((element) => {
      if (element.numericId == floor_id) {
        state.floor_records = element.structuresList;
      }
    });
  },
  //
  // RECORDINGS_LIST_FOR_STRUCTURE_SUCCESS
  //
  [RECORDINGS_LIST_FOR_STRUCTURE_SUCCESS](state, structure_id) {
    state.structure_records = [];
    state.floor_records.forEach((element) => {
      if (element.numericId == structure_id) {
        state.structure_records = element.recordingsList;
      }
    });
  },
  //
  // RECORDINGS_LIST_FOR_BUILDING_TITLE
  //
  [RECORDINGS_LIST_FOR_BUILDING_TITLE](state) {
    state.recording_title = "Building";
  },
  //
  // RECORDINGS_LIST_FOR_FLOOR_TITLE
  //
  [RECORDINGS_LIST_FOR_FLOOR_TITLE](state, floor_id) {
    const floor_label = state.floors_label.find(
      (floor) => floor.numericId == floor_id
    );
    if (floor_label) {
      state.recording_title = "Building > " + floor_label.label;
    } else {
      state.recording_title = "Building > " + floor_id;
    }
  },
  //
  // RECORDINGS_LIST_FOR_STRUCTURE_TITLE
  //
  [RECORDINGS_LIST_FOR_STRUCTURE_TITLE](state, { structure_id, floor_id }) {
    const floor_label = state.floors_label.find(
      (floor) => floor.numericId == floor_id
    );
    let label = "";
    if (floor_label) {
      label += "Building > " + floor_label.label;
    } else {
      label += "Building > " + " " + floor_id;
    }
    const structure_label = state.structures_label.find(
      (structure) => structure.numericId == structure_id
    );
    if (structure_label) {
      label += " > " + structure_label.label;
    } else {
      label += " > " + structure_id;
    }
    state.recording_title = label;
  },
};

export default {
  state,
  actions,
  mutations,
};
