// source: msh/resource/v1/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_common_v1_language_pb = require('../../../msh/common/v1/language_pb.js');
goog.object.extend(proto, msh_common_v1_language_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.msh.resource.v1.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.User.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.User.displayName = 'proto.msh.resource.v1.User';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.User.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lang: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contactTimeList: jspb.Message.toObjectList(msg.getContactTimeList(),
    msh_common_v1_common_pb.ContactTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.User;
  return proto.msh.resource.v1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 6:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 8:
      var value = /** @type {!proto.msh.common.v1.Language} */ (reader.readEnum());
      msg.setLang(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 10:
      var value = new msh_common_v1_common_pb.ContactTime;
      reader.readMessage(value,msh_common_v1_common_pb.ContactTime.deserializeBinaryFromReader);
      msg.addContactTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLang();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContactTimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      msh_common_v1_common_pb.ContactTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string photo_url = 4;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.User.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.User} returns this
*/
proto.msh.resource.v1.User.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.User.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 6;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.User.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 6));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.User} returns this
*/
proto.msh.resource.v1.User.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.User.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string phone = 7;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional msh.common.v1.Language lang = 8;
 * @return {!proto.msh.common.v1.Language}
 */
proto.msh.resource.v1.User.prototype.getLang = function() {
  return /** @type {!proto.msh.common.v1.Language} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.msh.common.v1.Language} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setLang = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string timezone = 9;
 * @return {string}
 */
proto.msh.resource.v1.User.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated msh.common.v1.ContactTime contact_time = 10;
 * @return {!Array<!proto.msh.common.v1.ContactTime>}
 */
proto.msh.resource.v1.User.prototype.getContactTimeList = function() {
  return /** @type{!Array<!proto.msh.common.v1.ContactTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_common_v1_common_pb.ContactTime, 10));
};


/**
 * @param {!Array<!proto.msh.common.v1.ContactTime>} value
 * @return {!proto.msh.resource.v1.User} returns this
*/
proto.msh.resource.v1.User.prototype.setContactTimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.msh.common.v1.ContactTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.common.v1.ContactTime}
 */
proto.msh.resource.v1.User.prototype.addContactTime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.msh.common.v1.ContactTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.User} returns this
 */
proto.msh.resource.v1.User.prototype.clearContactTimeList = function() {
  return this.setContactTimeList([]);
};


goog.object.extend(exports, proto.msh.resource.v1);
