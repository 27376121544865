// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { AUTH_LOGOUT_REQUEST, BREADCRUMB_SET_REQUEST } from "@/store/constants";
import { BUILDING_SET_CURRENTCONFIRMED, AUTH_LOGOUTCONFIRMED } from "@/events";
import { bus } from "@/main.js";
import i18n from "@/plugins/i18n";
import route from "color-convert/route";

var previous_to = "";
var logged_on = false;
var currentBuildingID = "";

export const registerRouterCallbacks = () => {
  // When we log in from the login page,
  // We need to wait until the current building
  // is set to redirect the user, because the building
  // ID is set in the path
  bus.$on(BUILDING_SET_CURRENTCONFIRMED, (buildingId) => {
    currentBuildingID = buildingId;
    if (
      !logged_on &&
      router.currentRoute.name &&
      (router.currentRoute.name === "Login" ||
        router.currentRoute.name === "wait" ||
        router.currentRoute.name === "Logout")
    ) {
      logged_on = true;

      // Wait is a dummy page, we don't want to go to that page
      if (previous_to && previous_to.name != "wait") {
        router.push(previous_to).catch(() => {});
        previous_to = "";
      } else {
        router.push({ name: "dashboard", params: { buildingId: buildingId } });
      }
    }
  });

  bus.$on(AUTH_LOGOUTCONFIRMED, (user) => {
    logged_on = false;
    router.push("/logout").catch(() => {});
  });
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  // Next to redirect to the page we came from
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  previous_to = to;
  logged_on = false;
  next("/login");
};

const logoutUser = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.dispatch(AUTH_LOGOUT_REQUEST);
  }
  next();
};

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: (to) => {
      if (currentBuildingID === "") {
        return {
          name: "wait",
        };
      } else {
        return {
          name: "dashboard",
          params: { buildingId: currentBuildingID },
        };
      }
    },
    component: () => import("@/views/layout/BusinessLayout.vue"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "/:buildingId/dashboard/",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
        meta: {
          breadCrumb(route) {
            return [
              {
                title: i18n.t("msh.aside.dashboard"),
              },
            ];
          },
        },
      },
      {
        path: "/:buildingId/floors/:structureId/chart/:chartName",
        name: "structureChartDetail",
        component: () => import("@/views/pages/structures/DetailChart.vue"),
      },
      {
        path: "/:buildingId/floors/:structureId/",
        name: "structureDetail",
        component: () => import("@/views/pages/structures/Detail.vue"),
      },
      {
        path: "/:buildingId/floors",
        name: "floors",
        component: () => import("@/views/pages/floors/List.vue"),
        meta: {
          breadCrumb(route) {
            return [
              {
                title: i18n.t("msh.aside.appartments"),
              },
            ];
          },
        },
      },
      {
        path: "/:buildingId/devices/provision",
        name: "provisionSteps",
        component: () => import("@/views/pages/devices/ProvisionSteps.vue"),
        meta: {
          breadCrumb(route) {
            return [
              {
                title: i18n.t("msh.aside.provision"),
              },
            ];
          },
        },
      },
      {
        path: "/:buildingId/devices",
        name: "listDevices",
        component: () => import("@/views/pages/devices/List.vue"),
        meta: {
          breadCrumb(route) {
            return [
              {
                title: i18n.t("msh.aside.devices"),
              },
            ];
          },
        },
      },
      {
        path: "/:buildingId/records",
        name: "recordsMain",
        component: () => import("@/views/pages/recordings/ListHeader.vue"),
        meta: {
          breadCrumb(route) {
            return [
              {
                title: i18n.t("msh.aside.recordings"),
              },
            ];
          },
        },
        children: [
          {
            path: "/:buildingId/records/:floorId/:structureId",
            name: "structureRecords",
            component: () => import("@/views/pages/recordings/Structure.vue"),
            meta: {
              breadCrumb(route) {
                return [
                  {
                    title: i18n.t("msh.aside.recordings"),
                  },
                ];
              },
            },
          },
          {
            path: "/:buildingId/records/:floorId",
            name: "floorRecords",
            component: () => import("@/views/pages/recordings/Floor.vue"),
            meta: {
              breadCrumb(route) {
                return [
                  {
                    title: i18n.t("msh.aside.recordings"),
                  },
                ];
              },
            },
          },
          {
            path: "/:buildingId/records/",
            name: "buildingRecords",
            component: () => import("@/views/pages/recordings/Building.vue"),
            meta: {
              breadCrumb(route) {
                return [
                  {
                    title: i18n.t("msh.aside.recordings"),
                  },
                ];
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/pages/auth/Login.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/pages/auth/Login.vue"),
    beforeEnter: logoutUser,
  },
  // "wait" is a dummy page where we can redirect
  // and wait until the current building is set.
  // The "BUILDING_SET_CURRENTCONFIRMED" event will redirect
  // us once the curren building is set.
  {
    path: "/wait",
    name: "wait",
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  routes,
});

router.afterEach((to, from) => {
  if (to.meta.breadCrumb) {
    store.dispatch(BREADCRUMB_SET_REQUEST, to.meta.breadCrumb.call(to));
  } else {
    store.dispatch(BREADCRUMB_SET_REQUEST, { title: "" });
  }
});

export default router;
