// English
export const locale = {
  msh: {
    login: {
      sideTitle: "Moonshot Health Portal",
      email: "Email",
      password: "Password",
      signIn: "Sign In",
      welcome: "Welcome",
      fixValidation: "Please, provide correct data",
    },
    forgot: {
      title: "Forgot Password?",
      desc: "Enter your email to reset your password",
      submit: "Submit",
      cancel: "Cancel",
      success: "Your account has been successfully reset.",
    },
    aside: {
      title: "Moonshot Health",
      dashboard: "Dashboard",
      appartments: "Appartments",
      devices: "Devices",
      recordings: "Recordings",
      provision: "Provision a new device",
    },
    user: {
      profile: "User Profile",
      signOut: "Sign out",
    },
    buildingSelector: {
      title: "All buildings",
    },
    appartments: {
      title: "Appartments",
      titleSingle: "Appartment {name}",
      timeline: "Timeline",
      devices: "Devices",
      lastEvents: "No events | One event | {count} events",
      withActivity: "With Activity",
      withoutActivity: "Without Activity",
      showingTotal: "No appartment | One appartment | {count} appartments",
      filter: "Filter",
      presence: "Presence",
      activityLevel: "Activity Level",
      extra: "...",
      name: "Name",
      showMore: "Show More",
      event: {
        out: "Out",
        new: "new",
        acknowledge: "acknowledged",
        noActivitySince: "no activity since {time}",
        fall: "Fall detected",
        generic: "Active alert",
      },
    },
    devices: {
      title: "Devices",
      titleSingle: "Device {name}",
      label: "Label",
      online: {
        title: "Online / Offline",
        true: "Online",
        false: "Offline",
      },
      recording: {
        status: "Recording status",
        startedOnNumberOfDevices:
          "Recording started on no device | Recording started on one device | Recording started on {count} devices",
        started: "Recording started",
        stop: "Stop",
        trigger: "Trigger",
        cancel: "Cancel",
        stopRecording: "Stop recording",
        triggerRecording: "Trigger recording",
        cancelRecording: "Cancel recording",
        label: {
          label: "Label for this recording",
          duration: "Duration (in minutes)",
        },
      },
      showingTotal: "No device | One device | {count} devices",
      withActivity: "With Activity",
      timeline: "Timeline",
      withoutActivity: "Without Activity",
      lastEvents: "No events | One event | {count} events",
      latestSensor: "Latest Sensor data",
      showMore: "Show More",
      provision: {
        findDevice: "Find device",
        findAppartment: "Find appartment",
      },
    },
    dashboard: {
      incidents: {
        title: "Incidents",
        number: "No Incident | One Incident | {count} Incidents",
        active: "Active",
        resolved: "Resolved",
        all: "All",
        titleSingle: "Incident {name}",
        responseTimeline: "Response timeline",
        historyRequest: "Last request sent to {name} at {time}",
        appartment: "Appartment",
        floor: "Floor",
      },
      charts: {
        present: "Present",
        absent: "Absent",
        total: "Total",
        online: "Online",
        offline: "Offline",
      },
      quickOverview: {
        presenceTitle: "Presence",
        presenceText:
          "All residents are present. | One resident is away from his apartment. | {count} residents are away from their apartment.",
        statusTitle: "Devices online",
        statusText:
          "All devices online. | One device is offline. | {count} devices are offline.",
        alertTitle: "Incidents",
        alertText:
          "No active incidents to report. | One active incident. | {count} active incidents.",
      },
    },
    notification: {
      title: "Notification | Notification | Notifications",
      recent: "Recent Notifications",
      number: "No new | One new | {count} news",
    },
    home: {
      title: "Home",
      attentionRequired: "Attention Required",
      tenants: "Tenants",
    },
    floor: {
      status: "Status",
      activityLevel: "Activity level",
      alert: "Alert",
      alertActive: "Fall",
      noActiveAlert: "No active alert",
      presence: {
        noPresence: "No presence recorded",
        title: "Presence",
        lastSeen: "Last seen",
        0: "Undefined",
        1: "Absent",
        2: "Present",
        3: "Unknown",
      },
      event: {
        out: "Out",
        new: "new",
        acknowledge: "acknowledged",
        noActivitySince: "no activity since {time}",
        fall: "Fall detected",
        generic: "Event",
        withAppt: {
          fall: "Fall detected in {appt}",
          presence: "Presence modified in {appt}",
          activity: "Activity level captured in {appt}",
          audioClassification: "",
          connectivity: "",
          heartRate: "",
          respirationRate: "",
          temperature: "Temperature captured in {appt}",
          pressure: "Pressure captured in {appt}",
          humidity: "Humidity level captured in {appt}",
          lightIntensity: "Light intensity captured in {appt}",
        },
        detail: {
          fall: "{type} fall detected by {source}",
          fallLevel: {
            0: "Soft",
            1: "Soft",
            2: "Hard",
          },
          fallSource: {
            radar: "radar",
            audio: "audio",
            accelerometer: "accelerometer",
          },
        },
      },
    },
    detail: {
      title: "Detail",
      alertTab: "Active alert",
      deviceTab: "Devices",
      timelineTab: "Timeline",
      alert: {
        fall: "Fall detected",
        numberOfUnits: "No unit | 1 unit | {count} units",
        noActiveAlert: "No active alert",
        eventTimeline: "Event timeline",
        addResponse: "Add info / Response",
        request: "A request for assistance was sent",
        ack: "{user} acknowledge this alert",
      },
      devices: {
        title: "Device | Device | Devices",
        device: "Device",
        noDevice: "No device registered for this appartment",
      },
      timeline: {
        noData: "No data received for this period",
        presenceDetected: "Presence detected",
        presenceNotDetected: "Absence",
        alertSent: "An alert was created",
        requestSent: "A request for assistance was sent to {name}",
        ackReceived: "The alert was acknowledge by  {name}",
        sinceYesterday: "Since yesterday",
        sinceLastWeek: "Since last week",
        sinceLastMonth: "Since last month",
        showMore: "Show more",
      },
    },
    date: {
      yesterday: "Yesterday",
      today: "Today",
    },
    error: {
      title: "404",
      desc: "OOPS! Something went wrong here",
    },
    validation: {
      format: {
        email: "The value is not a valid email address",
        password: "",
        nameUnderscore: "Name cannot contains an underscore",
        nameSpace: "Name cannot contains space",
        durationPositive: "Duration must be a positive number",
      },
      required: {
        email: "Email is required",
        password: "Password is required",
        name: "Name is required",
        duration: "Duration is required",
      },
    },
    recordings: {
      download: {
        download: "Download",
        ready: "Download ready",
      },
      history: "History",
      status: {
        0: "Unknown",
        1: "Recording",
        2: "Finished",
        3: "Cancelled",
        4: "In error",
        5: "Preparing upload",
        6: "Uploading",
        7: "Was already recording",
        8: "Start recording command sent",
        9: "Storage confirmed",
        10: "Storage deleted",
      },
    },
    loading: "Loading",
    search: "Search",
    close: "Close",
  },
  2: "You have entered an invalid email or password.",
  "auth/too-many-requests":
    "Access to this account has been temporarily disabled due to many failed login attempts. Try again later.",
  "auth/user-not-found": "Access not granted for this user.",
  "auth/wrong-password": "You have entered an invalid email or password.",
  "auth/account-exists-with-different-credential":
    "You have entered an invalid email or password.",
  "auth/invalid-credential": "You have entered an invalid email or password.",
  "auth/operation-not-allowed":
    "You have entered an invalid email or password.",
  "auth/user-disabled": "User disable, access revoked.",
  "auth/invalid-verification-code":
    "You have entered an invalid email or password.",
  "auth/invalid-verification-id":
    "You have entered an invalid email or password.",
  "auth/invalid-email": "You have entered an invalid email",
  "auth/network-request-failed":
    "Error, could not login. Network request failed",
};
