// source: msh/trait/actuator/v1/online_offline.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var msh_common_v1_common_pb = require('../../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_common_v1_capture_mode_pb = require('../../../../msh/common/v1/capture_mode_pb.js');
goog.object.extend(proto, msh_common_v1_capture_mode_pb);
goog.exportSymbol('proto.msh.trait.actuator.v1.OnlineOfflineTrait', null, global);
goog.exportSymbol('proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent', null, global);
goog.exportSymbol('proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.actuator.v1.OnlineOfflineTrait, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.displayName = 'proto.msh.trait.actuator.v1.OnlineOfflineTrait';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.displayName = 'proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.displayName = 'proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.toObject = function(includeInstance, msg) {
  var f, obj = {
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.actuator.v1.OnlineOfflineTrait;
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    trait: (f = msg.getTrait()) && proto.msh.trait.actuator.v1.OnlineOfflineTrait.toObject(includeInstance, f),
    agent: (f = msg.getAgent()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    captureMode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent;
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.msh.trait.actuator.v1.OnlineOfflineTrait;
      reader.readMessage(value,proto.msh.trait.actuator.v1.OnlineOfflineTrait.deserializeBinaryFromReader);
      msg.setTrait(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 4:
      var value = /** @type {!proto.msh.common.v1.CaptureMode} */ (reader.readEnum());
      msg.setCaptureMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTrait();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.msh.trait.actuator.v1.OnlineOfflineTrait.serializeBinaryToWriter
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCaptureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
*/
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnlineOfflineTrait trait = 2;
 * @return {?proto.msh.trait.actuator.v1.OnlineOfflineTrait}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.getTrait = function() {
  return /** @type{?proto.msh.trait.actuator.v1.OnlineOfflineTrait} */ (
    jspb.Message.getWrapperField(this, proto.msh.trait.actuator.v1.OnlineOfflineTrait, 2));
};


/**
 * @param {?proto.msh.trait.actuator.v1.OnlineOfflineTrait|undefined} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
*/
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.setTrait = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.clearTrait = function() {
  return this.setTrait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.hasTrait = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Agent agent = 3;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.getAgent = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 3));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
*/
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional msh.common.v1.CaptureMode capture_mode = 4;
 * @return {!proto.msh.common.v1.CaptureMode}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.getCaptureMode = function() {
  return /** @type {!proto.msh.common.v1.CaptureMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.msh.common.v1.CaptureMode} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent.prototype.setCaptureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.toObject = function(includeInstance, msg) {
  var f, obj = {
    trait: (f = msg.getTrait()) && proto.msh.trait.actuator.v1.OnlineOfflineTrait.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState;
  return proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.trait.actuator.v1.OnlineOfflineTrait;
      reader.readMessage(value,proto.msh.trait.actuator.v1.OnlineOfflineTrait.deserializeBinaryFromReader);
      msg.setTrait(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrait();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.msh.trait.actuator.v1.OnlineOfflineTrait.serializeBinaryToWriter
    );
  }
};


/**
 * optional OnlineOfflineTrait trait = 1;
 * @return {?proto.msh.trait.actuator.v1.OnlineOfflineTrait}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.getTrait = function() {
  return /** @type{?proto.msh.trait.actuator.v1.OnlineOfflineTrait} */ (
    jspb.Message.getWrapperField(this, proto.msh.trait.actuator.v1.OnlineOfflineTrait, 1));
};


/**
 * @param {?proto.msh.trait.actuator.v1.OnlineOfflineTrait|undefined} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState} returns this
*/
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.setTrait = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.clearTrait = function() {
  return this.setTrait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.SetState.prototype.hasTrait = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_online = 1;
 * @return {boolean}
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.trait.actuator.v1.OnlineOfflineTrait} returns this
 */
proto.msh.trait.actuator.v1.OnlineOfflineTrait.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.msh.trait.actuator.v1);
