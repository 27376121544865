// source: msh/trait/thermal/v1/thermal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait', null, global);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait.Command', null, global);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent', null, global);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel', null, global);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading', null, global);
goog.exportSymbol('proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.thermal.v1.ThermalTrait = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.thermal.v1.ThermalTrait, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.thermal.v1.ThermalTrait.displayName = 'proto.msh.trait.thermal.v1.ThermalTrait';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.displayName = 'proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.thermal.v1.ThermalTrait.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.thermal.v1.ThermalTrait.Command.displayName = 'proto.msh.trait.thermal.v1.ThermalTrait.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.displayName = 'proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.thermal.v1.ThermalTrait.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.thermal.v1.ThermalTrait.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait}
 */
proto.msh.trait.thermal.v1.ThermalTrait.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.thermal.v1.ThermalTrait;
  return proto.msh.trait.thermal.v1.ThermalTrait.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait}
 */
proto.msh.trait.thermal.v1.ThermalTrait.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.thermal.v1.ThermalTrait.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.thermal.v1.ThermalTrait.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel = {
  THERMAL_LEVEL_UNSPECIFIED: 0,
  THERMAL_LEVEL_NORMAL: 1,
  THERMAL_LEVEL_WARNING: 2,
  THERMAL_LEVEL_EXCEEDED: 3
};

/**
 * @enum {number}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit = {
  THERMAL_UNIT_UNSPECIFIED: 0,
  THERMAL_UNIT_CELSIUS: 1,
  THERMAL_UNIT_FAHRENHEIT: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    temperature: (f = msg.getTemperature()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    target: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitSystem: jspb.Message.getFieldWithDefault(msg, 3, 0),
    thermalLevel: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading;
  return proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit} */ (reader.readEnum());
      msg.setUnitSystem(value);
      break;
    case 4:
      var value = /** @type {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel} */ (reader.readEnum());
      msg.setThermalLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getThermalLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.FloatValue temperature = 1;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.getTemperature = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 1));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} returns this
*/
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} returns this
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string target = 2;
 * @return {string}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} returns this
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ThermalUnit unit_system = 3;
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.getUnitSystem = function() {
  return /** @type {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalUnit} value
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} returns this
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.setUnitSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ThermalLevel thermal_level = 4;
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.getThermalLevel = function() {
  return /** @type {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalLevel} value
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} returns this
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.prototype.setThermalLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.thermal.v1.ThermalTrait.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.Command}
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.thermal.v1.ThermalTrait.Command;
  return proto.msh.trait.thermal.v1.ThermalTrait.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.Command}
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.thermal.v1.ThermalTrait.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    thermalReading: (f = msg.getThermalReading()) && proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent;
  return proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading;
      reader.readMessage(value,proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.deserializeBinaryFromReader);
      msg.setThermalReading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThermalReading();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading.serializeBinaryToWriter
    );
  }
};


/**
 * optional ThermalReading thermal_reading = 2;
 * @return {?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.getThermalReading = function() {
  return /** @type{?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading} */ (
    jspb.Message.getWrapperField(this, proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading, 2));
};


/**
 * @param {?proto.msh.trait.thermal.v1.ThermalTrait.ThermalReading|undefined} value
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent} returns this
*/
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.setThermalReading = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent} returns this
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.clearThermalReading = function() {
  return this.setThermalReading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.trait.thermal.v1.ThermalTrait.ThermalChangedEvent.prototype.hasThermalReading = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.msh.trait.thermal.v1);
