/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js')

var msh_common_v1_language_pb = require('../../../msh/common/v1/language_pb.js')

var msh_resource_v1_alert_pb = require('../../../msh/resource/v1/alert_pb.js')
const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./alert_manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.AlertManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.AlertManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddAlertRequest,
 *   !proto.msh.service.v1.AddAlertResponse>}
 */
const methodDescriptor_AlertManager_AddAlert = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/AddAlert',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddAlertRequest,
  proto.msh.service.v1.AddAlertResponse,
  /**
   * @param {!proto.msh.service.v1.AddAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddAlertResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddAlertRequest,
 *   !proto.msh.service.v1.AddAlertResponse>}
 */
const methodInfo_AlertManager_AddAlert = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddAlertResponse,
  /**
   * @param {!proto.msh.service.v1.AddAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddAlertResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddAlertResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddAlertResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.addAlert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AddAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AddAlert,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddAlertResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.addAlert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AddAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AddAlert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AcknowledgeAlertRequest,
 *   !proto.msh.service.v1.AcknowledgeAlertResponse>}
 */
const methodDescriptor_AlertManager_AcknowledgeAlert = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/AcknowledgeAlert',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AcknowledgeAlertRequest,
  proto.msh.service.v1.AcknowledgeAlertResponse,
  /**
   * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AcknowledgeAlertResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AcknowledgeAlertRequest,
 *   !proto.msh.service.v1.AcknowledgeAlertResponse>}
 */
const methodInfo_AlertManager_AcknowledgeAlert = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AcknowledgeAlertResponse,
  /**
   * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AcknowledgeAlertResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AcknowledgeAlertResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AcknowledgeAlertResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.acknowledgeAlert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AcknowledgeAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AcknowledgeAlert,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AcknowledgeAlertResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.acknowledgeAlert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AcknowledgeAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AcknowledgeAlert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.IncreaseAlertLevelRequest,
 *   !proto.msh.service.v1.IncreaseAlertLevelResponse>}
 */
const methodDescriptor_AlertManager_IncreaseAlertLevel = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/IncreaseAlertLevel',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.IncreaseAlertLevelRequest,
  proto.msh.service.v1.IncreaseAlertLevelResponse,
  /**
   * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.IncreaseAlertLevelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.IncreaseAlertLevelRequest,
 *   !proto.msh.service.v1.IncreaseAlertLevelResponse>}
 */
const methodInfo_AlertManager_IncreaseAlertLevel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.IncreaseAlertLevelResponse,
  /**
   * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.IncreaseAlertLevelResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.IncreaseAlertLevelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.IncreaseAlertLevelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.increaseAlertLevel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/IncreaseAlertLevel',
      request,
      metadata || {},
      methodDescriptor_AlertManager_IncreaseAlertLevel,
      callback);
};


/**
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.IncreaseAlertLevelResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.increaseAlertLevel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/IncreaseAlertLevel',
      request,
      metadata || {},
      methodDescriptor_AlertManager_IncreaseAlertLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetAlertsRequest,
 *   !proto.msh.service.v1.GetAlertsResponse>}
 */
const methodDescriptor_AlertManager_GetAlerts = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/GetAlerts',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetAlertsRequest,
  proto.msh.service.v1.GetAlertsResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetAlertsRequest,
 *   !proto.msh.service.v1.GetAlertsResponse>}
 */
const methodInfo_AlertManager_GetAlerts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetAlertsResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetAlertsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetAlertsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.getAlerts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlerts',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlerts,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetAlertsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.getAlerts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlerts',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlerts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetAlertRequest,
 *   !proto.msh.service.v1.GetAlertResponse>}
 */
const methodDescriptor_AlertManager_GetAlert = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/GetAlert',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetAlertRequest,
  proto.msh.service.v1.GetAlertResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetAlertRequest,
 *   !proto.msh.service.v1.GetAlertResponse>}
 */
const methodInfo_AlertManager_GetAlert = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetAlertResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetAlertResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetAlertResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.getAlert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlert,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetAlertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetAlertResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.getAlert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlert',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetAlertByEventRequest,
 *   !proto.msh.service.v1.GetAlertByEventResponse>}
 */
const methodDescriptor_AlertManager_GetAlertByEvent = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/GetAlertByEvent',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetAlertByEventRequest,
  proto.msh.service.v1.GetAlertByEventResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertByEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertByEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetAlertByEventRequest,
 *   !proto.msh.service.v1.GetAlertByEventResponse>}
 */
const methodInfo_AlertManager_GetAlertByEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetAlertByEventResponse,
  /**
   * @param {!proto.msh.service.v1.GetAlertByEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAlertByEventResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetAlertByEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetAlertByEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetAlertByEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.getAlertByEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlertByEvent',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlertByEvent,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetAlertByEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetAlertByEventResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.getAlertByEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/GetAlertByEvent',
      request,
      metadata || {},
      methodDescriptor_AlertManager_GetAlertByEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AcknowledgeAlertByUserRequest,
 *   !proto.msh.service.v1.AcknowledgeAlertByUserResponse>}
 */
const methodDescriptor_AlertManager_AcknowledgeAlertByUser = new grpc.web.MethodDescriptor(
  '/msh.service.v1.AlertManager/AcknowledgeAlertByUser',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AcknowledgeAlertByUserRequest,
  proto.msh.service.v1.AcknowledgeAlertByUserResponse,
  /**
   * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AcknowledgeAlertByUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AcknowledgeAlertByUserRequest,
 *   !proto.msh.service.v1.AcknowledgeAlertByUserResponse>}
 */
const methodInfo_AlertManager_AcknowledgeAlertByUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AcknowledgeAlertByUserResponse,
  /**
   * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AcknowledgeAlertByUserResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AcknowledgeAlertByUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AcknowledgeAlertByUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.AlertManagerClient.prototype.acknowledgeAlertByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AcknowledgeAlertByUser',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AcknowledgeAlertByUser,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AcknowledgeAlertByUserResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.AlertManagerPromiseClient.prototype.acknowledgeAlertByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.AlertManager/AcknowledgeAlertByUser',
      request,
      metadata || {},
      methodDescriptor_AlertManager_AcknowledgeAlertByUser);
};


module.exports = proto.msh.service.v1;

