import refProto from "@/grpc/app_engine_key_pb";

const urlsafeService = {
  returnArrayKey(urlsafe) {
    const refKey = refProto.Reference.deserializeBinary(
      this.base64StringtoBytes(urlsafe)
    );
    let array = [];
    if (refKey.getPath()) {
      refKey
        .getPath()
        .getElementList()
        .forEach((element) => {
          array.push(element.getType());
          array.push(element.getId());
        });
    }

    return array;
  },

  /*
    Return requested type from urlsafe. If no type is found return null
    */
  returnIdForType(urlsafe, searchedType) {
    let response = null;
    const refKey = refProto.Reference.deserializeBinary(
      this.base64StringtoBytes(urlsafe)
    );
    if (refKey.getPath()) {
      refKey
        .getPath()
        .getElementList()
        .forEach((element) => {
          if (searchedType === element.getType()) {
            if (element.getId() != 0) {
              response = element.getId();
            } else if (element.getName() != "") {
              response = element.getName();
            }
            return null;
          }
        });
    }

    return response;
  },
  /**
   * Generate an urlsafe string key that can be used to request object from the backend
   *  * urlsafe = Original key that need to be trimed
   *  * typeToRemove = Array of string that countains type to be remove from key.
   * Do not compare urlsafe between them. Need to use the key compare in the backend or construct a Key/Reference and compare the Ids
   * Ex:
   * An urlsafe xnfb... that represent Account 1, Building 2, Floor 3, Structure 4, Alert 5 where we need to have an urlsafe of the structure we
   * call this function with the urlsafe and an array of only the "Structure" value
   */
  generateUrlsafeKey(urlsafe, typeToRemove) {
    let refKey = refProto.Reference.deserializeBinary(
      this.base64StringtoBytes(urlsafe)
    );
    if (refKey.getPath()) {
      refKey.getPath().setElementList(
        refKey
          .getPath()
          .getElementList()
          .filter((element) => {
            if (typeToRemove.includes(element.getType())) {
              return false;
            }
            return true;
          })
      );
      return window.btoa(
        String.fromCharCode(...new Uint8Array(refKey.serializeBinary()))
      );
    }
    return "";
  },
  /**
   * Decode an urlsafe to a Uint8Array required for deserialization
   */
  base64StringtoBytes(b64Data) {
    // supposed urlsafe base64 decode (src : https://stackoverflow.com/a/44528376/218703)
    // const byteCharacters = atob(b64Data.replace(/_/g, '/').replace(/-/g, '+'));
    const byteCharacters = window.atob(
      b64Data.replace(/_/g, "/").replace(/-/g, "/").replace(/\+/g, "/")
    );
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return byteArray;
  },
};

export default urlsafeService;
