// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import { AUTH_LOGINCONFIRMED, AUTH_LOGOUTCONFIRMED } from "@/events";
import { PROFILE_SET_REQUEST, PROFILE_SET_SUCCESS } from "@/store/constants";
import { bus } from "@/main.js";
import store from "@/store";

// Functions to respond to events
export const registerProfileCallbacks = () => {
  bus.$on(AUTH_LOGINCONFIRMED, (user) => {
    store.dispatch(PROFILE_SET_REQUEST, {
      name: user.displayName ? user.displayName : user.email,
      email: user.email,
    });
  });

  bus.$on(AUTH_LOGOUTCONFIRMED, (user) => {
    store.dispatch(PROFILE_SET_REQUEST, {
      name: "",
      email: "",
    });
  });
};

const state = {
  profile: {
    name: "",
    email: "",
  },
};

const actions = {
  [PROFILE_SET_REQUEST](context, payload) {
    context.commit(PROFILE_SET_SUCCESS, payload);
  },
};

const mutations = {
  [PROFILE_SET_SUCCESS](state, user_personal_info) {
    state.profile = user_personal_info;
  },
};

export default {
  state,
  actions,
  mutations,
};
