import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/locales/en";
// import { locale as ch } from "@/core/config/i18n/ch";
// import { locale as es } from "@/core/config/i18n/es";
// import { locale as jp } from "@/core/config/i18n/jp";
// import { locale as de } from "@/core/config/i18n/de";
import { locale as fr } from "@/locales/fr";

Vue.use(VueI18n);

const datetimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNoTime: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNoTime: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
};

let messages = {};
messages = { ...messages, en, fr };

// get current selected language
const lang = localStorage.getItem("language") || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  dateTimeFormats: datetimeFormats,
});

export default i18n;
