// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
const ID_TOKEN_KEY = "id_token";
const ID_TOKEN_EXPIRATION_TIME = "id_token_expiration";
const ID_TOKEN_CLAIM_ACCOUNT = "id_token_account";
const ID_TOKEN_CLAIM_MANAGER = "id_token_manager";
const ID_TOKEN_CLAIM_BUSINESS = "id_token_business";
const ID_TOKEN_USER_ID = "id_token_uid";
const ID_TOKEN_EMAIL = "id_token_email";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getExpirationTime = () => {
  return window.localStorage.getItem(ID_TOKEN_EXPIRATION_TIME);
};

/**
 * Return the url safe account id received in the custom claim for the token saved.
 */
export const getAccountId = () => {
  return window.localStorage.getItem(ID_TOKEN_CLAIM_ACCOUNT);
};

export const setAccountId = (accountId) => {
  window.localStorage.setItem(ID_TOKEN_CLAIM_ACCOUNT, accountId);
};

export const getUserId = () => {
  return window.localStorage.getItem(ID_TOKEN_USER_ID);
};

export const setUserId = (userId) => {
  window.localStorage.setItem(ID_TOKEN_USER_ID, userId);
};

export const getEmail = () => {
  return window.localStorage.getItem(ID_TOKEN_EMAIL);
};

export const setEmail = (email) => {
  window.localStorage.setItem(ID_TOKEN_EMAIL, email);
};

export const isManager = () => {
  return window.localStorage.getItem(ID_TOKEN_CLAIM_MANAGER);
};

/**
 * Save the result received from the getIdTokenResult function of Firebase
 * @param {IdTokenResult} result
 * @see https://firebase.google.com/docs/reference/js/firebase.auth.IDTokenResult
 */
export const saveToken = (result) => {
  window.localStorage.setItem(ID_TOKEN_KEY, result.token);
  window.localStorage.setItem(ID_TOKEN_EXPIRATION_TIME, result.expirationTime);
  // The claims has the AccountID in the ID form. We use the setAccountId to set the urlsafe account id.
  // window.localStorage.setItem(ID_TOKEN_CLAIM_ACCOUNT, btoa(result.claims.accounts))
  if (result.claims.manager) {
    window.localStorage.setItem(ID_TOKEN_CLAIM_MANAGER, "true");
  } else {
    window.localStorage.setItem(ID_TOKEN_CLAIM_MANAGER, "false");
  }
  if (result.claims.manager) {
    window.localStorage.setItem(ID_TOKEN_CLAIM_BUSINESS, "true");
  } else {
    window.localStorage.setItem(ID_TOKEN_CLAIM_BUSINESS, "false");
  }
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_EXPIRATION_TIME);
  window.localStorage.removeItem(ID_TOKEN_CLAIM_ACCOUNT);
  window.localStorage.removeItem(ID_TOKEN_CLAIM_MANAGER);
  window.localStorage.removeItem(ID_TOKEN_CLAIM_BUSINESS);
  window.localStorage.removeItem(ID_TOKEN_USER_ID);
  window.localStorage.removeItem(ID_TOKEN_EMAIL);
  window.localStorage.removeItem("currentBuildingId");
};

/**
 * Check that a token is present with an expiration time. This only means that
 * the current idToken is valid. We can use the refresh token if the idToken is expired.
 */
export const isTokenValid = () => {
  const expirationTime = getExpirationTime();
  if (expirationTime) {
    if (new Date(expirationTime) < Date.now()) {
      // expired
      return false;
    } else {
      // still valid
      return true;
    }
  }
  // no time set assuming it's expired
  return false;
};

export default {
  getToken,
  getAccountId,
  setAccountId,
  saveToken,
  destroyToken,
  isTokenValid,
  getUserId,
  setUserId,
  setEmail,
  getEmail,
  isManager,
};
