/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var msh_common_v1_address_pb = require('../../../msh/common/v1/address_pb.js')

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js')

var msh_resource_v1_alert_pb = require('../../../msh/resource/v1/alert_pb.js')

var msh_resource_v1_event_pb = require('../../../msh/resource/v1/event_pb.js')

var msh_resource_v1_structure_pb = require('../../../msh/resource/v1/structure_pb.js')

var msh_trait_activities_v1_activity_level_pb = require('../../../msh/trait/activities/v1/activity_level_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./structure_manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.BuildingManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.BuildingManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.CreateBuildingRequest,
 *   !proto.msh.service.v1.CreateBuildingResponse>}
 */
const methodDescriptor_BuildingManager_CreateBuilding = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/CreateBuilding',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.CreateBuildingRequest,
  proto.msh.service.v1.CreateBuildingResponse,
  /**
   * @param {!proto.msh.service.v1.CreateBuildingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateBuildingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.CreateBuildingRequest,
 *   !proto.msh.service.v1.CreateBuildingResponse>}
 */
const methodInfo_BuildingManager_CreateBuilding = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.CreateBuildingResponse,
  /**
   * @param {!proto.msh.service.v1.CreateBuildingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateBuildingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.CreateBuildingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.CreateBuildingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.CreateBuildingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.createBuilding =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/CreateBuilding',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_CreateBuilding,
      callback);
};


/**
 * @param {!proto.msh.service.v1.CreateBuildingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.CreateBuildingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.createBuilding =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/CreateBuilding',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_CreateBuilding);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetBuildingsRequest,
 *   !proto.msh.service.v1.GetBuildingsResponse>}
 */
const methodDescriptor_BuildingManager_GetBuildings = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/GetBuildings',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetBuildingsRequest,
  proto.msh.service.v1.GetBuildingsResponse,
  /**
   * @param {!proto.msh.service.v1.GetBuildingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetBuildingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetBuildingsRequest,
 *   !proto.msh.service.v1.GetBuildingsResponse>}
 */
const methodInfo_BuildingManager_GetBuildings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetBuildingsResponse,
  /**
   * @param {!proto.msh.service.v1.GetBuildingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetBuildingsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetBuildingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetBuildingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetBuildingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.getBuildings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetBuildings',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetBuildings,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetBuildingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetBuildingsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.getBuildings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetBuildings',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetBuildings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddFloorRequest,
 *   !proto.msh.service.v1.AddFloorResponse>}
 */
const methodDescriptor_BuildingManager_AddFloor = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/AddFloor',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddFloorRequest,
  proto.msh.service.v1.AddFloorResponse,
  /**
   * @param {!proto.msh.service.v1.AddFloorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddFloorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddFloorRequest,
 *   !proto.msh.service.v1.AddFloorResponse>}
 */
const methodInfo_BuildingManager_AddFloor = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddFloorResponse,
  /**
   * @param {!proto.msh.service.v1.AddFloorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddFloorResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddFloorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddFloorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddFloorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.addFloor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/AddFloor',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_AddFloor,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddFloorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddFloorResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.addFloor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/AddFloor',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_AddFloor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetFloorsRequest,
 *   !proto.msh.service.v1.GetFloorsResponse>}
 */
const methodDescriptor_BuildingManager_GetFloors = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/GetFloors',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetFloorsRequest,
  proto.msh.service.v1.GetFloorsResponse,
  /**
   * @param {!proto.msh.service.v1.GetFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetFloorsRequest,
 *   !proto.msh.service.v1.GetFloorsResponse>}
 */
const methodInfo_BuildingManager_GetFloors = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetFloorsResponse,
  /**
   * @param {!proto.msh.service.v1.GetFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.getFloors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetFloors',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetFloors,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.getFloors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetFloors',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetFloors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddContactRequest,
 *   !proto.msh.service.v1.AddContactResponse>}
 */
const methodDescriptor_BuildingManager_AddContact = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/AddContact',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddContactRequest,
  proto.msh.service.v1.AddContactResponse,
  /**
   * @param {!proto.msh.service.v1.AddContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddContactResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddContactRequest,
 *   !proto.msh.service.v1.AddContactResponse>}
 */
const methodInfo_BuildingManager_AddContact = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddContactResponse,
  /**
   * @param {!proto.msh.service.v1.AddContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddContactResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.addContact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/AddContact',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_AddContact,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddContactResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.addContact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/AddContact',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_AddContact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetDeviceOnlineCountRequest,
 *   !proto.msh.service.v1.GetDeviceOnlineCountResponse>}
 */
const methodDescriptor_BuildingManager_GetDeviceOnlineCount = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/GetDeviceOnlineCount',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetDeviceOnlineCountRequest,
  proto.msh.service.v1.GetDeviceOnlineCountResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceOnlineCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetDeviceOnlineCountRequest,
 *   !proto.msh.service.v1.GetDeviceOnlineCountResponse>}
 */
const methodInfo_BuildingManager_GetDeviceOnlineCount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetDeviceOnlineCountResponse,
  /**
   * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetDeviceOnlineCountResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetDeviceOnlineCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetDeviceOnlineCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.getDeviceOnlineCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetDeviceOnlineCount',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetDeviceOnlineCount,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetDeviceOnlineCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetDeviceOnlineCountResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.getDeviceOnlineCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetDeviceOnlineCount',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetDeviceOnlineCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetBuildingAlertsRequest,
 *   !proto.msh.service.v1.GetBuildingAlertsResponse>}
 */
const methodDescriptor_BuildingManager_GetBuildingAlerts = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/GetBuildingAlerts',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetBuildingAlertsRequest,
  proto.msh.service.v1.GetBuildingAlertsResponse,
  /**
   * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetBuildingAlertsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetBuildingAlertsRequest,
 *   !proto.msh.service.v1.GetBuildingAlertsResponse>}
 */
const methodInfo_BuildingManager_GetBuildingAlerts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetBuildingAlertsResponse,
  /**
   * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetBuildingAlertsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetBuildingAlertsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetBuildingAlertsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.getBuildingAlerts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetBuildingAlerts',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetBuildingAlerts,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetBuildingAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetBuildingAlertsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.getBuildingAlerts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetBuildingAlerts',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetBuildingAlerts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetADLBuildingEventsRequest,
 *   !proto.msh.service.v1.GetADLBuildingEventsResponse>}
 */
const methodDescriptor_BuildingManager_GetADLBuildingEvents = new grpc.web.MethodDescriptor(
  '/msh.service.v1.BuildingManager/GetADLBuildingEvents',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetADLBuildingEventsRequest,
  proto.msh.service.v1.GetADLBuildingEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLBuildingEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetADLBuildingEventsRequest,
 *   !proto.msh.service.v1.GetADLBuildingEventsResponse>}
 */
const methodInfo_BuildingManager_GetADLBuildingEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetADLBuildingEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLBuildingEventsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetADLBuildingEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetADLBuildingEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.BuildingManagerClient.prototype.getADLBuildingEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetADLBuildingEvents',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetADLBuildingEvents,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetADLBuildingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetADLBuildingEventsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.BuildingManagerPromiseClient.prototype.getADLBuildingEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.BuildingManager/GetADLBuildingEvents',
      request,
      metadata || {},
      methodDescriptor_BuildingManager_GetADLBuildingEvents);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.StructureManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.StructureManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.CreateStructureRequest,
 *   !proto.msh.service.v1.CreateStructureResponse>}
 */
const methodDescriptor_StructureManager_CreateStructure = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/CreateStructure',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.CreateStructureRequest,
  proto.msh.service.v1.CreateStructureResponse,
  /**
   * @param {!proto.msh.service.v1.CreateStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateStructureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.CreateStructureRequest,
 *   !proto.msh.service.v1.CreateStructureResponse>}
 */
const methodInfo_StructureManager_CreateStructure = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.CreateStructureResponse,
  /**
   * @param {!proto.msh.service.v1.CreateStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateStructureResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.CreateStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.CreateStructureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.CreateStructureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.createStructure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/CreateStructure',
      request,
      metadata || {},
      methodDescriptor_StructureManager_CreateStructure,
      callback);
};


/**
 * @param {!proto.msh.service.v1.CreateStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.CreateStructureResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.createStructure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/CreateStructure',
      request,
      metadata || {},
      methodDescriptor_StructureManager_CreateStructure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetStructuresRequest,
 *   !proto.msh.service.v1.GetStructuresResponse>}
 */
const methodDescriptor_StructureManager_GetStructures = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetStructures',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetStructuresRequest,
  proto.msh.service.v1.GetStructuresResponse,
  /**
   * @param {!proto.msh.service.v1.GetStructuresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetStructuresResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetStructuresRequest,
 *   !proto.msh.service.v1.GetStructuresResponse>}
 */
const methodInfo_StructureManager_GetStructures = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetStructuresResponse,
  /**
   * @param {!proto.msh.service.v1.GetStructuresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetStructuresResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetStructuresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetStructuresResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetStructuresResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getStructures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetStructures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetStructures,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetStructuresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetStructuresResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getStructures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetStructures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetStructures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.UpdateStructureRequest,
 *   !proto.msh.service.v1.UpdateStructureResponse>}
 */
const methodDescriptor_StructureManager_UpdateStructure = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/UpdateStructure',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.UpdateStructureRequest,
  proto.msh.service.v1.UpdateStructureResponse,
  /**
   * @param {!proto.msh.service.v1.UpdateStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UpdateStructureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.UpdateStructureRequest,
 *   !proto.msh.service.v1.UpdateStructureResponse>}
 */
const methodInfo_StructureManager_UpdateStructure = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.UpdateStructureResponse,
  /**
   * @param {!proto.msh.service.v1.UpdateStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.UpdateStructureResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.UpdateStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.UpdateStructureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.UpdateStructureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.updateStructure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/UpdateStructure',
      request,
      metadata || {},
      methodDescriptor_StructureManager_UpdateStructure,
      callback);
};


/**
 * @param {!proto.msh.service.v1.UpdateStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.UpdateStructureResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.updateStructure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/UpdateStructure',
      request,
      metadata || {},
      methodDescriptor_StructureManager_UpdateStructure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddRoomRequest,
 *   !proto.msh.service.v1.AddRoomResponse>}
 */
const methodDescriptor_StructureManager_AddRoom = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/AddRoom',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddRoomRequest,
  proto.msh.service.v1.AddRoomResponse,
  /**
   * @param {!proto.msh.service.v1.AddRoomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddRoomResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddRoomRequest,
 *   !proto.msh.service.v1.AddRoomResponse>}
 */
const methodInfo_StructureManager_AddRoom = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddRoomResponse,
  /**
   * @param {!proto.msh.service.v1.AddRoomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddRoomResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddRoomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddRoomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.addRoom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/AddRoom',
      request,
      metadata || {},
      methodDescriptor_StructureManager_AddRoom,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddRoomResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.addRoom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/AddRoom',
      request,
      metadata || {},
      methodDescriptor_StructureManager_AddRoom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetRoomsRequest,
 *   !proto.msh.service.v1.GetRoomsResponse>}
 */
const methodDescriptor_StructureManager_GetRooms = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetRooms',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetRoomsRequest,
  proto.msh.service.v1.GetRoomsResponse,
  /**
   * @param {!proto.msh.service.v1.GetRoomsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRoomsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetRoomsRequest,
 *   !proto.msh.service.v1.GetRoomsResponse>}
 */
const methodInfo_StructureManager_GetRooms = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetRoomsResponse,
  /**
   * @param {!proto.msh.service.v1.GetRoomsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRoomsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetRoomsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetRoomsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetRoomsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getRooms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetRooms',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetRooms,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetRoomsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetRoomsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getRooms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetRooms',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetRooms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetADLStructureEventsRequest,
 *   !proto.msh.service.v1.GetADLStructureEventsResponse>}
 */
const methodDescriptor_StructureManager_GetADLStructureEvents = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetADLStructureEvents',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetADLStructureEventsRequest,
  proto.msh.service.v1.GetADLStructureEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLStructureEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetADLStructureEventsRequest,
 *   !proto.msh.service.v1.GetADLStructureEventsResponse>}
 */
const methodInfo_StructureManager_GetADLStructureEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetADLStructureEventsResponse,
  /**
   * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetADLStructureEventsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetADLStructureEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetADLStructureEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getADLStructureEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetADLStructureEvents',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetADLStructureEvents,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetADLStructureEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetADLStructureEventsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getADLStructureEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetADLStructureEvents',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetADLStructureEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendStartRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendStartRecordingStructureCommandResponse>}
 */
const methodDescriptor_StructureManager_SendStartRecordingStructureCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/SendStartRecordingStructureCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendStartRecordingStructureCommandRequest,
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendStartRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendStartRecordingStructureCommandResponse>}
 */
const methodInfo_StructureManager_SendStartRecordingStructureCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStartRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendStartRecordingStructureCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendStartRecordingStructureCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.sendStartRecordingStructureCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendStartRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendStartRecordingStructureCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendStartRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendStartRecordingStructureCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.sendStartRecordingStructureCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendStartRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendStartRecordingStructureCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendStopRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendStopRecordingStructureCommandResponse>}
 */
const methodDescriptor_StructureManager_SendStopRecordingStructureCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/SendStopRecordingStructureCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendStopRecordingStructureCommandRequest,
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendStopRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendStopRecordingStructureCommandResponse>}
 */
const methodInfo_StructureManager_SendStopRecordingStructureCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendStopRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendStopRecordingStructureCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendStopRecordingStructureCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.sendStopRecordingStructureCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendStopRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendStopRecordingStructureCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendStopRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendStopRecordingStructureCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.sendStopRecordingStructureCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendStopRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendStopRecordingStructureCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.SendCancelRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendCancelRecordingStructureCommandResponse>}
 */
const methodDescriptor_StructureManager_SendCancelRecordingStructureCommand = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/SendCancelRecordingStructureCommand',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.SendCancelRecordingStructureCommandRequest,
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.SendCancelRecordingStructureCommandRequest,
 *   !proto.msh.service.v1.SendCancelRecordingStructureCommandResponse>}
 */
const methodInfo_StructureManager_SendCancelRecordingStructureCommand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse,
  /**
   * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.SendCancelRecordingStructureCommandResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.SendCancelRecordingStructureCommandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.sendCancelRecordingStructureCommand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendCancelRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendCancelRecordingStructureCommand,
      callback);
};


/**
 * @param {!proto.msh.service.v1.SendCancelRecordingStructureCommandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.SendCancelRecordingStructureCommandResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.sendCancelRecordingStructureCommand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/SendCancelRecordingStructureCommand',
      request,
      metadata || {},
      methodDescriptor_StructureManager_SendCancelRecordingStructureCommand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetActivityLevelHistoryRequest,
 *   !proto.msh.service.v1.GetActivityLevelHistoryResponse>}
 */
const methodDescriptor_StructureManager_GetActivityLevelHistory = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetActivityLevelHistory',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetActivityLevelHistoryRequest,
  proto.msh.service.v1.GetActivityLevelHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetActivityLevelHistoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetActivityLevelHistoryRequest,
 *   !proto.msh.service.v1.GetActivityLevelHistoryResponse>}
 */
const methodInfo_StructureManager_GetActivityLevelHistory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetActivityLevelHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetActivityLevelHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetActivityLevelHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetActivityLevelHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getActivityLevelHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetActivityLevelHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetActivityLevelHistory,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetActivityLevelHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetActivityLevelHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getActivityLevelHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetActivityLevelHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetActivityLevelHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetRespirationHistoryRequest,
 *   !proto.msh.service.v1.GetRespirationHistoryResponse>}
 */
const methodDescriptor_StructureManager_GetRespirationHistory = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetRespirationHistory',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetRespirationHistoryRequest,
  proto.msh.service.v1.GetRespirationHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRespirationHistoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetRespirationHistoryRequest,
 *   !proto.msh.service.v1.GetRespirationHistoryResponse>}
 */
const methodInfo_StructureManager_GetRespirationHistory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetRespirationHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRespirationHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetRespirationHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetRespirationHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getRespirationHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetRespirationHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetRespirationHistory,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetRespirationHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetRespirationHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getRespirationHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetRespirationHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetRespirationHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetHRHistoryRequest,
 *   !proto.msh.service.v1.GetHRHistoryResponse>}
 */
const methodDescriptor_StructureManager_GetHRHistory = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetHRHistory',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetHRHistoryRequest,
  proto.msh.service.v1.GetHRHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetHRHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetHRHistoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetHRHistoryRequest,
 *   !proto.msh.service.v1.GetHRHistoryResponse>}
 */
const methodInfo_StructureManager_GetHRHistory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetHRHistoryResponse,
  /**
   * @param {!proto.msh.service.v1.GetHRHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetHRHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetHRHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetHRHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetHRHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getHRHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetHRHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetHRHistory,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetHRHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetHRHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getHRHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetHRHistory',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetHRHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetTemperaturesRequest,
 *   !proto.msh.service.v1.GetTemperaturesResponse>}
 */
const methodDescriptor_StructureManager_GetTemperatures = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetTemperatures',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetTemperaturesRequest,
  proto.msh.service.v1.GetTemperaturesResponse,
  /**
   * @param {!proto.msh.service.v1.GetTemperaturesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetTemperaturesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetTemperaturesRequest,
 *   !proto.msh.service.v1.GetTemperaturesResponse>}
 */
const methodInfo_StructureManager_GetTemperatures = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetTemperaturesResponse,
  /**
   * @param {!proto.msh.service.v1.GetTemperaturesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetTemperaturesResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetTemperaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetTemperaturesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetTemperaturesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getTemperatures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetTemperatures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetTemperatures,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetTemperaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetTemperaturesResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getTemperatures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetTemperatures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetTemperatures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetHumiditiesRequest,
 *   !proto.msh.service.v1.GetHumiditiesResponse>}
 */
const methodDescriptor_StructureManager_GetHumidities = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetHumidities',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetHumiditiesRequest,
  proto.msh.service.v1.GetHumiditiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetHumiditiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetHumiditiesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetHumiditiesRequest,
 *   !proto.msh.service.v1.GetHumiditiesResponse>}
 */
const methodInfo_StructureManager_GetHumidities = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetHumiditiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetHumiditiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetHumiditiesResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetHumiditiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetHumiditiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetHumiditiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getHumidities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetHumidities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetHumidities,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetHumiditiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetHumiditiesResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getHumidities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetHumidities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetHumidities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetPressuresRequest,
 *   !proto.msh.service.v1.GetPressuresResponse>}
 */
const methodDescriptor_StructureManager_GetPressures = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetPressures',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetPressuresRequest,
  proto.msh.service.v1.GetPressuresResponse,
  /**
   * @param {!proto.msh.service.v1.GetPressuresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetPressuresResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetPressuresRequest,
 *   !proto.msh.service.v1.GetPressuresResponse>}
 */
const methodInfo_StructureManager_GetPressures = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetPressuresResponse,
  /**
   * @param {!proto.msh.service.v1.GetPressuresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetPressuresResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetPressuresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetPressuresResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetPressuresResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getPressures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetPressures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetPressures,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetPressuresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetPressuresResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getPressures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetPressures',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetPressures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetAirQualitiesRequest,
 *   !proto.msh.service.v1.GetAirQualitiesResponse>}
 */
const methodDescriptor_StructureManager_GetAirQualities = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetAirQualities',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetAirQualitiesRequest,
  proto.msh.service.v1.GetAirQualitiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetAirQualitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAirQualitiesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetAirQualitiesRequest,
 *   !proto.msh.service.v1.GetAirQualitiesResponse>}
 */
const methodInfo_StructureManager_GetAirQualities = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetAirQualitiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetAirQualitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetAirQualitiesResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetAirQualitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetAirQualitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetAirQualitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getAirQualities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetAirQualities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetAirQualities,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetAirQualitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetAirQualitiesResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getAirQualities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetAirQualities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetAirQualities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetLightIntensitiesRequest,
 *   !proto.msh.service.v1.GetLightIntensitiesResponse>}
 */
const methodDescriptor_StructureManager_GetLightIntensities = new grpc.web.MethodDescriptor(
  '/msh.service.v1.StructureManager/GetLightIntensities',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetLightIntensitiesRequest,
  proto.msh.service.v1.GetLightIntensitiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetLightIntensitiesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetLightIntensitiesRequest,
 *   !proto.msh.service.v1.GetLightIntensitiesResponse>}
 */
const methodInfo_StructureManager_GetLightIntensities = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetLightIntensitiesResponse,
  /**
   * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetLightIntensitiesResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetLightIntensitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetLightIntensitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.StructureManagerClient.prototype.getLightIntensities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetLightIntensities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetLightIntensities,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetLightIntensitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetLightIntensitiesResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.StructureManagerPromiseClient.prototype.getLightIntensities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.StructureManager/GetLightIntensities',
      request,
      metadata || {},
      methodDescriptor_StructureManager_GetLightIntensities);
};


module.exports = proto.msh.service.v1;

