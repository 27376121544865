// source: msh/resource/v1/event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_trait_activities_v1_activity_level_pb = require('../../../msh/trait/activities/v1/activity_level_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_activity_level_pb);
var msh_trait_activities_v1_audio_classification_pb = require('../../../msh/trait/activities/v1/audio_classification_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_audio_classification_pb);
var msh_trait_activities_v1_fall_pb = require('../../../msh/trait/activities/v1/fall_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_fall_pb);
var msh_trait_activities_v1_presence_pb = require('../../../msh/trait/activities/v1/presence_pb.js');
goog.object.extend(proto, msh_trait_activities_v1_presence_pb);
var msh_trait_telemetry_v1_distance_pb = require('../../../msh/trait/telemetry/v1/distance_pb.js');
goog.object.extend(proto, msh_trait_telemetry_v1_distance_pb);
var msh_trait_telemetry_v1_heart_rate_pb = require('../../../msh/trait/telemetry/v1/heart_rate_pb.js');
goog.object.extend(proto, msh_trait_telemetry_v1_heart_rate_pb);
var msh_trait_telemetry_v1_respiration_pb = require('../../../msh/trait/telemetry/v1/respiration_pb.js');
goog.object.extend(proto, msh_trait_telemetry_v1_respiration_pb);
var msh_trait_actuator_v1_online_offline_pb = require('../../../msh/trait/actuator/v1/online_offline_pb.js');
goog.object.extend(proto, msh_trait_actuator_v1_online_offline_pb);
var msh_trait_sensor_v1_air_quality_pb = require('../../../msh/trait/sensor/v1/air_quality_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_air_quality_pb);
var msh_trait_sensor_v1_temperature_pb = require('../../../msh/trait/sensor/v1/temperature_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_temperature_pb);
var msh_trait_sensor_v1_humidity_pb = require('../../../msh/trait/sensor/v1/humidity_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_humidity_pb);
var msh_trait_sensor_v1_light_pb = require('../../../msh/trait/sensor/v1/light_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_light_pb);
var msh_trait_sensor_v1_pressure_pb = require('../../../msh/trait/sensor/v1/pressure_pb.js');
goog.object.extend(proto, msh_trait_sensor_v1_pressure_pb);
goog.exportSymbol('proto.msh.resource.v1.Event', null, global);
goog.exportSymbol('proto.msh.resource.v1.Event.TypeCase', null, global);
goog.exportSymbol('proto.msh.resource.v1.EventFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.msh.resource.v1.Event.oneofGroups_);
};
goog.inherits(proto.msh.resource.v1.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Event.displayName = 'proto.msh.resource.v1.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.EventFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.EventFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.EventFilter.displayName = 'proto.msh.resource.v1.EventFilter';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.msh.resource.v1.Event.oneofGroups_ = [[1,2,3,4,5,6,7,14,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.msh.resource.v1.Event.TypeCase = {
  TYPE_NOT_SET: 0,
  ACTIVITY_LEVEL: 1,
  FALL: 2,
  PRESENCE: 3,
  HEART_RATE: 4,
  RESPIRATION_RATE: 5,
  DISTANCE: 6,
  CONNECTIVITY: 7,
  AUDIO_CLASSIFICATION: 14,
  TEMPERATURE: 9,
  HUMIDITY: 10,
  PRESSURE: 11,
  LIGHT_INTENSITY: 12,
  AIR_QUALITY: 13
};

/**
 * @return {proto.msh.resource.v1.Event.TypeCase}
 */
proto.msh.resource.v1.Event.prototype.getTypeCase = function() {
  return /** @type {proto.msh.resource.v1.Event.TypeCase} */(jspb.Message.computeOneofCase(this, proto.msh.resource.v1.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activityLevel: (f = msg.getActivityLevel()) && msh_trait_activities_v1_activity_level_pb.ActivityLevelTrait.ActivityLevelEvent.toObject(includeInstance, f),
    fall: (f = msg.getFall()) && msh_trait_activities_v1_fall_pb.FallTrait.FallEvent.toObject(includeInstance, f),
    presence: (f = msg.getPresence()) && msh_trait_activities_v1_presence_pb.PresenceTrait.PresenceEvent.toObject(includeInstance, f),
    heartRate: (f = msg.getHeartRate()) && msh_trait_telemetry_v1_heart_rate_pb.HeartRateTrait.HeartRateEvent.toObject(includeInstance, f),
    respirationRate: (f = msg.getRespirationRate()) && msh_trait_telemetry_v1_respiration_pb.RespirationTrait.RespirationEvent.toObject(includeInstance, f),
    distance: (f = msg.getDistance()) && msh_trait_telemetry_v1_distance_pb.DistanceTrait.DistanceEvent.toObject(includeInstance, f),
    connectivity: (f = msg.getConnectivity()) && msh_trait_actuator_v1_online_offline_pb.OnlineOfflineTrait.OnlineOfflineChangedEvent.toObject(includeInstance, f),
    audioClassification: (f = msg.getAudioClassification()) && msh_trait_activities_v1_audio_classification_pb.AudioClassificationTrait.AudioClassificationEvent.toObject(includeInstance, f),
    temperature: (f = msg.getTemperature()) && msh_trait_sensor_v1_temperature_pb.TemperatureTrait.TemperatureChangedEvent.toObject(includeInstance, f),
    humidity: (f = msg.getHumidity()) && msh_trait_sensor_v1_humidity_pb.HumidityTrait.HumidityChangedEvent.toObject(includeInstance, f),
    pressure: (f = msg.getPressure()) && msh_trait_sensor_v1_pressure_pb.PressureTrait.PressureChangedEvent.toObject(includeInstance, f),
    lightIntensity: (f = msg.getLightIntensity()) && msh_trait_sensor_v1_light_pb.LightIntensityTrait.LightIntensityChangedEvent.toObject(includeInstance, f),
    airQuality: (f = msg.getAirQuality()) && msh_trait_sensor_v1_air_quality_pb.AirQualityTrait.AirQualityChangedEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.resource.v1.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Event;
  return proto.msh.resource.v1.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Event}
 */
proto.msh.resource.v1.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 1:
      var value = new msh_trait_activities_v1_activity_level_pb.ActivityLevelTrait.ActivityLevelEvent;
      reader.readMessage(value,msh_trait_activities_v1_activity_level_pb.ActivityLevelTrait.ActivityLevelEvent.deserializeBinaryFromReader);
      msg.setActivityLevel(value);
      break;
    case 2:
      var value = new msh_trait_activities_v1_fall_pb.FallTrait.FallEvent;
      reader.readMessage(value,msh_trait_activities_v1_fall_pb.FallTrait.FallEvent.deserializeBinaryFromReader);
      msg.setFall(value);
      break;
    case 3:
      var value = new msh_trait_activities_v1_presence_pb.PresenceTrait.PresenceEvent;
      reader.readMessage(value,msh_trait_activities_v1_presence_pb.PresenceTrait.PresenceEvent.deserializeBinaryFromReader);
      msg.setPresence(value);
      break;
    case 4:
      var value = new msh_trait_telemetry_v1_heart_rate_pb.HeartRateTrait.HeartRateEvent;
      reader.readMessage(value,msh_trait_telemetry_v1_heart_rate_pb.HeartRateTrait.HeartRateEvent.deserializeBinaryFromReader);
      msg.setHeartRate(value);
      break;
    case 5:
      var value = new msh_trait_telemetry_v1_respiration_pb.RespirationTrait.RespirationEvent;
      reader.readMessage(value,msh_trait_telemetry_v1_respiration_pb.RespirationTrait.RespirationEvent.deserializeBinaryFromReader);
      msg.setRespirationRate(value);
      break;
    case 6:
      var value = new msh_trait_telemetry_v1_distance_pb.DistanceTrait.DistanceEvent;
      reader.readMessage(value,msh_trait_telemetry_v1_distance_pb.DistanceTrait.DistanceEvent.deserializeBinaryFromReader);
      msg.setDistance(value);
      break;
    case 7:
      var value = new msh_trait_actuator_v1_online_offline_pb.OnlineOfflineTrait.OnlineOfflineChangedEvent;
      reader.readMessage(value,msh_trait_actuator_v1_online_offline_pb.OnlineOfflineTrait.OnlineOfflineChangedEvent.deserializeBinaryFromReader);
      msg.setConnectivity(value);
      break;
    case 14:
      var value = new msh_trait_activities_v1_audio_classification_pb.AudioClassificationTrait.AudioClassificationEvent;
      reader.readMessage(value,msh_trait_activities_v1_audio_classification_pb.AudioClassificationTrait.AudioClassificationEvent.deserializeBinaryFromReader);
      msg.setAudioClassification(value);
      break;
    case 9:
      var value = new msh_trait_sensor_v1_temperature_pb.TemperatureTrait.TemperatureChangedEvent;
      reader.readMessage(value,msh_trait_sensor_v1_temperature_pb.TemperatureTrait.TemperatureChangedEvent.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    case 10:
      var value = new msh_trait_sensor_v1_humidity_pb.HumidityTrait.HumidityChangedEvent;
      reader.readMessage(value,msh_trait_sensor_v1_humidity_pb.HumidityTrait.HumidityChangedEvent.deserializeBinaryFromReader);
      msg.setHumidity(value);
      break;
    case 11:
      var value = new msh_trait_sensor_v1_pressure_pb.PressureTrait.PressureChangedEvent;
      reader.readMessage(value,msh_trait_sensor_v1_pressure_pb.PressureTrait.PressureChangedEvent.deserializeBinaryFromReader);
      msg.setPressure(value);
      break;
    case 12:
      var value = new msh_trait_sensor_v1_light_pb.LightIntensityTrait.LightIntensityChangedEvent;
      reader.readMessage(value,msh_trait_sensor_v1_light_pb.LightIntensityTrait.LightIntensityChangedEvent.deserializeBinaryFromReader);
      msg.setLightIntensity(value);
      break;
    case 13:
      var value = new msh_trait_sensor_v1_air_quality_pb.AirQualityTrait.AirQualityChangedEvent;
      reader.readMessage(value,msh_trait_sensor_v1_air_quality_pb.AirQualityTrait.AirQualityChangedEvent.deserializeBinaryFromReader);
      msg.setAirQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivityLevel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_trait_activities_v1_activity_level_pb.ActivityLevelTrait.ActivityLevelEvent.serializeBinaryToWriter
    );
  }
  f = message.getFall();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_trait_activities_v1_fall_pb.FallTrait.FallEvent.serializeBinaryToWriter
    );
  }
  f = message.getPresence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_trait_activities_v1_presence_pb.PresenceTrait.PresenceEvent.serializeBinaryToWriter
    );
  }
  f = message.getHeartRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      msh_trait_telemetry_v1_heart_rate_pb.HeartRateTrait.HeartRateEvent.serializeBinaryToWriter
    );
  }
  f = message.getRespirationRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_trait_telemetry_v1_respiration_pb.RespirationTrait.RespirationEvent.serializeBinaryToWriter
    );
  }
  f = message.getDistance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      msh_trait_telemetry_v1_distance_pb.DistanceTrait.DistanceEvent.serializeBinaryToWriter
    );
  }
  f = message.getConnectivity();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      msh_trait_actuator_v1_online_offline_pb.OnlineOfflineTrait.OnlineOfflineChangedEvent.serializeBinaryToWriter
    );
  }
  f = message.getAudioClassification();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      msh_trait_activities_v1_audio_classification_pb.AudioClassificationTrait.AudioClassificationEvent.serializeBinaryToWriter
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      msh_trait_sensor_v1_temperature_pb.TemperatureTrait.TemperatureChangedEvent.serializeBinaryToWriter
    );
  }
  f = message.getHumidity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      msh_trait_sensor_v1_humidity_pb.HumidityTrait.HumidityChangedEvent.serializeBinaryToWriter
    );
  }
  f = message.getPressure();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      msh_trait_sensor_v1_pressure_pb.PressureTrait.PressureChangedEvent.serializeBinaryToWriter
    );
  }
  f = message.getLightIntensity();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      msh_trait_sensor_v1_light_pb.LightIntensityTrait.LightIntensityChangedEvent.serializeBinaryToWriter
    );
  }
  f = message.getAirQuality();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      msh_trait_sensor_v1_air_quality_pb.AirQualityTrait.AirQualityChangedEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event_id = 8;
 * @return {string}
 */
proto.msh.resource.v1.Event.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional msh.trait.activities.v1.ActivityLevelTrait.ActivityLevelEvent activity_level = 1;
 * @return {?proto.msh.trait.activities.v1.ActivityLevelTrait.ActivityLevelEvent}
 */
proto.msh.resource.v1.Event.prototype.getActivityLevel = function() {
  return /** @type{?proto.msh.trait.activities.v1.ActivityLevelTrait.ActivityLevelEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_activities_v1_activity_level_pb.ActivityLevelTrait.ActivityLevelEvent, 1));
};


/**
 * @param {?proto.msh.trait.activities.v1.ActivityLevelTrait.ActivityLevelEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setActivityLevel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearActivityLevel = function() {
  return this.setActivityLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasActivityLevel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional msh.trait.activities.v1.FallTrait.FallEvent fall = 2;
 * @return {?proto.msh.trait.activities.v1.FallTrait.FallEvent}
 */
proto.msh.resource.v1.Event.prototype.getFall = function() {
  return /** @type{?proto.msh.trait.activities.v1.FallTrait.FallEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_activities_v1_fall_pb.FallTrait.FallEvent, 2));
};


/**
 * @param {?proto.msh.trait.activities.v1.FallTrait.FallEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setFall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearFall = function() {
  return this.setFall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasFall = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.trait.activities.v1.PresenceTrait.PresenceEvent presence = 3;
 * @return {?proto.msh.trait.activities.v1.PresenceTrait.PresenceEvent}
 */
proto.msh.resource.v1.Event.prototype.getPresence = function() {
  return /** @type{?proto.msh.trait.activities.v1.PresenceTrait.PresenceEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_activities_v1_presence_pb.PresenceTrait.PresenceEvent, 3));
};


/**
 * @param {?proto.msh.trait.activities.v1.PresenceTrait.PresenceEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setPresence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearPresence = function() {
  return this.setPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasPresence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional msh.trait.telemetry.v1.HeartRateTrait.HeartRateEvent heart_rate = 4;
 * @return {?proto.msh.trait.telemetry.v1.HeartRateTrait.HeartRateEvent}
 */
proto.msh.resource.v1.Event.prototype.getHeartRate = function() {
  return /** @type{?proto.msh.trait.telemetry.v1.HeartRateTrait.HeartRateEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_telemetry_v1_heart_rate_pb.HeartRateTrait.HeartRateEvent, 4));
};


/**
 * @param {?proto.msh.trait.telemetry.v1.HeartRateTrait.HeartRateEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setHeartRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearHeartRate = function() {
  return this.setHeartRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasHeartRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional msh.trait.telemetry.v1.RespirationTrait.RespirationEvent respiration_rate = 5;
 * @return {?proto.msh.trait.telemetry.v1.RespirationTrait.RespirationEvent}
 */
proto.msh.resource.v1.Event.prototype.getRespirationRate = function() {
  return /** @type{?proto.msh.trait.telemetry.v1.RespirationTrait.RespirationEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_telemetry_v1_respiration_pb.RespirationTrait.RespirationEvent, 5));
};


/**
 * @param {?proto.msh.trait.telemetry.v1.RespirationTrait.RespirationEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setRespirationRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearRespirationRate = function() {
  return this.setRespirationRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasRespirationRate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional msh.trait.telemetry.v1.DistanceTrait.DistanceEvent distance = 6;
 * @return {?proto.msh.trait.telemetry.v1.DistanceTrait.DistanceEvent}
 */
proto.msh.resource.v1.Event.prototype.getDistance = function() {
  return /** @type{?proto.msh.trait.telemetry.v1.DistanceTrait.DistanceEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_telemetry_v1_distance_pb.DistanceTrait.DistanceEvent, 6));
};


/**
 * @param {?proto.msh.trait.telemetry.v1.DistanceTrait.DistanceEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setDistance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearDistance = function() {
  return this.setDistance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasDistance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent connectivity = 7;
 * @return {?proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getConnectivity = function() {
  return /** @type{?proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_actuator_v1_online_offline_pb.OnlineOfflineTrait.OnlineOfflineChangedEvent, 7));
};


/**
 * @param {?proto.msh.trait.actuator.v1.OnlineOfflineTrait.OnlineOfflineChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setConnectivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearConnectivity = function() {
  return this.setConnectivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasConnectivity = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent audio_classification = 14;
 * @return {?proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent}
 */
proto.msh.resource.v1.Event.prototype.getAudioClassification = function() {
  return /** @type{?proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_activities_v1_audio_classification_pb.AudioClassificationTrait.AudioClassificationEvent, 14));
};


/**
 * @param {?proto.msh.trait.activities.v1.AudioClassificationTrait.AudioClassificationEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setAudioClassification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearAudioClassification = function() {
  return this.setAudioClassification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasAudioClassification = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent temperature = 9;
 * @return {?proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getTemperature = function() {
  return /** @type{?proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_temperature_pb.TemperatureTrait.TemperatureChangedEvent, 9));
};


/**
 * @param {?proto.msh.trait.sensor.v1.TemperatureTrait.TemperatureChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setTemperature = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional msh.trait.sensor.v1.HumidityTrait.HumidityChangedEvent humidity = 10;
 * @return {?proto.msh.trait.sensor.v1.HumidityTrait.HumidityChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getHumidity = function() {
  return /** @type{?proto.msh.trait.sensor.v1.HumidityTrait.HumidityChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_humidity_pb.HumidityTrait.HumidityChangedEvent, 10));
};


/**
 * @param {?proto.msh.trait.sensor.v1.HumidityTrait.HumidityChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setHumidity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearHumidity = function() {
  return this.setHumidity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasHumidity = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional msh.trait.sensor.v1.PressureTrait.PressureChangedEvent pressure = 11;
 * @return {?proto.msh.trait.sensor.v1.PressureTrait.PressureChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getPressure = function() {
  return /** @type{?proto.msh.trait.sensor.v1.PressureTrait.PressureChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_pressure_pb.PressureTrait.PressureChangedEvent, 11));
};


/**
 * @param {?proto.msh.trait.sensor.v1.PressureTrait.PressureChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setPressure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearPressure = function() {
  return this.setPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasPressure = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional msh.trait.sensor.v1.LightIntensityTrait.LightIntensityChangedEvent light_intensity = 12;
 * @return {?proto.msh.trait.sensor.v1.LightIntensityTrait.LightIntensityChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getLightIntensity = function() {
  return /** @type{?proto.msh.trait.sensor.v1.LightIntensityTrait.LightIntensityChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_light_pb.LightIntensityTrait.LightIntensityChangedEvent, 12));
};


/**
 * @param {?proto.msh.trait.sensor.v1.LightIntensityTrait.LightIntensityChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setLightIntensity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearLightIntensity = function() {
  return this.setLightIntensity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasLightIntensity = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional msh.trait.sensor.v1.AirQualityTrait.AirQualityChangedEvent air_quality = 13;
 * @return {?proto.msh.trait.sensor.v1.AirQualityTrait.AirQualityChangedEvent}
 */
proto.msh.resource.v1.Event.prototype.getAirQuality = function() {
  return /** @type{?proto.msh.trait.sensor.v1.AirQualityTrait.AirQualityChangedEvent} */ (
    jspb.Message.getWrapperField(this, msh_trait_sensor_v1_air_quality_pb.AirQualityTrait.AirQualityChangedEvent, 13));
};


/**
 * @param {?proto.msh.trait.sensor.v1.AirQualityTrait.AirQualityChangedEvent|undefined} value
 * @return {!proto.msh.resource.v1.Event} returns this
*/
proto.msh.resource.v1.Event.prototype.setAirQuality = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.msh.resource.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Event} returns this
 */
proto.msh.resource.v1.Event.prototype.clearAirQuality = function() {
  return this.setAirQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Event.prototype.hasAirQuality = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.EventFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.EventFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.EventFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.EventFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRange: (f = msg.getTimeRange()) && msh_common_v1_common_pb.TimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.EventFilter}
 */
proto.msh.resource.v1.EventFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.EventFilter;
  return proto.msh.resource.v1.EventFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.EventFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.EventFilter}
 */
proto.msh.resource.v1.EventFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_common_v1_common_pb.TimeRange;
      reader.readMessage(value,msh_common_v1_common_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.EventFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.EventFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.EventFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.EventFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_common_v1_common_pb.TimeRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.common.v1.TimeRange time_range = 1;
 * @return {?proto.msh.common.v1.TimeRange}
 */
proto.msh.resource.v1.EventFilter.prototype.getTimeRange = function() {
  return /** @type{?proto.msh.common.v1.TimeRange} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.TimeRange, 1));
};


/**
 * @param {?proto.msh.common.v1.TimeRange|undefined} value
 * @return {!proto.msh.resource.v1.EventFilter} returns this
*/
proto.msh.resource.v1.EventFilter.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.EventFilter} returns this
 */
proto.msh.resource.v1.EventFilter.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.EventFilter.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.msh.resource.v1);
