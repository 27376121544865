// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.

// Global application store definition
// This module contains all of the actions and commits used in the application.
// Stored them in a central area to make it clearer

//
// Actions
//

// Auth
export const AUTH_LOGIN_REQUEST = "action:auth-loginRequest";
export const AUTH_LOGOUT_REQUEST = "action:auth-logoutRequest";
export const AUTH_VERIFY_REQUEST = "action:auth-verifyRequest";
export const AUTH_AUTO_LOGIN_REQUEST = "action:auth-loginWithTokenRequest";
//Profile
export const PROFILE_SET_REQUEST = "action:profile-setRequest";
// Account
export const ACCOUNT_GET_REQUEST = "action:account-getRequest";
export const ACCOUNT_RESET_REQUEST = "action:account-resetRequest";
// Breadcrumbs
export const BREADCRUMB_SET_REQUEST = "action:breadcrumb-setRequest";
// Buildings
export const BUILDING_GET_ALL_REQUEST = "action:building-getAllRequest";
export const BUILDING_RESET_REQUEST = "action:building-resetRequest";
export const BUILDING_SET_CURRENT_REQUEST = "action:building-setCurrentRequest";
export const BUILDING_COUNT_DEVICE_REQUEST =
  "action:building-countDeviceRequest";
export const BUILDING_FETCH_ALERTS_REQUEST =
  "action:building-fetchAlertRequest";
export const BUILDING_GET_ADL_REQUEST = "action:building-getAdlRequest";
// Devices
export const DEVICE_GET_ALL_REQUEST = "action:device-getAllRequest";
export const DEVICE_RESET_REQUEST = "action:device-resetRequest";
export const DEVICE_TRIGGER_RECORDING_REQUEST =
  "action:device-triggerRecordingRequest";
export const DEVICE_STOP_RECORDING_REQUEST =
  "action:device-stopRecordingRequest";
export const DEVICE_CANCEL_RECORDING_REQUEST =
  "action:device-cancelRecordingRequest";
export const DEVICE_PROVISION_DEVICE_REQUEST = "action:device-provision";
export const DEVICE_UNPROVISION_DEVICE_REQUEST = "action:device-unprovision";
export const DEVICE_GET_BY_PRODUCT_NAME_REQUEST_PROMISE =
  "action:device-getByProductNamePromise";
// Floors
export const FLOOR_RESET_REQUEST = "action:floors-resetRequest";
export const FLOOR_FORCE_UPDATE = "action:floors-forceUpdate";
export const FLOOR_GET_DEVICES_REQUEST = "action:floors-getDevicesRequest";
export const FLOOR_FETCH_STRUCTURE_REQUEST =
  "action:floors-fetchStructureRequest";
export const FLOOR_GET_DEVICE_REQUEST = "action:floors-getDeviceRequest";
// Structure
export const STRUCTURE_REQUEST = "action:structure-request";
export const STRUCTURE_RESET_REQUEST = "action:structure-resetRequest";
export const STRUCTURE_ALERT_REQUEST = "action:structure-alertRequest";
export const STRUCTURE_ADL_REQUEST = "action:structure-adlRequest";
export const STRUCTURE_ACTIVITY_LEVEL_REQUEST =
  "action:structure-activityLevelRequest";
export const STRUCTURE_SENSOR_TEMPERATURE_REQUEST =
  "action:structure-temperatureRequest";
export const STRUCTURE_SENSOR_HUMIDITY_REQUEST =
  "action:structure-humidityRequest";
export const STRUCTURE_SENSOR_AIR_QUALITY_REQUEST =
  "action:structure-airQualityRequest";
export const STRUCTURE_ACKNOWLEDGE_ALERT_REQUEST_PROMISE =
  "action:structure-ackAlertRequestPromise";
// Recordings
export const RECORDINGS_FETCH_REQUEST = "action:recordings-fetchRequest";
export const RECORDINGS_LIST_FOR_FLOOR_REQUEST =
  "action:recordings-listForFloorRequest";
export const RECORDINGS_LIST_FOR_STRUCTURE_REQUEST =
  "action:recordings-listForStructureRequest";
export const RECORDINGS_REQUEST_DOWNLOAD_URL_PROMISE =
  "action:recordings-requestDownloadUrlPromise";
export const RECORDING_GET_REQUEST = "action:recordings-getRequest";
export const RECORDING_GET_PROMISE = "action:recordings-getPromise";
// GRPC CALL STATUS
export const GRPC_CALLS_INPROGRESS_REQUEST =
  "action:grpc-callsInProgressRequest";
export const GRPC_CALLS_COMPLETED_REQUEST = "action:grpc-callsCompletedRequest";
export const GRPC_CALL_SET_STATUS_REQUEST = "action:grpc-callSetStatusRequest";
// PUSHER SERVER EVENTS
export const PUSHER_ONLINE_OFFLINE_EVENT = "action:pusher-online_offline_event";
export const PUSHER_CAST_VOTE_PRESENCE = "action:pusher-CastVoteResponse";
export const PUSHER_ADD_ALERT = "action:pusher-AddAlertResponse";
export const PUSHER_ALERT_ACKNOWLEDGE =
  "action:pusher-AcknowledgeAlertResponse";
export const PUSHER_UPDATE_STRUCTURE = "action:pusher-UpdateStructureResponse";
export const PUSHER_CREATE_STRUCTURE = "action:pusher-CreateStructureResponse";
export const PUSHER_RECORDING_CHANGED = "action:pusher-recording_changed";
export const PUSHER_RECORDING_CONFIRMED =
  "action:pusher-ConfirmRecordingResponse";

//
// Commits
//

// Auth
export const AUTH_LOGIN_SUCCESS = "commit:auth-loginSuccess";
export const AUTH_LOGOUT_SUCCESS = "commit:auth-logoutSuccess";
export const AUTH_AUTO_LOGIN_SUCCESS = "commit:auth-loginWithTokenSuccess";
// Profile
export const PROFILE_SET_SUCCESS = "commit:profile-setSuccess";
// Account
export const ACCOUNT_GET_SUCCESS = "commit:account-getSuccess";
export const ACCOUNT_RESET_SUCCESS = "commit:account-resetSuccess";
// Breadcrumbs
export const BREADCRUMB_SET_SUCCESS = "commit:breadcrumb-setSuccess";
// Buildings
export const BUILDING_GET_ALL_SUCCESS = "commit:building-getAllSuccess";
export const BUILDING_RESET_SUCCESS = "commit:building-resetSuccess";
export const BUILDING_SET_CURRENT_SUCCESS = "commit:building-setCurrentSuccess";
export const BUILDING_COUNT_DEVICE_SUCCESS =
  "commit:building-countDeviceSuccess";
export const BUILDING_FETCH_ALERTS_SUCCESS =
  "commit:building-fetchAlertSuccess";
export const BUILDING_GET_ADL_SUCCESS = "commit:building-getAdlSuccess";
// Devices
export const DEVICE_GET_ALL_SUCCESS = "commit:device-getAllSuccess";
export const DEVICE_RESET_SUCCESS = "commit:device-resetSuccess";
export const DEVICE_UPDATE_ONLINE_STATUS = "commit:device-updateOnlineStatus";
// Floors
export const FLOOR_RESET_SUCCESS = "commit:floors-resetSuccess";
export const FLOOR_GET_DEVICES_SUCCESS = "commit:floors-getDevicesSuccess";
export const FLOOR_STRUCTURE_FETCH_SUCCESS =
  "commit-floors-fetchStructureSuccess";
export const FLOOR_GET_DEVICE_SUCCESS = "commit:floors-getDeviceSuccess";
// Structure
export const STRUCTURE_FETCH_SUCCESS = "commit:structure-fetchSuccess";
export const STRUCTURE_RESET_SUCCESS = "commit:structure-resetSuccess";
export const STRUCTURE_ALERT_SUCCESS = "commit:structure-alertSuccess";
export const STRUCTURE_ADL_SUCCESS = "commit:structure-adlSuccess";
export const STRUCTURE_ACTIVITY_LEVEL_SUCCESS =
  "commit:structure-SensorActivityLevelSuccess";
export const STRUCTURE_SENSOR_TEMPERATURE_SUCCESS =
  "commit:structure-SensorTemperatureSuccess";
export const STRUCTURE_SENSOR_HUMIDITY_SUCCESS =
  "commit:structure-humiditySuccess";
export const STRUCTURE_SENSOR_AIR_QUALITY_SUCCESS =
  "commit:structure-airQualitySuccess";
// Recordings
export const RECORDINGS_FETCH_SUCCESS = "commit:recordings-fetchSuccess";
export const RECORDINGS_LIST_FOR_FLOOR_SUCCESS =
  "commit:recordings-listForFloorSuccess";
export const RECORDINGS_LIST_FOR_STRUCTURE_SUCCESS =
  "commit:recordings-listForStructureSuccess";
export const RECORDINGS_LIST_FOR_BUILDING_TITLE =
  "commit:recordings-listForBuildingTitle";
export const RECORDINGS_LIST_FOR_FLOOR_TITLE =
  "commit:recordings-listForFloorTitle";
export const RECORDINGS_LIST_FOR_STRUCTURE_TITLE =
  "commit:recordings-listForStructureTitle";
export const RECORDING_GET_SUCCESS = "commit:recordings-getSuccess";
// GRPC call status
export const GRPC_CALL_SET_STATUS_SUCCESS = "commit:grpc-callSetStatusSuccess";

// Values used to indicate if the data is initialized, is being loaded or is it's completed
// Used in the "status" field

export const STATUS_NOT_INITIALIZED = "status:not-initialized";
export const STATUS_LOADING = "status:loading";
export const STATUS_COMPLETED = "status:completed";
