// source: msh/service/v1/alert_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_common_v1_language_pb = require('../../../msh/common/v1/language_pb.js');
goog.object.extend(proto, msh_common_v1_language_pb);
var msh_resource_v1_alert_pb = require('../../../msh/resource/v1/alert_pb.js');
goog.object.extend(proto, msh_resource_v1_alert_pb);
goog.exportSymbol('proto.msh.service.v1.AcknowledgeAlertByUserRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AcknowledgeAlertByUserResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.AcknowledgeAlertRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AcknowledgeAlertResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.AddAlertRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.AddAlertResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertByEventRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertByEventResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertsRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.GetAlertsResponse', null, global);
goog.exportSymbol('proto.msh.service.v1.IncreaseAlertLevelRequest', null, global);
goog.exportSymbol('proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo', null, global);
goog.exportSymbol('proto.msh.service.v1.IncreaseAlertLevelResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddAlertRequest.displayName = 'proto.msh.service.v1.AddAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AddAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AddAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AddAlertResponse.displayName = 'proto.msh.service.v1.AddAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AcknowledgeAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AcknowledgeAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AcknowledgeAlertRequest.displayName = 'proto.msh.service.v1.AcknowledgeAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AcknowledgeAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AcknowledgeAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AcknowledgeAlertResponse.displayName = 'proto.msh.service.v1.AcknowledgeAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.IncreaseAlertLevelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.IncreaseAlertLevelRequest.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.IncreaseAlertLevelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.IncreaseAlertLevelRequest.displayName = 'proto.msh.service.v1.IncreaseAlertLevelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.displayName = 'proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.IncreaseAlertLevelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.IncreaseAlertLevelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.IncreaseAlertLevelResponse.displayName = 'proto.msh.service.v1.IncreaseAlertLevelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAlertsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertsRequest.displayName = 'proto.msh.service.v1.GetAlertsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.service.v1.GetAlertsResponse.repeatedFields_, null);
};
goog.inherits(proto.msh.service.v1.GetAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertsResponse.displayName = 'proto.msh.service.v1.GetAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertRequest.displayName = 'proto.msh.service.v1.GetAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertResponse.displayName = 'proto.msh.service.v1.GetAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertByEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAlertByEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertByEventRequest.displayName = 'proto.msh.service.v1.GetAlertByEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.GetAlertByEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.GetAlertByEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.GetAlertByEventResponse.displayName = 'proto.msh.service.v1.GetAlertByEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AcknowledgeAlertByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AcknowledgeAlertByUserRequest.displayName = 'proto.msh.service.v1.AcknowledgeAlertByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.service.v1.AcknowledgeAlertByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.service.v1.AcknowledgeAlertByUserResponse.displayName = 'proto.msh.service.v1.AcknowledgeAlertByUserResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddAlertRequest}
 */
proto.msh.service.v1.AddAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddAlertRequest;
  return proto.msh.service.v1.AddAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddAlertRequest}
 */
proto.msh.service.v1.AddAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AddAlertRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddAlertRequest} returns this
 */
proto.msh.service.v1.AddAlertRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AddAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AddAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AddAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alertCreated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AddAlertResponse}
 */
proto.msh.service.v1.AddAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AddAlertResponse;
  return proto.msh.service.v1.AddAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AddAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AddAlertResponse}
 */
proto.msh.service.v1.AddAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AddAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AddAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AddAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AddAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlertCreated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AddAlertResponse.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AddAlertResponse} returns this
 */
proto.msh.service.v1.AddAlertResponse.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool alert_created = 2;
 * @return {boolean}
 */
proto.msh.service.v1.AddAlertResponse.prototype.getAlertCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.msh.service.v1.AddAlertResponse} returns this
 */
proto.msh.service.v1.AddAlertResponse.prototype.setAlertCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AcknowledgeAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AcknowledgeAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertRequestId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AcknowledgeAlertRequest}
 */
proto.msh.service.v1.AcknowledgeAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AcknowledgeAlertRequest;
  return proto.msh.service.v1.AcknowledgeAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AcknowledgeAlertRequest}
 */
proto.msh.service.v1.AcknowledgeAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AcknowledgeAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AcknowledgeAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AcknowledgeAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alert_request_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertRequest.prototype.getAlertRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertRequest} returns this
 */
proto.msh.service.v1.AcknowledgeAlertRequest.prototype.setAlertRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AcknowledgeAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AcknowledgeAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertAckId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alertId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AcknowledgeAlertResponse}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AcknowledgeAlertResponse;
  return proto.msh.service.v1.AcknowledgeAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AcknowledgeAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AcknowledgeAlertResponse}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertAckId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AcknowledgeAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AcknowledgeAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertAckId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string alert_ack_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.getAlertAckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertResponse} returns this
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.setAlertAckId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alert_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertResponse} returns this
 */
proto.msh.service.v1.AcknowledgeAlertResponse.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.IncreaseAlertLevelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactInfoList: jspb.Message.toObjectList(msg.getContactInfoList(),
    proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.toObject, includeInstance),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.IncreaseAlertLevelRequest;
  return proto.msh.service.v1.IncreaseAlertLevelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    case 2:
      var value = new proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo;
      reader.readMessage(value,proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.deserializeBinaryFromReader);
      msg.addContactInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.IncreaseAlertLevelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lang: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contactTimeList: jspb.Message.toObjectList(msg.getContactTimeList(),
    msh_common_v1_common_pb.ContactTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo;
  return proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {!proto.msh.common.v1.Language} */ (reader.readEnum());
      msg.setLang(value);
      break;
    case 5:
      var value = new msh_common_v1_common_pb.ContactTime;
      reader.readMessage(value,msh_common_v1_common_pb.ContactTime.deserializeBinaryFromReader);
      msg.addContactTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLang();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getContactTimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      msh_common_v1_common_pb.ContactTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 level = 2;
 * @return {number}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional msh.common.v1.Language lang = 4;
 * @return {!proto.msh.common.v1.Language}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.getLang = function() {
  return /** @type {!proto.msh.common.v1.Language} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.msh.common.v1.Language} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.setLang = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated msh.common.v1.ContactTime contact_time = 5;
 * @return {!Array<!proto.msh.common.v1.ContactTime>}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.getContactTimeList = function() {
  return /** @type{!Array<!proto.msh.common.v1.ContactTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_common_v1_common_pb.ContactTime, 5));
};


/**
 * @param {!Array<!proto.msh.common.v1.ContactTime>} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
*/
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.setContactTimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.msh.common.v1.ContactTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.common.v1.ContactTime}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.addContactTime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.msh.common.v1.ContactTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo.prototype.clearContactTimeList = function() {
  return this.setContactTimeList([]);
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ContactInfo contact_info = 2;
 * @return {!Array<!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo>}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.getContactInfoList = function() {
  return /** @type{!Array<!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo, 2));
};


/**
 * @param {!Array<!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo>} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest} returns this
*/
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.setContactInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.addContactInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.msh.service.v1.IncreaseAlertLevelRequest.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.clearContactInfoList = function() {
  return this.setContactInfoList([]);
};


/**
 * optional uint32 level = 3;
 * @return {number}
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.service.v1.IncreaseAlertLevelRequest} returns this
 */
proto.msh.service.v1.IncreaseAlertLevelRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.IncreaseAlertLevelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.IncreaseAlertLevelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelResponse}
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.IncreaseAlertLevelResponse;
  return proto.msh.service.v1.IncreaseAlertLevelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.IncreaseAlertLevelResponse}
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.IncreaseAlertLevelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.IncreaseAlertLevelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.IncreaseAlertLevelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && msh_resource_v1_alert_pb.AlertFilter.toObject(includeInstance, f),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertsRequest}
 */
proto.msh.service.v1.GetAlertsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertsRequest;
  return proto.msh.service.v1.GetAlertsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertsRequest}
 */
proto.msh.service.v1.GetAlertsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = new msh_resource_v1_alert_pb.AlertFilter;
      reader.readMessage(value,msh_resource_v1_alert_pb.AlertFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_resource_v1_alert_pb.AlertFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetAlertsRequest} returns this
 */
proto.msh.service.v1.GetAlertsRequest.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional msh.resource.v1.AlertFilter filter = 2;
 * @return {?proto.msh.resource.v1.AlertFilter}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.getFilter = function() {
  return /** @type{?proto.msh.resource.v1.AlertFilter} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_alert_pb.AlertFilter, 2));
};


/**
 * @param {?proto.msh.resource.v1.AlertFilter|undefined} value
 * @return {!proto.msh.service.v1.GetAlertsRequest} returns this
*/
proto.msh.service.v1.GetAlertsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAlertsRequest} returns this
 */
proto.msh.service.v1.GetAlertsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional msh.common.v1.Page page = 3;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 3));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetAlertsRequest} returns this
*/
proto.msh.service.v1.GetAlertsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAlertsRequest} returns this
 */
proto.msh.service.v1.GetAlertsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAlertsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.service.v1.GetAlertsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    msh_resource_v1_alert_pb.Alert.toObject, includeInstance),
    page: (f = msg.getPage()) && msh_common_v1_common_pb.Page.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertsResponse}
 */
proto.msh.service.v1.GetAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertsResponse;
  return proto.msh.service.v1.GetAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertsResponse}
 */
proto.msh.service.v1.GetAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 2:
      var value = new msh_common_v1_common_pb.Page;
      reader.readMessage(value,msh_common_v1_common_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msh_common_v1_common_pb.Page.serializeBinaryToWriter
    );
  }
};


/**
 * repeated msh.resource.v1.Alert alerts = 1;
 * @return {!Array<!proto.msh.resource.v1.Alert>}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_alert_pb.Alert, 1));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Alert>} value
 * @return {!proto.msh.service.v1.GetAlertsResponse} returns this
*/
proto.msh.service.v1.GetAlertsResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msh.resource.v1.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Alert}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msh.resource.v1.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.service.v1.GetAlertsResponse} returns this
 */
proto.msh.service.v1.GetAlertsResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional msh.common.v1.Page page = 2;
 * @return {?proto.msh.common.v1.Page}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.getPage = function() {
  return /** @type{?proto.msh.common.v1.Page} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Page, 2));
};


/**
 * @param {?proto.msh.common.v1.Page|undefined} value
 * @return {!proto.msh.service.v1.GetAlertsResponse} returns this
*/
proto.msh.service.v1.GetAlertsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAlertsResponse} returns this
 */
proto.msh.service.v1.GetAlertsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAlertsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertRequest}
 */
proto.msh.service.v1.GetAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertRequest;
  return proto.msh.service.v1.GetAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertRequest}
 */
proto.msh.service.v1.GetAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetAlertRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetAlertRequest} returns this
 */
proto.msh.service.v1.GetAlertRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && msh_resource_v1_alert_pb.Alert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertResponse}
 */
proto.msh.service.v1.GetAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertResponse;
  return proto.msh.service.v1.GetAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertResponse}
 */
proto.msh.service.v1.GetAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Alert alert = 1;
 * @return {?proto.msh.resource.v1.Alert}
 */
proto.msh.service.v1.GetAlertResponse.prototype.getAlert = function() {
  return /** @type{?proto.msh.resource.v1.Alert} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_alert_pb.Alert, 1));
};


/**
 * @param {?proto.msh.resource.v1.Alert|undefined} value
 * @return {!proto.msh.service.v1.GetAlertResponse} returns this
*/
proto.msh.service.v1.GetAlertResponse.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAlertResponse} returns this
 */
proto.msh.service.v1.GetAlertResponse.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAlertResponse.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertByEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertByEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertByEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertByEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertByEventRequest}
 */
proto.msh.service.v1.GetAlertByEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertByEventRequest;
  return proto.msh.service.v1.GetAlertByEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertByEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertByEventRequest}
 */
proto.msh.service.v1.GetAlertByEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertByEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertByEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertByEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertByEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.msh.service.v1.GetAlertByEventRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.GetAlertByEventRequest} returns this
 */
proto.msh.service.v1.GetAlertByEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.GetAlertByEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.GetAlertByEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.GetAlertByEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertByEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && msh_resource_v1_alert_pb.Alert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.GetAlertByEventResponse}
 */
proto.msh.service.v1.GetAlertByEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.GetAlertByEventResponse;
  return proto.msh.service.v1.GetAlertByEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.GetAlertByEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.GetAlertByEventResponse}
 */
proto.msh.service.v1.GetAlertByEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.GetAlertByEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.GetAlertByEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.GetAlertByEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.GetAlertByEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
};


/**
 * optional msh.resource.v1.Alert alert = 1;
 * @return {?proto.msh.resource.v1.Alert}
 */
proto.msh.service.v1.GetAlertByEventResponse.prototype.getAlert = function() {
  return /** @type{?proto.msh.resource.v1.Alert} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_alert_pb.Alert, 1));
};


/**
 * @param {?proto.msh.resource.v1.Alert|undefined} value
 * @return {!proto.msh.service.v1.GetAlertByEventResponse} returns this
*/
proto.msh.service.v1.GetAlertByEventResponse.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.service.v1.GetAlertByEventResponse} returns this
 */
proto.msh.service.v1.GetAlertByEventResponse.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.service.v1.GetAlertByEventResponse.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AcknowledgeAlertByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserRequest}
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AcknowledgeAlertByUserRequest;
  return proto.msh.service.v1.AcknowledgeAlertByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserRequest}
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AcknowledgeAlertByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserRequest} returns this
 */
proto.msh.service.v1.AcknowledgeAlertByUserRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.service.v1.AcknowledgeAlertByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertAckId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alertId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserResponse}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.service.v1.AcknowledgeAlertByUserResponse;
  return proto.msh.service.v1.AcknowledgeAlertByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserResponse}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertAckId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.service.v1.AcknowledgeAlertByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.service.v1.AcknowledgeAlertByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertAckId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string alert_ack_id = 1;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.getAlertAckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserResponse} returns this
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.setAlertAckId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alert_id = 2;
 * @return {string}
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.service.v1.AcknowledgeAlertByUserResponse} returns this
 */
proto.msh.service.v1.AcknowledgeAlertByUserResponse.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.msh.service.v1);
