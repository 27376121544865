/**
 * @fileoverview gRPC-Web generated client stub for msh.service.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var msh_resource_v1_raw_storage_index_pb = require('../../../msh/resource/v1/raw_storage_index_pb.js')

var msh_resource_v1_recording_pb = require('../../../msh/resource/v1/recording_pb.js')
const proto = {};
proto.msh = {};
proto.msh.service = {};
proto.msh.service.v1 = require('./index_manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.IndexManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.msh.service.v1.IndexManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.CreateIndexRequest,
 *   !proto.msh.service.v1.CreateIndexResponse>}
 */
const methodDescriptor_IndexManager_CreateIndex = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/CreateIndex',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.CreateIndexRequest,
  proto.msh.service.v1.CreateIndexResponse,
  /**
   * @param {!proto.msh.service.v1.CreateIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateIndexResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.CreateIndexRequest,
 *   !proto.msh.service.v1.CreateIndexResponse>}
 */
const methodInfo_IndexManager_CreateIndex = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.CreateIndexResponse,
  /**
   * @param {!proto.msh.service.v1.CreateIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.CreateIndexResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.CreateIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.CreateIndexResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.CreateIndexResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.createIndex =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/CreateIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_CreateIndex,
      callback);
};


/**
 * @param {!proto.msh.service.v1.CreateIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.CreateIndexResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.createIndex =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/CreateIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_CreateIndex);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.DeleteIndexRequest,
 *   !proto.msh.service.v1.DeleteIndexResponse>}
 */
const methodDescriptor_IndexManager_DeleteIndex = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/DeleteIndex',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.DeleteIndexRequest,
  proto.msh.service.v1.DeleteIndexResponse,
  /**
   * @param {!proto.msh.service.v1.DeleteIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.DeleteIndexResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.DeleteIndexRequest,
 *   !proto.msh.service.v1.DeleteIndexResponse>}
 */
const methodInfo_IndexManager_DeleteIndex = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.DeleteIndexResponse,
  /**
   * @param {!proto.msh.service.v1.DeleteIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.DeleteIndexResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.DeleteIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.DeleteIndexResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.DeleteIndexResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.deleteIndex =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/DeleteIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_DeleteIndex,
      callback);
};


/**
 * @param {!proto.msh.service.v1.DeleteIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.DeleteIndexResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.deleteIndex =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/DeleteIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_DeleteIndex);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ListIndexRequest,
 *   !proto.msh.service.v1.ListIndexResponse>}
 */
const methodDescriptor_IndexManager_ListIndex = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/ListIndex',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ListIndexRequest,
  proto.msh.service.v1.ListIndexResponse,
  /**
   * @param {!proto.msh.service.v1.ListIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListIndexResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ListIndexRequest,
 *   !proto.msh.service.v1.ListIndexResponse>}
 */
const methodInfo_IndexManager_ListIndex = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ListIndexResponse,
  /**
   * @param {!proto.msh.service.v1.ListIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListIndexResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ListIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ListIndexResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ListIndexResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.listIndex =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ListIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ListIndex,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ListIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ListIndexResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.listIndex =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ListIndex',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ListIndex);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ListRecordingsRequest,
 *   !proto.msh.service.v1.ListRecordingsResponse>}
 */
const methodDescriptor_IndexManager_ListRecordings = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/ListRecordings',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ListRecordingsRequest,
  proto.msh.service.v1.ListRecordingsResponse,
  /**
   * @param {!proto.msh.service.v1.ListRecordingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListRecordingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ListRecordingsRequest,
 *   !proto.msh.service.v1.ListRecordingsResponse>}
 */
const methodInfo_IndexManager_ListRecordings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ListRecordingsResponse,
  /**
   * @param {!proto.msh.service.v1.ListRecordingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ListRecordingsResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ListRecordingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ListRecordingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ListRecordingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.listRecordings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ListRecordings',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ListRecordings,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ListRecordingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ListRecordingsResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.listRecordings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ListRecordings',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ListRecordings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.GetRecordingRequest,
 *   !proto.msh.service.v1.GetRecordingResponse>}
 */
const methodDescriptor_IndexManager_GetRecording = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/GetRecording',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.GetRecordingRequest,
  proto.msh.service.v1.GetRecordingResponse,
  /**
   * @param {!proto.msh.service.v1.GetRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRecordingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.GetRecordingRequest,
 *   !proto.msh.service.v1.GetRecordingResponse>}
 */
const methodInfo_IndexManager_GetRecording = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.GetRecordingResponse,
  /**
   * @param {!proto.msh.service.v1.GetRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.GetRecordingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.GetRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.GetRecordingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.GetRecordingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.getRecording =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/GetRecording',
      request,
      metadata || {},
      methodDescriptor_IndexManager_GetRecording,
      callback);
};


/**
 * @param {!proto.msh.service.v1.GetRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.GetRecordingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.getRecording =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/GetRecording',
      request,
      metadata || {},
      methodDescriptor_IndexManager_GetRecording);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.RequestDownloadUrlRequest,
 *   !proto.msh.service.v1.RequestDownloadUrlResponse>}
 */
const methodDescriptor_IndexManager_RequestDownloadUrl = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/RequestDownloadUrl',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.RequestDownloadUrlRequest,
  proto.msh.service.v1.RequestDownloadUrlResponse,
  /**
   * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.RequestDownloadUrlResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.RequestDownloadUrlRequest,
 *   !proto.msh.service.v1.RequestDownloadUrlResponse>}
 */
const methodInfo_IndexManager_RequestDownloadUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.RequestDownloadUrlResponse,
  /**
   * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.RequestDownloadUrlResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.RequestDownloadUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.RequestDownloadUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.requestDownloadUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/RequestDownloadUrl',
      request,
      metadata || {},
      methodDescriptor_IndexManager_RequestDownloadUrl,
      callback);
};


/**
 * @param {!proto.msh.service.v1.RequestDownloadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.RequestDownloadUrlResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.requestDownloadUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/RequestDownloadUrl',
      request,
      metadata || {},
      methodDescriptor_IndexManager_RequestDownloadUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.ConfirmRecordingRequest,
 *   !proto.msh.service.v1.ConfirmRecordingResponse>}
 */
const methodDescriptor_IndexManager_ConfirmRecording = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/ConfirmRecording',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.ConfirmRecordingRequest,
  proto.msh.service.v1.ConfirmRecordingResponse,
  /**
   * @param {!proto.msh.service.v1.ConfirmRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ConfirmRecordingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.ConfirmRecordingRequest,
 *   !proto.msh.service.v1.ConfirmRecordingResponse>}
 */
const methodInfo_IndexManager_ConfirmRecording = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.ConfirmRecordingResponse,
  /**
   * @param {!proto.msh.service.v1.ConfirmRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.ConfirmRecordingResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.ConfirmRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.ConfirmRecordingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.ConfirmRecordingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.confirmRecording =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ConfirmRecording',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ConfirmRecording,
      callback);
};


/**
 * @param {!proto.msh.service.v1.ConfirmRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.ConfirmRecordingResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.confirmRecording =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/ConfirmRecording',
      request,
      metadata || {},
      methodDescriptor_IndexManager_ConfirmRecording);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.msh.service.v1.AddRemoveRecordingStatusRequest,
 *   !proto.msh.service.v1.AddRemoveRecordingStatusResponse>}
 */
const methodDescriptor_IndexManager_AddRemoveRecordingStatus = new grpc.web.MethodDescriptor(
  '/msh.service.v1.IndexManager/AddRemoveRecordingStatus',
  grpc.web.MethodType.UNARY,
  proto.msh.service.v1.AddRemoveRecordingStatusRequest,
  proto.msh.service.v1.AddRemoveRecordingStatusResponse,
  /**
   * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddRemoveRecordingStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.msh.service.v1.AddRemoveRecordingStatusRequest,
 *   !proto.msh.service.v1.AddRemoveRecordingStatusResponse>}
 */
const methodInfo_IndexManager_AddRemoveRecordingStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.msh.service.v1.AddRemoveRecordingStatusResponse,
  /**
   * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.msh.service.v1.AddRemoveRecordingStatusResponse.deserializeBinary
);


/**
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.msh.service.v1.AddRemoveRecordingStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.msh.service.v1.AddRemoveRecordingStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.msh.service.v1.IndexManagerClient.prototype.addRemoveRecordingStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/msh.service.v1.IndexManager/AddRemoveRecordingStatus',
      request,
      metadata || {},
      methodDescriptor_IndexManager_AddRemoveRecordingStatus,
      callback);
};


/**
 * @param {!proto.msh.service.v1.AddRemoveRecordingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.msh.service.v1.AddRemoveRecordingStatusResponse>}
 *     Promise that resolves to the response
 */
proto.msh.service.v1.IndexManagerPromiseClient.prototype.addRemoveRecordingStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/msh.service.v1.IndexManager/AddRemoveRecordingStatus',
      request,
      metadata || {},
      methodDescriptor_IndexManager_AddRemoveRecordingStatus);
};


module.exports = proto.msh.service.v1;

