// Copyright 2021
//  __  __                       _           _     _   _            _ _   _
// |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
// | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
// | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
// |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|
//
//  All Rights Reserved.
//
// NOTICE:  All information contained herein is, and remains
// the property of Moonshot Health Incorporated and its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Adobe Systems Incorporated
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process, and are protected by trade secret or copyright law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Moonshot Health Incorporated.
import gRPCService from "@/services/grpc.service";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import eventProtoDef from "@/grpc/msh/resource/v1/event_pb";
import commonDef from "@/grpc/msh/common/v1/common_pb";
import {
  BUILDING_GET_ALL_REQUEST,
  BUILDING_GET_ALL_SUCCESS,
  BUILDING_RESET_REQUEST,
  BUILDING_RESET_SUCCESS,
  BUILDING_SET_CURRENT_REQUEST,
  BUILDING_SET_CURRENT_SUCCESS,
  BUILDING_COUNT_DEVICE_REQUEST,
  BUILDING_COUNT_DEVICE_SUCCESS,
  BUILDING_FETCH_ALERTS_REQUEST,
  BUILDING_FETCH_ALERTS_SUCCESS,
  BUILDING_GET_ADL_REQUEST,
  BUILDING_GET_ADL_SUCCESS,
} from "@/store/constants";
import {
  ACCOUNT_CHANGED_EVENT,
  AUTH_LOGOUTCONFIRMED,
  BUILDING_SET_CURRENTCONFIRMED,
  BUILDING_CHANGED_CURRENTCONFIRMED,
} from "@/events";
import { bus } from "@/main.js";
import dateUtils from "@/utils/date.utils";
import store from "@/store";

// Functions to respond to events
export const registerBuildingCallbacks = () => {
  bus.$on(ACCOUNT_CHANGED_EVENT, (accountId) => {
    store.dispatch(BUILDING_GET_ALL_REQUEST, accountId);
  });

  bus.$on(AUTH_LOGOUTCONFIRMED, () => {
    store.dispatch(BUILDING_RESET_REQUEST);
  });
};

const state = {
  buildings: null,
  currentBuilding: null,
  deviceCount: null,
  alerts: [],
  adlEvents: [],
};

const actions = {
  //
  // BUILDING_GET_ALL_REQUEST
  //
  [BUILDING_GET_ALL_REQUEST](context, accountId) {
    const request = new structureProtoDef.GetBuildingsRequest([
      accountId,
      false,
    ]);
    gRPCService
      .building()
      .getBuildings(request, {})
      .then((buildingsResponse) => {
        context.commit(
          BUILDING_GET_ALL_SUCCESS,
          buildingsResponse.getBuildingsList()
        );
        context.commit(BUILDING_SET_CURRENT_SUCCESS);
      });
  },
  //
  // BUILDING_SET_CURRENT_REQUEST
  //
  [BUILDING_SET_CURRENT_REQUEST](context, buildingId) {
    context.commit(BUILDING_SET_CURRENT_SUCCESS, buildingId);
  },
  //
  // BUILDING_RESET_REQUEST
  //
  [BUILDING_RESET_REQUEST](context) {
    context.commit(BUILDING_RESET_SUCCESS);
  },
  //
  // BUILDING_COUNT_DEVICE_REQUEST
  //
  [BUILDING_COUNT_DEVICE_REQUEST](context, buildingId) {
    const request = new structureProtoDef.GetDeviceOnlineCountRequest([
      buildingId,
    ]);
    gRPCService
      .building()
      .getDeviceOnlineCount(request, {})
      .then((response) => {
        context.commit(BUILDING_COUNT_DEVICE_SUCCESS, response.toObject());
      });
  },
  //
  // BUILDING_FETCH_ALERTS_REQUEST
  //
  [BUILDING_FETCH_ALERTS_REQUEST](context, buildingId) {
    // const request = new structureProtoDef.GetBuildingAlertsRequest([
    //   buildingId,
    //   [[dateRange.start, dateRange.end], status],
    //   [pageRequest.cursor, pageRequest.perPage]
    // ]);
    const request = new structureProtoDef.GetBuildingAlertsRequest([
      buildingId,
      [["", ""], 1],
      ["", 5],
    ]);
    gRPCService
      .building()
      .getBuildingAlerts(request, {})
      .then((response) => {
        context.commit(BUILDING_FETCH_ALERTS_SUCCESS, response.getAlertsList());
      });
  },
  //
  // BUILDING_GET_ADL_REQUEST
  //
  [BUILDING_GET_ADL_REQUEST](context, buildingId) {
    const dateRange = dateUtils.generateDateRangeForOneWeek();
    const eventFilter = new eventProtoDef.EventFilter();
    eventFilter.setTimeRange(dateRange);
    const protoPage = new commonDef.Page();
    protoPage.setPerPage("6");
    const request = new structureProtoDef.GetADLBuildingEventsRequest();
    request.setBuildingId(buildingId);
    request.setFilter(eventFilter);
    request.setPage(protoPage);
    gRPCService
      .building()
      .getADLBuildingEvents(request, {})
      .then((response) => {
        context.commit(BUILDING_GET_ADL_SUCCESS, response.getAdlEventsList());
      });
  },
};

const mutations = {
  //
  // BUILDING_GET_ALL_SUCCESS
  //
  [BUILDING_GET_ALL_SUCCESS](state, buildings) {
    state.buildings = buildings;
  },
  //
  // BUILDING_RESET_SUCCESS
  //
  [BUILDING_RESET_SUCCESS](state) {
    state.buildings = null;
    state.currentBuilding = null;
    state.deviceCount = null;
    state.alert = [];
    state.adlEvents = [];
  },
  [BUILDING_SET_CURRENT_SUCCESS](state, buildingId) {
    // If not building ID are specified, we'll use the one
    // localStorage. If none exist, we'll take the first building
    var currentBuildingId = buildingId;
    const accountId = store.getters.getAccountId;
    if (currentBuildingId) {
      localStorage.setItem(accountId + ":currentBuildingId", currentBuildingId);
      bus.$emit(BUILDING_CHANGED_CURRENTCONFIRMED, currentBuildingId);
    } else {
      currentBuildingId = localStorage.getItem(
        accountId + ":currentBuildingId"
      );
      if (!currentBuildingId) {
        currentBuildingId = state.buildings[0].getBuildingId();
        localStorage.setItem(
          accountId + ":currentBuildingId",
          currentBuildingId
        );
      }
      bus.$emit(BUILDING_SET_CURRENTCONFIRMED, currentBuildingId);
    }
    state.currentBuildingId = currentBuildingId;
    state.currentBuilding = state.buildings.find(
      (building) => building.getBuildingId() === currentBuildingId
    );
  },
  //
  // BUILDING_COUNT_DEVICE_SUCCESS
  //
  [BUILDING_COUNT_DEVICE_SUCCESS](state, payload) {
    state.deviceCount = payload;
  },
  //
  // BUILDING_FETCH_ALERTS_SUCCESS
  //
  [BUILDING_FETCH_ALERTS_SUCCESS](state, payload) {
    state.alerts = [];
    payload.forEach((alert) => {
      state.alerts.push(alert.toObject());
    });
  },
  //
  // BUILDING_GET_ADL_SUCCESS
  //
  [BUILDING_GET_ADL_SUCCESS](state, payload) {
    state.adlEvents = [];
    payload.forEach((adl) => {
      state.adlEvents.push(adl.toObject());
    });
  },
};

const getters = {
  getBuilding: (state) => (buildingId) => {
    if (state.buildings) {
      return state.buildings.find(
        (element) => element.getBuildingId() === buildingId
      );
    }
    return null;
  },
  getCurrentBuilding: (state) => {
    return state.currentBuilding;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
