// source: msh/resource/v1/structure.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var msh_common_v1_common_pb = require('../../../msh/common/v1/common_pb.js');
goog.object.extend(proto, msh_common_v1_common_pb);
var msh_common_v1_address_pb = require('../../../msh/common/v1/address_pb.js');
goog.object.extend(proto, msh_common_v1_address_pb);
var msh_resource_v1_user_pb = require('../../../msh/resource/v1/user_pb.js');
goog.object.extend(proto, msh_resource_v1_user_pb);
var msh_resource_v1_device_pb = require('../../../msh/resource/v1/device_pb.js');
goog.object.extend(proto, msh_resource_v1_device_pb);
var msh_resource_v1_alert_pb = require('../../../msh/resource/v1/alert_pb.js');
goog.object.extend(proto, msh_resource_v1_alert_pb);
var msh_resource_v1_alert_contact_pb = require('../../../msh/resource/v1/alert_contact_pb.js');
goog.object.extend(proto, msh_resource_v1_alert_contact_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.msh.resource.v1.Building', null, global);
goog.exportSymbol('proto.msh.resource.v1.Floor', null, global);
goog.exportSymbol('proto.msh.resource.v1.Room', null, global);
goog.exportSymbol('proto.msh.resource.v1.Statistics', null, global);
goog.exportSymbol('proto.msh.resource.v1.Structure', null, global);
goog.exportSymbol('proto.msh.resource.v1.Structure.Business', null, global);
goog.exportSymbol('proto.msh.resource.v1.Structure.Consumer', null, global);
goog.exportSymbol('proto.msh.resource.v1.Structure.TypeCase', null, global);
goog.exportSymbol('proto.msh.resource.v1.StructureBusinessStatistics', null, global);
goog.exportSymbol('proto.msh.resource.v1.StructureConsumerStatistics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Structure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.msh.resource.v1.Structure.oneofGroups_);
};
goog.inherits(proto.msh.resource.v1.Structure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Structure.displayName = 'proto.msh.resource.v1.Structure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Structure.Business = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Structure.Business.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Structure.Business, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Structure.Business.displayName = 'proto.msh.resource.v1.Structure.Business';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Structure.Consumer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Structure.Consumer.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Structure.Consumer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Structure.Consumer.displayName = 'proto.msh.resource.v1.Structure.Consumer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Building = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Building.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Building, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Building.displayName = 'proto.msh.resource.v1.Building';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Floor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msh.resource.v1.Floor.repeatedFields_, null);
};
goog.inherits(proto.msh.resource.v1.Floor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Floor.displayName = 'proto.msh.resource.v1.Floor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Room = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.Room, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Room.displayName = 'proto.msh.resource.v1.Room';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.Statistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.Statistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.Statistics.displayName = 'proto.msh.resource.v1.Statistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.StructureConsumerStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.StructureConsumerStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.StructureConsumerStatistics.displayName = 'proto.msh.resource.v1.StructureConsumerStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msh.resource.v1.StructureBusinessStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msh.resource.v1.StructureBusinessStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msh.resource.v1.StructureBusinessStatistics.displayName = 'proto.msh.resource.v1.StructureBusinessStatistics';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.msh.resource.v1.Structure.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.msh.resource.v1.Structure.TypeCase = {
  TYPE_NOT_SET: 0,
  CONSUMER: 1,
  BUSINESS: 2
};

/**
 * @return {proto.msh.resource.v1.Structure.TypeCase}
 */
proto.msh.resource.v1.Structure.prototype.getTypeCase = function() {
  return /** @type {proto.msh.resource.v1.Structure.TypeCase} */(jspb.Message.computeOneofCase(this, proto.msh.resource.v1.Structure.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Structure.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Structure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Structure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumer: (f = msg.getConsumer()) && proto.msh.resource.v1.Structure.Consumer.toObject(includeInstance, f),
    business: (f = msg.getBusiness()) && proto.msh.resource.v1.Structure.Business.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Structure}
 */
proto.msh.resource.v1.Structure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Structure;
  return proto.msh.resource.v1.Structure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Structure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Structure}
 */
proto.msh.resource.v1.Structure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msh.resource.v1.Structure.Consumer;
      reader.readMessage(value,proto.msh.resource.v1.Structure.Consumer.deserializeBinaryFromReader);
      msg.setConsumer(value);
      break;
    case 2:
      var value = new proto.msh.resource.v1.Structure.Business;
      reader.readMessage(value,proto.msh.resource.v1.Structure.Business.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Structure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Structure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Structure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.msh.resource.v1.Structure.Consumer.serializeBinaryToWriter
    );
  }
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.msh.resource.v1.Structure.Business.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Structure.Business.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Structure.Business.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Structure.Business.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Structure.Business} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.Business.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.msh.resource.v1.Room.toObject, includeInstance),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    msh_resource_v1_device_pb.Device.toObject, includeInstance),
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    msh_resource_v1_alert_pb.Alert.toObject, includeInstance),
    stats: (f = msg.getStats()) && proto.msh.resource.v1.StructureBusinessStatistics.toObject(includeInstance, f),
    presenceStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lastPresenceUpdated: (f = msg.getLastPresenceUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Structure.Business}
 */
proto.msh.resource.v1.Structure.Business.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Structure.Business;
  return proto.msh.resource.v1.Structure.Business.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Structure.Business} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Structure.Business}
 */
proto.msh.resource.v1.Structure.Business.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 7:
      var value = new proto.msh.resource.v1.Room;
      reader.readMessage(value,proto.msh.resource.v1.Room.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 8:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 9:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 10:
      var value = new proto.msh.resource.v1.StructureBusinessStatistics;
      reader.readMessage(value,proto.msh.resource.v1.StructureBusinessStatistics.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresenceStatus(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPresenceUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Structure.Business.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Structure.Business.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Structure.Business} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.Business.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.msh.resource.v1.Room.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.msh.resource.v1.StructureBusinessStatistics.serializeBinaryToWriter
    );
  }
  f = message.getPresenceStatus();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getLastPresenceUpdated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Structure.Business.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.resource.v1.Structure.Business.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional msh.common.v1.Agent created_by = 5;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Structure.Business.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 5));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Business.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Structure.Business.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Business.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Room rooms = 7;
 * @return {!Array<!proto.msh.resource.v1.Room>}
 */
proto.msh.resource.v1.Structure.Business.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Room>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.resource.v1.Room, 7));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Room>} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.msh.resource.v1.Room=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Room}
 */
proto.msh.resource.v1.Structure.Business.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.msh.resource.v1.Room, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * repeated Device devices = 8;
 * @return {!Array<!proto.msh.resource.v1.Device>}
 */
proto.msh.resource.v1.Structure.Business.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_device_pb.Device, 8));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Device>} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.msh.resource.v1.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Device}
 */
proto.msh.resource.v1.Structure.Business.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.msh.resource.v1.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated Alert alerts = 9;
 * @return {!Array<!proto.msh.resource.v1.Alert>}
 */
proto.msh.resource.v1.Structure.Business.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_alert_pb.Alert, 9));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Alert>} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.msh.resource.v1.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Alert}
 */
proto.msh.resource.v1.Structure.Business.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.msh.resource.v1.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional StructureBusinessStatistics stats = 10;
 * @return {?proto.msh.resource.v1.StructureBusinessStatistics}
 */
proto.msh.resource.v1.Structure.Business.prototype.getStats = function() {
  return /** @type{?proto.msh.resource.v1.StructureBusinessStatistics} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.StructureBusinessStatistics, 10));
};


/**
 * @param {?proto.msh.resource.v1.StructureBusinessStatistics|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Business.prototype.hasStats = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 presence_status = 11;
 * @return {number}
 */
proto.msh.resource.v1.Structure.Business.prototype.getPresenceStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.setPresenceStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp last_presence_updated = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Structure.Business.prototype.getLastPresenceUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
*/
proto.msh.resource.v1.Structure.Business.prototype.setLastPresenceUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Business} returns this
 */
proto.msh.resource.v1.Structure.Business.prototype.clearLastPresenceUpdated = function() {
  return this.setLastPresenceUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Business.prototype.hasLastPresenceUpdated = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Structure.Consumer.repeatedFields_ = [4,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Structure.Consumer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Structure.Consumer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.Consumer.toObject = function(includeInstance, msg) {
  var f, obj = {
    structureId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: (f = msg.getOwner()) && msh_resource_v1_user_pb.User.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.msh.resource.v1.Room.toObject, includeInstance),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    msh_resource_v1_device_pb.Device.toObject, includeInstance),
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    msh_resource_v1_alert_pb.Alert.toObject, includeInstance),
    stats: (f = msg.getStats()) && proto.msh.resource.v1.StructureConsumerStatistics.toObject(includeInstance, f),
    presenceStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lastPresenceUpdated: (f = msg.getLastPresenceUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Structure.Consumer}
 */
proto.msh.resource.v1.Structure.Consumer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Structure.Consumer;
  return proto.msh.resource.v1.Structure.Consumer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Structure.Consumer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Structure.Consumer}
 */
proto.msh.resource.v1.Structure.Consumer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 4:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 7:
      var value = new proto.msh.resource.v1.Room;
      reader.readMessage(value,proto.msh.resource.v1.Room.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 8:
      var value = new msh_resource_v1_device_pb.Device;
      reader.readMessage(value,msh_resource_v1_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 9:
      var value = new msh_resource_v1_alert_pb.Alert;
      reader.readMessage(value,msh_resource_v1_alert_pb.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 10:
      var value = new proto.msh.resource.v1.StructureConsumerStatistics;
      reader.readMessage(value,proto.msh.resource.v1.StructureConsumerStatistics.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresenceStatus(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPresenceUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Structure.Consumer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Structure.Consumer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Structure.Consumer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.msh.resource.v1.Room.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      msh_resource_v1_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      msh_resource_v1_alert_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.msh.resource.v1.StructureConsumerStatistics.serializeBinaryToWriter
    );
  }
  f = message.getPresenceStatus();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getLastPresenceUpdated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string structure_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User owner = 3;
 * @return {?proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getOwner = function() {
  return /** @type{?proto.msh.resource.v1.User} */ (
    jspb.Message.getWrapperField(this, msh_resource_v1_user_pb.User, 3));
};


/**
 * @param {?proto.msh.resource.v1.User|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 4));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional msh.common.v1.Agent created_by = 5;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 5));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Room rooms = 7;
 * @return {!Array<!proto.msh.resource.v1.Room>}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Room>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.resource.v1.Room, 7));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Room>} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.msh.resource.v1.Room=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Room}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.msh.resource.v1.Room, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * repeated Device devices = 8;
 * @return {!Array<!proto.msh.resource.v1.Device>}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_device_pb.Device, 8));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Device>} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.msh.resource.v1.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Device}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.msh.resource.v1.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated Alert alerts = 9;
 * @return {!Array<!proto.msh.resource.v1.Alert>}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_alert_pb.Alert, 9));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Alert>} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.msh.resource.v1.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Alert}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.msh.resource.v1.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional StructureConsumerStatistics stats = 10;
 * @return {?proto.msh.resource.v1.StructureConsumerStatistics}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getStats = function() {
  return /** @type{?proto.msh.resource.v1.StructureConsumerStatistics} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.StructureConsumerStatistics, 10));
};


/**
 * @param {?proto.msh.resource.v1.StructureConsumerStatistics|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.hasStats = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 presence_status = 11;
 * @return {number}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getPresenceStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.setPresenceStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp last_presence_updated = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.getLastPresenceUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
*/
proto.msh.resource.v1.Structure.Consumer.prototype.setLastPresenceUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure.Consumer} returns this
 */
proto.msh.resource.v1.Structure.Consumer.prototype.clearLastPresenceUpdated = function() {
  return this.setLastPresenceUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.Consumer.prototype.hasLastPresenceUpdated = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Consumer consumer = 1;
 * @return {?proto.msh.resource.v1.Structure.Consumer}
 */
proto.msh.resource.v1.Structure.prototype.getConsumer = function() {
  return /** @type{?proto.msh.resource.v1.Structure.Consumer} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Structure.Consumer, 1));
};


/**
 * @param {?proto.msh.resource.v1.Structure.Consumer|undefined} value
 * @return {!proto.msh.resource.v1.Structure} returns this
*/
proto.msh.resource.v1.Structure.prototype.setConsumer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.msh.resource.v1.Structure.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure} returns this
 */
proto.msh.resource.v1.Structure.prototype.clearConsumer = function() {
  return this.setConsumer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.prototype.hasConsumer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Business business = 2;
 * @return {?proto.msh.resource.v1.Structure.Business}
 */
proto.msh.resource.v1.Structure.prototype.getBusiness = function() {
  return /** @type{?proto.msh.resource.v1.Structure.Business} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Structure.Business, 2));
};


/**
 * @param {?proto.msh.resource.v1.Structure.Business|undefined} value
 * @return {!proto.msh.resource.v1.Structure} returns this
*/
proto.msh.resource.v1.Structure.prototype.setBusiness = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.msh.resource.v1.Structure.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Structure} returns this
 */
proto.msh.resource.v1.Structure.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Structure.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Building.repeatedFields_ = [3,4,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Building.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Building.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Building} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Building.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    managersList: jspb.Message.toObjectList(msg.getManagersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    msh_resource_v1_user_pb.User.toObject, includeInstance),
    address: (f = msg.getAddress()) && msh_common_v1_address_pb.AddressFormat.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    floorsList: jspb.Message.toObjectList(msg.getFloorsList(),
    proto.msh.resource.v1.Floor.toObject, includeInstance),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    msh_resource_v1_alert_contact_pb.AlertContact.toObject, includeInstance),
    stats: (f = msg.getStats()) && proto.msh.resource.v1.Statistics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Building}
 */
proto.msh.resource.v1.Building.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Building;
  return proto.msh.resource.v1.Building.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Building} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Building}
 */
proto.msh.resource.v1.Building.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addManagers(value);
      break;
    case 4:
      var value = new msh_resource_v1_user_pb.User;
      reader.readMessage(value,msh_resource_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = new msh_common_v1_address_pb.AddressFormat;
      reader.readMessage(value,msh_common_v1_address_pb.AddressFormat.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 7:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = new proto.msh.resource.v1.Floor;
      reader.readMessage(value,proto.msh.resource.v1.Floor.deserializeBinaryFromReader);
      msg.addFloors(value);
      break;
    case 9:
      var value = new msh_resource_v1_alert_contact_pb.AlertContact;
      reader.readMessage(value,msh_resource_v1_alert_contact_pb.AlertContact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 10:
      var value = new proto.msh.resource.v1.Statistics;
      reader.readMessage(value,proto.msh.resource.v1.Statistics.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Building.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Building.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Building} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Building.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      msh_resource_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_address_pb.AddressFormat.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.msh.resource.v1.Floor.serializeBinaryToWriter
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      msh_resource_v1_alert_contact_pb.AlertContact.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.msh.resource.v1.Statistics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Building.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.resource.v1.Building.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated User managers = 3;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Building.prototype.getManagersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 3));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Building.prototype.addManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearManagersList = function() {
  return this.setManagersList([]);
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.msh.resource.v1.User>}
 */
proto.msh.resource.v1.Building.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_user_pb.User, 4));
};


/**
 * @param {!Array<!proto.msh.resource.v1.User>} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.msh.resource.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.User}
 */
proto.msh.resource.v1.Building.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.msh.resource.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional msh.common.v1.AddressFormat address = 5;
 * @return {?proto.msh.common.v1.AddressFormat}
 */
proto.msh.resource.v1.Building.prototype.getAddress = function() {
  return /** @type{?proto.msh.common.v1.AddressFormat} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_address_pb.AddressFormat, 5));
};


/**
 * @param {?proto.msh.common.v1.AddressFormat|undefined} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Building.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Building.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Building.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 7;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Building.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 7));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Building.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Floor floors = 8;
 * @return {!Array<!proto.msh.resource.v1.Floor>}
 */
proto.msh.resource.v1.Building.prototype.getFloorsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Floor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.resource.v1.Floor, 8));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Floor>} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.msh.resource.v1.Floor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Floor}
 */
proto.msh.resource.v1.Building.prototype.addFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.msh.resource.v1.Floor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};


/**
 * repeated AlertContact contacts = 9;
 * @return {!Array<!proto.msh.resource.v1.AlertContact>}
 */
proto.msh.resource.v1.Building.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.AlertContact>} */ (
    jspb.Message.getRepeatedWrapperField(this, msh_resource_v1_alert_contact_pb.AlertContact, 9));
};


/**
 * @param {!Array<!proto.msh.resource.v1.AlertContact>} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.msh.resource.v1.AlertContact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.AlertContact}
 */
proto.msh.resource.v1.Building.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.msh.resource.v1.AlertContact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional Statistics stats = 10;
 * @return {?proto.msh.resource.v1.Statistics}
 */
proto.msh.resource.v1.Building.prototype.getStats = function() {
  return /** @type{?proto.msh.resource.v1.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Statistics, 10));
};


/**
 * @param {?proto.msh.resource.v1.Statistics|undefined} value
 * @return {!proto.msh.resource.v1.Building} returns this
*/
proto.msh.resource.v1.Building.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Building} returns this
 */
proto.msh.resource.v1.Building.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Building.prototype.hasStats = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msh.resource.v1.Floor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Floor.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Floor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Floor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Floor.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    structuresList: jspb.Message.toObjectList(msg.getStructuresList(),
    proto.msh.resource.v1.Structure.toObject, includeInstance),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.msh.resource.v1.Statistics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Floor}
 */
proto.msh.resource.v1.Floor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Floor;
  return proto.msh.resource.v1.Floor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Floor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Floor}
 */
proto.msh.resource.v1.Floor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.msh.resource.v1.Structure;
      reader.readMessage(value,proto.msh.resource.v1.Structure.deserializeBinaryFromReader);
      msg.addStructures(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 5:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 6:
      var value = new proto.msh.resource.v1.Statistics;
      reader.readMessage(value,proto.msh.resource.v1.Statistics.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Floor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Floor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Floor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Floor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStructuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.msh.resource.v1.Structure.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.msh.resource.v1.Statistics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string floor_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Floor.prototype.getFloorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.setFloorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.resource.v1.Floor.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Structure structures = 3;
 * @return {!Array<!proto.msh.resource.v1.Structure>}
 */
proto.msh.resource.v1.Floor.prototype.getStructuresList = function() {
  return /** @type{!Array<!proto.msh.resource.v1.Structure>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msh.resource.v1.Structure, 3));
};


/**
 * @param {!Array<!proto.msh.resource.v1.Structure>} value
 * @return {!proto.msh.resource.v1.Floor} returns this
*/
proto.msh.resource.v1.Floor.prototype.setStructuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.msh.resource.v1.Structure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msh.resource.v1.Structure}
 */
proto.msh.resource.v1.Floor.prototype.addStructures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.msh.resource.v1.Structure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.clearStructuresList = function() {
  return this.setStructuresList([]);
};


/**
 * optional google.protobuf.Timestamp creation_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Floor.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Floor} returns this
*/
proto.msh.resource.v1.Floor.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Floor.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 5;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Floor.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 5));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Floor} returns this
*/
proto.msh.resource.v1.Floor.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Floor.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Statistics stats = 6;
 * @return {?proto.msh.resource.v1.Statistics}
 */
proto.msh.resource.v1.Floor.prototype.getStats = function() {
  return /** @type{?proto.msh.resource.v1.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.msh.resource.v1.Statistics, 6));
};


/**
 * @param {?proto.msh.resource.v1.Statistics|undefined} value
 * @return {!proto.msh.resource.v1.Floor} returns this
*/
proto.msh.resource.v1.Floor.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Floor} returns this
 */
proto.msh.resource.v1.Floor.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Floor.prototype.hasStats = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Room.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Room.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Room} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Room.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && msh_common_v1_common_pb.Agent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Room}
 */
proto.msh.resource.v1.Room.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Room;
  return proto.msh.resource.v1.Room.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Room} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Room}
 */
proto.msh.resource.v1.Room.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 5:
      var value = new msh_common_v1_common_pb.Agent;
      reader.readMessage(value,msh_common_v1_common_pb.Agent.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Room.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Room.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Room} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Room.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      msh_common_v1_common_pb.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Room.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Room} returns this
 */
proto.msh.resource.v1.Room.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.msh.resource.v1.Room.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Room} returns this
 */
proto.msh.resource.v1.Room.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.msh.resource.v1.Room.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.msh.resource.v1.Room} returns this
*/
proto.msh.resource.v1.Room.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Room} returns this
 */
proto.msh.resource.v1.Room.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Room.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional msh.common.v1.Agent created_by = 5;
 * @return {?proto.msh.common.v1.Agent}
 */
proto.msh.resource.v1.Room.prototype.getCreatedBy = function() {
  return /** @type{?proto.msh.common.v1.Agent} */ (
    jspb.Message.getWrapperField(this, msh_common_v1_common_pb.Agent, 5));
};


/**
 * @param {?proto.msh.common.v1.Agent|undefined} value
 * @return {!proto.msh.resource.v1.Room} returns this
*/
proto.msh.resource.v1.Room.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.msh.resource.v1.Room} returns this
 */
proto.msh.resource.v1.Room.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.msh.resource.v1.Room.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.Statistics.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.Statistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.Statistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Statistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalAlerts: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPresents: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalStructures: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalAbsents: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalUnknowns: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.Statistics}
 */
proto.msh.resource.v1.Statistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.Statistics;
  return proto.msh.resource.v1.Statistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.Statistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.Statistics}
 */
proto.msh.resource.v1.Statistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatsId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAlerts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPresents(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalStructures(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAbsents(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalUnknowns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.Statistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.Statistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.Statistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.Statistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalAlerts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalPresents();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalStructures();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalAbsents();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalUnknowns();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string stats_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.Statistics.prototype.getStatsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setStatsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total_alerts = 2;
 * @return {number}
 */
proto.msh.resource.v1.Statistics.prototype.getTotalAlerts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setTotalAlerts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_presents = 3;
 * @return {number}
 */
proto.msh.resource.v1.Statistics.prototype.getTotalPresents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setTotalPresents = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_structures = 4;
 * @return {number}
 */
proto.msh.resource.v1.Statistics.prototype.getTotalStructures = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setTotalStructures = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 total_absents = 5;
 * @return {number}
 */
proto.msh.resource.v1.Statistics.prototype.getTotalAbsents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setTotalAbsents = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_unknowns = 6;
 * @return {number}
 */
proto.msh.resource.v1.Statistics.prototype.getTotalUnknowns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.Statistics} returns this
 */
proto.msh.resource.v1.Statistics.prototype.setTotalUnknowns = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.StructureConsumerStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.StructureConsumerStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.StructureConsumerStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalAlerts: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.StructureConsumerStatistics}
 */
proto.msh.resource.v1.StructureConsumerStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.StructureConsumerStatistics;
  return proto.msh.resource.v1.StructureConsumerStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.StructureConsumerStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.StructureConsumerStatistics}
 */
proto.msh.resource.v1.StructureConsumerStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatsId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.StructureConsumerStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.StructureConsumerStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.StructureConsumerStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalAlerts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string stats_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.getStatsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.StructureConsumerStatistics} returns this
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.setStatsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total_alerts = 2;
 * @return {number}
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.getTotalAlerts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.StructureConsumerStatistics} returns this
 */
proto.msh.resource.v1.StructureConsumerStatistics.prototype.setTotalAlerts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.msh.resource.v1.StructureBusinessStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msh.resource.v1.StructureBusinessStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.StructureBusinessStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalAlerts: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPresents: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msh.resource.v1.StructureBusinessStatistics}
 */
proto.msh.resource.v1.StructureBusinessStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msh.resource.v1.StructureBusinessStatistics;
  return proto.msh.resource.v1.StructureBusinessStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msh.resource.v1.StructureBusinessStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msh.resource.v1.StructureBusinessStatistics}
 */
proto.msh.resource.v1.StructureBusinessStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatsId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAlerts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPresents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msh.resource.v1.StructureBusinessStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msh.resource.v1.StructureBusinessStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msh.resource.v1.StructureBusinessStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalAlerts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalPresents();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string stats_id = 1;
 * @return {string}
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.getStatsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msh.resource.v1.StructureBusinessStatistics} returns this
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.setStatsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total_alerts = 2;
 * @return {number}
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.getTotalAlerts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.StructureBusinessStatistics} returns this
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.setTotalAlerts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_presents = 3;
 * @return {number}
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.getTotalPresents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msh.resource.v1.StructureBusinessStatistics} returns this
 */
proto.msh.resource.v1.StructureBusinessStatistics.prototype.setTotalPresents = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.msh.resource.v1);
